.athlete-suggest-component {
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.1);
  background: #ffffff;
  border-radius: 20px;
  width: 450px;
  min-height: 702px;
  max-height: 702px;
  position: relative;
}
.athlete-suggest-component > span {
  font-family: "Britanica-Heavy";
  font-size: 25px;
  margin-top: 19px;
  margin-bottom: 18px;
}
.athlete-suggest-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  border-top: 0.3px solid rgba(0, 0, 0, 0.2);
  /* border-bottom: 0.3px solid rgba(0, 0, 0, 0.2); */
}
.athlete-suggest-container-athlete-suggest-wrap {
  display: flex;
  align-items: center;
  width: 100%;
  border-bottom: 0.3px solid rgba(0, 0, 0, 0.2);
  text-decoration: none;
  color: black;
  padding-top: 10px;
  padding-bottom: 10px;
  /* background-color: red; */
}
.athlete-suggest-container-athlete-suggest-wrap > img {
  width: 50px;
  height: 50px;
  border-radius: 999px;
  /* margin-top: 21px; */
  margin-left: 21px;
}
.athlete-suggest-container-athlete-suggest-wrap-content-wrap {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 21px;
  margin-left: 16px;
  margin-bottom: 18px;
}
.athlete-suggest-container-athlete-suggest-wrap-content-subwrap {
  max-width: 300px;
}
.athlete-suggest-container-athlete-suggest-wrap-content-subwrap
  > span:nth-child(2n + 1) {
  font-family: "Britanica-Heavy";
  font-size: 14px;
  line-height: 17px;
}
.athlete-suggest-container-athlete-suggest-wrap-content-subwrap
  span:nth-child(2) {
  /* background-color: red ; */
  font-size: 12px;
  padding-left: 10px;
}
.athlete-suggest-container-athlete-suggest-wrap-content-wrap > span {
  font-family: "Britanica-Regular";
  font-size: 12px;
  /* margin-top: 5px; */
}
.athlete-suggest-template-pop-up-line-separation {
  width: 100%;
  height: 10px;
  /* background-color: red; */
  position: absolute;
  bottom: 105px;
  border-top: 0.3px solid rgba(0, 0, 0, 0.2);
}
.athlete-suggest-component button {
  width: 410px;
  min-height: 56px !important;
  background-color: #f6d463;
  border: none;
  border-radius: 10px;
  font-family: "Britanica-Heavy";
  font-size: 20px;
  margin-top: 20px;
  margin-bottom: 29px;
  position: absolute;
  bottom: 0;
}
@media (max-height: 875px) {
  .athlete-suggest-component {
    min-height: 632px !important;
    max-height: 632px;
  }
}
@media (max-height: 770px) {
  .athlete-suggest-component {
    min-height: 632px !important;
    max-height: 552px;
  }
}
