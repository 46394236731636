.secondstep-container {
  height: 60%;
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.second-step-form-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.second-step-form-container > form {
  height: 100%;
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.form-and-title {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.title-signup-athlete-form {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 30px;
  font-family: "britanica-heavy";
  font-weight: 400;
  line-height: 36px;
  height: 40px;
}
.input-container-secondstep {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.input-container-secondstep > input {
  width: 70%;
  padding: 2%;
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.5);
  opacity: 0.5;
  text-align: center;
  font-size: 20px;
  line-height: 24px;
}

.input-container-secondstep input:focus {
  text-align: center;
  outline: none;
}

form > .form-and-title input:active {
  font-weight: 800;
  opacity: 1;
}

.typed {
  color: rgb(0, 0, 0) !important;
  opacity: 1 !important;
  font-weight: 600 !important;
}

.button-container {
  width: 100%;
  /* height: 20%; */
  display: flex;
  justify-content: center;
  align-items: center;
}
.second-step-form-error-message{
  display: inline-block;
  background-color: #ffd7d7;
  color: #dc3545;
  padding: 5px;
  border-radius: 5px;
  transition: background-color 0.5s ease;
font-size: 12px;
font-weight: 900;
}
.PhoneInput .secondstep-container{
  display: flex;
  width: 74% ;
  /* position: relative; */
  /* background-color: yellow; */
}

.PhoneInputCountry .secondstep-container{
/* position: relative; */
/* left: 200px; */
  /* background-color: red; */
  /* display: inline-block; */
  /* vertical-align: middle; */
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
}

.PhoneInputInput .secondstep-container{
  padding: 2%;
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.5);
  opacity: 0.5;
  text-align: center;
  font-size: 20px;
  line-height: 24px;
  width: 100%;
}

.PhoneInputInput:focus .secondstep-container{
  text-align: center;
  outline: none;
}

.PhoneInputInput:active .secondstep-container{
  font-weight: 800;
  opacity: 1;
}

.PhoneInputInput.typed .secondstep-container{
  color: rgb(0, 0, 0) !important;
  opacity: 1 !important;
  font-weight: 600 !important;
}

@media (min-width: 1900px) {
  .secondstep-container {
    height: 80%;
    align-items: center;
  }
  .title-signup-athlete-form {
    font-size: 2.5rem;
  }
}
