.home-component {
  display: flex;
  justify-content: space-between;
  width: 1242px;
  padding-top: 18px;
  margin-top: 95px;
}

.home-left-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 686px; /* A modif*/
  max-height: 686px; /* A modif*/
  padding-top: 50px;
}
.home-left-separation-line {
  border: 0.3px solid rgba(0, 0, 0, 0.1);
}
.home-legals-mentions-container {
  font-size: 13px;
}
.home-legals-mentions-container a {
  text-decoration: underline;
  color: black;
}
.home-legals-mentions-container a:hover {
  color: #f6d463;
  transition-duration: 200ms;
}
.home-navlink-create-post-wrap {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 138px; /* A modif*/
}
.home-feedsidenavlink-wrap {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 70px;
  margin-bottom: 15px !important;
}

.home-center-container {
  display: flex;
  flex-direction: column;
  max-height: calc(100vh - 131px);
  overflow-y: scroll;
  width: 534px !important;
}
.home-center-container > div {
  display: flex;
  flex-direction: column;
  width: 530px !important;
  /* background-color: rgba(255, 0, 0, 0.247); */
}
.home-center-container::-webkit-scrollbar {
  display: none;
}

.home-right-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 249px;
  height: 619px;
}
.home-post-link-copied {
  width: 200px;
  height: 30px;
  font-size: 14px;
  font-family: "britanica-black";
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 8%;
  bottom: 5%;
  border: 2px solid #f6d463;
  padding: 5px;
  border-radius: 10px;
  animation: slide-in 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.1);
}
.home-post-link-copied-hide {
  width: 200px;
  height: 30px;
  font-size: 14px;
  font-family: "britanica-black";
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 8%;
  bottom: 5%;
  border: 2px solid #f6d463;
  padding: 5px;
  border-radius: 10px;
  animation: hide-smooth 700ms;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.1);
}
@keyframes slide-in {
  0% {
    transform: translateX(-150px);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes hide-smooth {
  0% {
    opacity: 100%;
  }
  100% {
    opacity: 0%;
  }
}

/* Animation Loading */
.lds-ripple {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
  margin: 50px auto;
}
.lds-ripple div {
  position: absolute;
  border: 4px solid #f6d463;
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}
@keyframes lds-ripple {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 0;
  }
  4.9% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 0;
  }
  5% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
}
@media (max-width: 1300px) {
  .home-component {
    width: 90%;
  }
}
@media (max-width: 1240px) {
  .home-component {
    justify-content: space-around;
  }
  .home-right-container {
    display: none;
  }
}
@media (max-width: 950px) {
  .home-component {
    justify-content: space-between;
  }
  .home-left-container {
    /* min-height: 420px; */
    display: flex;
    /* flex-direction: column-reverse; */
    max-height: 492px;
    padding-top: 70px;
    /* background-color: red; */
  }
  .home-feedsidenavlink-wrap {
    width: 200px;
  }
  .home-post-link-copied {
    left: 4%;
  }
  .home-post-link-copied-hide {
    left: 4%;
  }
}
