.userprofilepage-container {
  width: calc(100% - 30px);
  /* height: calc(100vh - 94px); */
  display: flex;
  align-items: center;
  flex-direction: column;
  overflow-x: none;
  max-width: 1285px !important;
  margin-top: 95px;
}

.userheader-container {
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 100%;
  flex-direction: column;
  display: flex;
  align-items: center;
  max-height: 1463px;
  position: relative;
}
.user-content-activity-nft {
  /* position: absolute; */
  top: 304px;
  left: 0px;
  right: 0;
  height: 100%;
  width: calc(100% - 30px);
  /* padding-left: 30px; */
  /* padding-right: 30px; */
  /* background-color: red; */
}
.user-content-username-and-stats-and-settings {
  display: flex;
  justify-content: space-between;
  /* background-color: blue; */
}
.user-content-settings-button {
  /* background-color: yellow; */
  margin-top: 60px;
  margin-right: 2.5%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 45px;
  width: 45px;
}
.user-content-settings-button:hover{
  cursor: pointer;
  background-color: #00000015;
    transition-duration: 300ms !important;
    border-radius: 30px;
}
.user-content-settings-button:active{
  transform: scale(0.75);
  user-select: none;
}
.user-content-settings-button img{
  height: 25px;
  width: 25px;
}

@media (max-width: 1300px) {
  /* .user-content-activity-nft {
    position: absolute;
    top: 270px;
    width: 100%;
    overflow: hidden !important;
    display: flex;
    flex-direction: column;
    align-items: center;
  } */
  .username-and-stats-component {
    display: flex;
    width: 100%;
  }
  .userprofile-description-component {
    display: flex;
    width: 100%;
  }
}

@media (max-width: 1200px) {
}
@media (max-width: 1080px) {
  .userprofilepage-container {
    max-width: 990px;
  }
  .userheader-container {
    width: 100%;
    max-width: 940px;
  }
  /* .user-content-activity-nft {
    width: 100%;
    position: absolute;
    top: 210px;
  } */
}
@media (max-width: 970px) {
  /* .userheader-container{
    max-width: 835px;
  } */
}
@media (max-width: 870px) {
  .userheader-container {
    max-width: 775px;
  }
}
