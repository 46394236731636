@import url("https://fonts.googleapis.com/css2?family=Poppins");

.signup-login-form {
  box-sizing: border-box;
  margin-top: 100px;
}

/* body {
  display: flex;
  background-color: #f6f5f7;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-family: "Poppins", sans-serif;
  overflow: hidden;
  height: 100vh;
} */

.signup-login-form .forms-container {
  background-color: #fff;
  border-radius: 25px;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  position: relative;
  overflow: hidden;
  width: 1000px;
  max-width: 100%;
  min-height: 500px;
}

.signup-login-form .form-container {
  position: absolute;
  top: 0;
  height: 100%;
  transition: all 0.6s ease-in-out;
}

.signup-login-form h1 {
  font-weight: 700;
  letter-spacing: -1.5px;
  margin: 0;
  margin-bottom: 15px;
}

.signup-login-form h1.title {
  font-size: 45px;
  line-height: 45px;
  margin: 0;
  text-shadow: 0 0 10px rgba(16, 64, 74, 0.5);
}

.signup-login-form p {
  font-size: 14px;
  font-weight: 100;
  line-height: 20px;
  letter-spacing: 0.5px;
  margin: 20px 0 30px;
  text-shadow: 0 0 10px rgba(16, 64, 74, 0.5);
}

.signup-login-form span {
  font-size: 14px;
  margin-top: 25px;
}

.signup-login-form a {
  color: #333;
  font-size: 14px;
  text-decoration: none;
  margin: 15px 0;
  transition: 0.3s ease-in-out;
}

.signup-login-form a:hover {
  color: rgb(246, 212, 99);
}

.signup-login-form .content {
  display: flex;
  width: 100%;
  height: 50px;
  align-items: center;
  justify-content: space-around;
}

.signup-login-form .content .checkbox {
  display: flex;
  align-items: center;
  justify-content: center;
}
.form-register-input-container {
  width: 90% !important;
  position: relative;
}
.form-register-input-display-button {
  width: 15px;
  height: 15px;
  position: absolute;
  top: 20px;
  right: -10px;
  cursor: pointer;
}
.password-error-message-signup {
  color: red;
  font-size: 12px;
  font-weight: 900;
}
.signup-login-form .content input {
  accent-color: #333;
  width: 12px;
  height: 12px;
}

.content label {
  font-size: 14px;
  user-select: none;
  padding-left: 5px;
}

.signup-login-form button {
  position: relative;
  border-radius: 20px;
  border: 1px solid rgb(246, 212, 99);
  /* background-color: rgb(246, 212, 99); */
  background-color: white;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.1);
  color: #000;
  font-family: "britanica-heavy";
  font-size: 15px;
  font-weight: 700;
  margin: 10px;
  padding: 12px 80px;
  letter-spacing: 1px;
  text-transform: capitalize;
  transition: 0.3s ease-in-out;
  cursor: pointer;
}
/* .overlay-login-button{
  background-color: red !important;
} */
.ghost {
  /* background-color: red !important; */
  color: black !important;
  background: white !important;
}
.signup-login-form button:hover {
  /* letter-spacing: 3px; */
  background-color: #f7c520 !important;
  transition-duration: 300ms !important;
}
.ghost-register {
  background-color: white !important;
}
.ghost-register:hover {
  background-color: #00000015 !important;
  transition-duration: 300ms !important;
}
.signup-login-form button:active {
  transform: scale(0.95);
}

.signup-login-form button:focus {
  outline: none;
}

.signup-login-form button.ghost {
  background-color: rgba(225, 225, 225, 0.2);
  /* border: 2px solid rgb(246, 212, 99); */
  color: rgb(246, 212, 99);
}

.signup-login-form button.ghost i {
  position: absolute;
  opacity: 0;
  transition: 0.3s ease-in-out;
}

.signup-login-form button.ghost i.register {
  right: 70px;
}

.signup-login-form button.ghost i.login {
  left: 70px;
}

.signup-login-form button.ghost:hover i.register {
  right: 40px;
  opacity: 1;
}

.signup-login-form button.ghost:hover i.login {
  left: 40px;
  opacity: 1;
}

.signup-login-form form {
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 50px;
  height: 100%;
  text-align: center;
}

.signup-login-form input {
  background-color: #eee;
  border-radius: 10px;
  border: none;
  padding: 12px 15px;
  margin: 8px 0;
  width: 100%;
}

.signup-login-form .login-container {
  left: 0;
  width: 50%;
  z-index: 2;
}

.signup-login-form .forms-container.right-panel-active .login-container {
  transform: translateX(100%);
}

.signup-login-form .register-container {
  left: 0;
  width: 50%;
  opacity: 0;
  z-index: 1;
}

.signup-login-form .forms-container.right-panel-active .register-container {
  transform: translateX(100%);
  opacity: 1;
  z-index: 5;
  animation: show 0.6s;
}
.signup-page-confirmation-accept-cgu {
  font-style: italic;
}
.signup-page-confirmation-accept-cgu a {
  /* background-color: red; */
  text-decoration: underline;
  color: blue;
}
@keyframes show {
  0%,
  49.99% {
    opacity: 0;
    z-index: 1;
  }

  50%,
  100% {
    opacity: 1;
    z-index: 5;
  }
}

.signup-login-form .overlay-container {
  position: absolute;
  top: 0;
  left: 50%;
  width: 50%;
  height: 100%;
  overflow: hidden;
  transition: transform 0.6s ease-in-out;
  z-index: 100;
}

.signup-login-form .forms-container.right-panel-active .overlay-container {
  transform: translate(-100%);
}

.signup-login-form .overlay {
  /* background-image: url(""); */
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0 0;
  color: #fff;
  position: relative;
  left: -100%;
  height: 100%;
  width: 200%;
  transform: translateX(0);
  transition: transform 0.6s ease-in-out;
}

.signup-login-form .overlay::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: linear-gradient(
    to top,
    rgba(46, 94, 109, 0.4) 40%,
    rgba(46, 94, 109, 0)
  );
}

.signup-login-form .forms-container.right-panel-active .overlay {
  transform: translateX(50%);
}

.signup-login-form .overlay-panel {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 40px;
  text-align: center;
  top: 0;
  height: 100%;
  width: 44%;
  transform: translateX(0);
  transition: transform 0.6s ease-in-out;
}

.signup-login-form .overlay-left {
  transform: translateX(-20%);
}

.forms-container.right-panel-active .overlay-left {
  transform: translateX(0);
}

.signup-login-form .overlay-right {
  right: 0;
  transform: translateX(0);
}

.signup-login-form .forms-container.right-panel-active .overlay-right {
  transform: translateX(20%);
}

.signup-login-form .social-container {
  margin: 20px 0;
  display: flex;
}

.signup-login-form .social-container button {
  border: 1px solid #dddddd;
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin: 0 5px;
  height: 40px;
  width: 40px;
  transition: 0.3s ease-in-out;
  padding: 25px;
}

.signup-login-form .social-container button img {
  width: 25px;
}

.signup-login-form .social-container a:hover {
  border: 1px solid rgb(246, 212, 99);
}

.error-message {
  display: inline-block;
  background-color: #ffd7d7;
  color: #dc3545;
  padding: 10px;
  border-radius: 5px;
  transition: background-color 0.5s ease;
}
