.settings-page-container {
  margin-top: 100px;
  font-family: "britanica-heavy";
  font-size: 26px;
  width: calc(100% - 30px);
  max-width: 1240px;
  /* background-color: red; */
}
.settings-page-container input::placeholder {
  user-select: none;
}
.setting-page-wrap {
  width: 90%;
  /* background-color: yellow; */
}
.settings-page-title {
  height: 100px;
  /* background-color:green; */
  display: flex;
  align-items: center;
  font-size: 34px;
}
.settings-page-display-name-title {
  font-size: 18px;
  height: 25px;
}
.settings-page-display-name-container {
  width: 300px;
  /* background-color: yellowgreen; */
  display: flex;
  flex-direction: column;
  /* align-items: center; */
}
.settings-page-display-name-wrap {
  display: flex;
  flex-direction: column;
}
.settings-page-display-name-input {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  /* background-color: blue !important; */
}
.settings-page-display-name-input input {
  width: 90%;
  font-family: "britanica-heavy" !important;
  font-size: 16px;
  border: solid 1px black;
  border-radius: 5px;
  border-color: #9b9b9b;
  /* text-align: center; */
  padding-left: 10px;
  height: 30px;
  color: rgba(0, 0, 0, 0.432);
}
.settings-page-error-display-name {
  color: red;
  font-size: 12px;
  font-weight: 900;
  margin-top: 5px;
}
.settings-page-validation-display-name {
  color: #85d87e;
  font-size: 12px;
  font-weight: 900;
  margin-top: 5px;
}
.settings-page-display-name-input img {
  /* background-color: red; */
  margin-left: 15px;
  fill: black !important;
}
.settings-page-validation-button-container {
  width: 100%;
  max-width: 250px;
  /* background-color: turquoise; */
  display: flex;
}
.settings-page-validation-button-container button {
  border: none;
  margin: none;
  margin-top: 10px !important;
  outline: none;
  width: 250px !important;
  height: 30px;
  border-radius: 10px;
  background: var(--yellow, #f6d463);
  font-family: "britanica-heavy";
}

.settings-page-line-separation {
  border-top: 1px solid black;
  width: 250px;
  opacity: 0.3;
  margin-top: 30px;
}

/* Password change */

.settings-page-password-change-title {
  font-size: 18px;
  height: 25px;
}
.settings-page-password-change-container {
  width: 300px;
  /* background-color: yellowgreen; */
  display: flex;
  flex-direction: column;
  margin-top: 30px;
}
.settings-page-password-change-wrap {
  display: flex;
  flex-direction: column;
}
.settings-page-password-change-input {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  /* background-color: blue !important; */
  position: relative;
}
.settings-page-password-change-current-password-and-display-button,
.settings-page-password-change-new-password-and-display-button {
  position: relative;
}
.settings-page-password-change-input input {
  width: 90%;
  font-family: "britanica-heavy" !important;
  font-size: 14px;
  border: solid 1px black;
  border-radius: 5px;
  border-color: #9b9b9b;
  text-align: center;
  height: 30px;
  color: black;
  text-align: start;
  padding-left: 10px;
  position: relative;
}
.settings-page-error-password-change {
  color: red;
  font-size: 10px;
  font-weight: 900;
  margin-top: 5px;
}
.settings-page-input-display-button {
  height: 18px;
  width: 18px;
  position: absolute;
  right: 25px;
  bottom: 13px;
  cursor: pointer;
}
.settings-page-validation-button-container {
  width: 100%;
  /* background-color: turquoise; */
  display: flex;
}
.settings-page-validation-button-container button {
  border: none;
  margin: none;
  margin-top: 10px !important;
  outline: none;
  width: 180px;
  height: 30px;
  border-radius: 10px;
  background: var(--yellow, #f6d463);
  font-family: "britanica-heavy";
}
.settings-page-line-separation {
  border-top: 1px solid black;
  width: 250px;
  opacity: 0.3;
  margin-top: 30px;
}

.settings-page-wallet-change-container {
  width: 300px;
  display: flex;
  flex-direction: column;
}
.settings-page-wallet-change-wrap {
  display: flex;
  flex-direction: column;
}
.settings-page-wallet-change-title {
  margin-top: 20px;
  font-size: 18px;
}
.settings-page-wallet-change-current-wallet {
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.25);
  font-size: 14px;
  font-family: "britanica-black";
  padding: 10px 20px;
  width: 320px;
  margin-top: 20px;
}
.settings-page-phone-change-container {
  width: 300px;
  display: flex;
  flex-direction: column;
}
.settings-page-phone-change-wrap {
  display: flex;
  flex-direction: column;
}
.settings-page-phone-change-title {
  margin-top: 20px;
  font-size: 18px;
}
.PhoneInputInput {
  border: none;
  opacity: 0.5;
}
.PhoneInputCountryIcon {
  /* width: 25px ; */
  border: none;
  /* height: 25px ; */
}
.PhoneInputInputOpacity {
  border: none;
  opacity: 0.5;
  opacity: 1 !important;
}
.PhoneInputInput:active,
.PhoneInputInput:focus {
  border: none;
  opacity: 1;
}
.PhoneInputInput:focus-visible {
  border: none;
  width: 105% !important;
  height: 35px;
  /* background-color: red; */
  border-left: none !important;
}
