.nft-collection-submenu-container {
  width: 100%;
  height: 60px;
  max-width: 620px;
  /* max-height: 60px; */
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.03);
  border-radius: 10px;
}
.nft-collection-submenu-button {
  width: 150px;
  height: 15px;

  text-align: center;
  font-size: 16px;
  font-family: "britanica-heavy";
  opacity: 0.5;
  margin-left: 5px;
  margin-right: 5px;
}
/* .nft-collection-submenu-button:hover {
  opacity: 0.7;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f6d463;
  border-radius: 10px;
  cursor: pointer;
} */
.nft-collection-submenu-button-clicked {
  width: 150px;
  height: 15px;

  text-align: center;
  font-size: 16px;
  font-family: "britanica-heavy";
  opacity: 1;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f6d463;
  border-radius: 10px;
  margin-left: 5px;
  margin-right: 5px;
}
