.nft-collection-overview-latest-bids-container {
    height: 511px;
    width: 620px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 50px;
    /* background-color: darkmagenta; */
  }
  .nft-collection-overview-latest-bids-title {
    max-width: 100%;
    height: 76px;
    display: flex;
    align-items: center;
    font-size: 30px;
    font-family: "britanica-heavy";
    /* background-color: gold; */
  }
  .nft-collection-overview-latest-bids-wrap {
    width: 620px;
    height: 475px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    /* background-color: chocolate; */
  }
  .nft-collection-overview-latest-bids-subwrap {
    height: 405px;
    width: 579px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    /* background-color: gray; */
    overflow: hidden;
  }
  .nft-collection-overview-latest-bids-scrolling-container{
      height: 100%;
      max-height: 340px;
      width: auto;
      overflow-y: scroll;
      /* background-color: yellow; */
  }
  .nft-collection-overview-latest-bids-scrolling-container::-webkit-scrollbar{
  display: none;
  }
  .nft-collection-overview-latest-bids-subwrap::-webkit-scrollbar{
      display: none;
  }
  .nft-collection-overview-bids {
    width: 579px;
    height: 50px;
    display: flex;
    margin-bottom: 20px;
    display: flex;
    align-items: center !important;
    /* padding-left: 5px;
    padding-right: 5px; */
        /* background-color: deeppink; */
  }
  /* .nft-collection-overview-bids:hover{
    transition-duration: 300ms !important;
    background-color: #00000015;
    border-radius: 10px;
  } */
  .nft-collection-overview-bids:last-child{
      padding-bottom: 0px;
  }
  .nft-collection-overview-bids-profile-pic{
      width: 50px;
      height: 50px;
  }
  .nft-collection-overview-bids-profile-pic img {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      cursor: pointer;
  }
  .nft-collection-overview-bids-username-date-price-container{
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
  }
  .nft-collection-overview-bids-username-and-date-container{
      max-width: 200px;
      height: 44px;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      margin-left: 20px;
      /* background-color: rgb(52, 206, 52); */
  }
  .nft-collection-overview-bids-username{
      font-size: 20px;
      font-family: "britanica-heavy";
      height: 24px;
      cursor: pointer;
  }
  .nft-collection-overview-bids-username:hover{
    text-decoration: underline;
  }
  .nft-collection-overview-bids-date{
      font-size: 16px;
      height: 19px;
  }
  .nft-collection-overview-bids-price{
      height: 44px;
      max-width: 100px;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      /* background-color: rgb(57, 183, 120); */
  }
  .nft-collection-overview-bids-price-eth{
      height: 24px;
      max-width: 100%;
      font-family: "britanica-heavy";
      font-size: 20px;
      text-align: right;
  }
  .nft-collection-overview-bids-price-eur{
      height: 19px;
      max-width: 100%;
      font-family: "britanica-regular";
      font-size: 16px;
      text-align: right;
  }
  .nft-collection-overview-bids-buy-button {
    width: 579px;
    height: 65px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 25px;
    font-family: "britanica-heavy";
    cursor: pointer;
    background: #f6d463;
    border-radius: 9.05759px;
    margin-top: 20px;
  }
  .nft-collection-overview-bids-buy-button:hover {
      background-color: #f7c520 !important;
      transition-duration: 300ms !important;
    }