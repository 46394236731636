/* 1470x920 */
.fullpagepost-container {
  position: relative !important;
  height: 100% !important;
  width: 100% !important;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100 !important;
  top: 0 !important;
  bottom: 0 !important;
  left: 0 !important;
  right: 0 !important;
}
.overlay-postfeed {
  top: 0;
  bottom: 0;
  left: 0;
  /* right: 0; */
  position: relative !important;
  width: 100% !important ;
  height: 100% !important;
  background-color: black;
  opacity: 0.7;
  z-index: 10 !important;
}

.post-container-fullpagepost {
  height: 100%;
  width: 100%;
  max-width: 1347px;
  max-height: 795px;
  display: flex;
  justify-content: center;
  align-items: center;
}
/* Responsive */
.media-fullpagepost {
  width: 795px;
  height: 795px;
  max-width: 795px;
  max-height: 795px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-color: antiquewhite; */
}
/* Responsive */
.img-fullpagepost {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  max-height: 795px;
  max-width: 795px;
  width: 795px;
  height: 795px;
  object-fit: contain !important;
  background-color: rgba(0, 0, 0, 0.706) !important;
  background-color: black !important;
  /* background-color: blur(15px) rgba(0, 0, 0, 0.706); */
}

.desc-likes-comments-container-fullpagepost {
  width: 100%;
  height: 100%;
  min-height: 795px;
  min-width: 552px;
  max-width: 552px;
  max-height: 795px !important;
  background-color: white;
  /* border-radius: 10px; */
  display: flex;
  align-items: center;
  /* justify-content: center; */
  flex-direction: column;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.bloc-content-fullpagepost {
  width: 100%;
  height: 100%;
  max-width: 496px;
  max-height: 795px;
  /* display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; */
  margin-top: 15px;
  overflow-y: scroll;
  overflow-x: hidden;
  /* background-color: RED; */
}
.bloc-content-fullpagepost::-webkit-scrollbar {
  display: none;
}
.cancel-fullpagepost {
  position: sticky;
  bottom: 95%;
  left: 93%;
}

.headofpost-container-fullpagepost {
  width: 500px;
  height: 60px;
  display: flex;
  justify-content: space-between;
  /* background-color: tomato; */
  /* justify-content: center;
  align-items: center; */
}

.separation-line-fullpagepost {
  width: 100%;
  border-top: 0.3px solid rgba(0, 0, 0, 0.2);
}
.comments-container-fullpagepost {
  /* overflow-y: scroll; */
  min-height: 474px !important;
  height: 100%;
  width: 100%;
  margin-top: 15px;
  /* background-color: aqua; */
  max-height: 474px !important;
  overflow-y: scroll;
}
/* Hide scrollbar for IE, Edge, and Firefox */
.comments-container-fullpagepost::-webkit-scrollbar,
.comments-container-fullpagepost {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  width: 100.5%;
}

/* Customize the scrollbar for WebKit-based browsers (e.g., Chrome, Safari) */
.comments-container-fullpagepost::-webkit-scrollbar {
  width: 6px; /* Set the width of the scrollbar */
  border-radius: 10px;
}

.comments-container-fullpagepost::-webkit-scrollbar-track {
  background: transparent; /* Set the track background */
}

.comments-container-fullpagepost::-webkit-scrollbar-thumb {
  background-color: #888; /* Set the color of the scrollbar */
  border-radius: 10px; /* Set the border-radius */
}

.comments-container-fullpagepost::-webkit-scrollbar-thumb:hover {
  background-color: #555; /* Set the color when hovered */
}
#dropdown-small {
  display: none;
}

.likes-comments-counter-container-fullpagepost {
  height: 6% !important;
  width: 100%;
  /* display: flex; */
  /* justify-content: flex-start; */
  /* align-items: center; */
  /* background-color: bisque; */
  /* height: 20px !important; */
  display: flex;
  align-items: center;
}

.add-comment-input-container-fullpagepost {
  width: 100%;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (max-height: 900px) {
  .post-container-fullpagepost {
    max-width: 1017px;
    max-height: 600px;
  }
  .media-fullpagepost {
    width: 600px;
    height: 600px;
  }
  .img-fullpagepost {
    width: 600px;
    height: 600px;
  }
  .desc-likes-comments-container-fullpagepost {
    width: 417px !important;
    height: 600px;
    min-height: 600px;
    max-height: 600px;
    min-width: auto;
  }
  .headofpost-container-fullpagepost {
    width: 400px;
  }
  .bloc-content-fullpagepost {
    max-width: 370px;
    max-height: 580px;
    /* background-color: yellow; */
    padding-left: 15px;
  }
  .separation-line-fullpagepost {
    height: 10px;
  }
  .comments-container-fullpagepost {
    min-height: 354px !important;
    max-height: 354px !important;
    max-width: 390px;
    overflow-y: scroll;
    overflow-x: hidden;
  }
  .comments-container-fullpagepost::-webkit-scrollbar {
  }
  #responsive-display-toggle-comments-fullpagepost1 {
    display: none;
  }
  #responsive-display-toggle-comments-fullpagepost2 {
    display: none;
  }
  .add-comment-input-container-fullpagepost {
    width: 100%;
    height: 70px;
  }
}
@media (max-width: 1450px) {
  .post-container-fullpagepost {
    max-width: 1017px;
    max-height: 600px;
  }
  .media-fullpagepost {
    width: 600px;
    height: 600px;
  }
  .img-fullpagepost {
    width: 600px;
    height: 600px;
  }
  .desc-likes-comments-container-fullpagepost {
    width: 417px !important;
    height: 600px;
    min-height: 600px;
    max-height: 600px;
    min-width: auto;
  }
  .headofpost-container-fullpagepost {
    width: 400px;
  }
  .bloc-content-fullpagepost {
    max-width: 370px;
    max-height: 580px;
    /* background-color: yellow; */
    padding-left: 15px;
  }
  .separation-line-fullpagepost {
    height: 10px;
  }
  .comments-container-fullpagepost {
    min-height: 304px !important;
    max-height: 304px !important;
    max-width: 390px;
    overflow-y: scroll;
    overflow-x: hidden;
  }
  #responsive-display-toggle-comments-fullpagepost1 {
    display: none;
  }
  #responsive-display-toggle-comments-fullpagepost2 {
    display: none;
  }
  .add-comment-input-container-fullpagepost {
    width: 100%;
    height: 70px;
  }
}
@media (max-width: 1050px) {
  .post-container-fullpagepost {
    max-width: 847px;
    max-height: 500px;
  }
  .media-fullpagepost {
    width: 500px;
    height: 500px;
  }

  .img-fullpagepost {
    width: 500px;
    height: 500px;
  }

  .desc-likes-comments-container-fullpagepost {
    width: 347px !important;
    height: 500px;
    max-height: 500px;
    min-height: 500px;
    /* background-color: wheat; */
  }
  .bloc-content-fullpagepost {
    padding-left: 5px;
  }

  .headofpost-container-fullpagepost {
    width: 300px;
    height: 40px;
    max-height: 40px;
    /* background-color: blueviolet; */
  }

  #dropdown-medium {
    /* display: none; */
    /* width: 320px; */
  }
  #dropdown-small {
    display: flex;
  }
  .bloc-content-fullpagepost {
    max-width: 300px;
    /* max-height: 580px; */
    height: 480px;
    /* display: flex;
    justify-content: space-around;
    flex-direction: column; */
    /* background-color: red; */
  }
  .likes-comments-counter-container-fullpagepost{
    height: 20px !important;
    margin-bottom: 10px;
    /* background-color: red; */
  }
  .comments-container-fullpagepost {
    min-height: 240px !important;
    max-height: 240px;
    height: 240px;
    /* background-color: yellow; */
  }
  .separation-line-fullpagepost {
    height: 0px;
    margin-top: 5px;
  }
  #responsive-display-toggle-comments-fullpagepost1 {
    display: none;
  }
  #responsive-display-toggle-comments-fullpagepost2 {
    display: none;
  }
  #responsive-display-toggle-comments-fullpagepost3 {
    display: none;
  }
  #responsive-display-toggle-comments-fullpagepost {
    display: none;
  }
  .add-comment-input-container-fullpagepost {
    width: 100%;
    height: 70px;
  }
}
@media (max-width: 900px) {
  .post-container-fullpagepost {
    max-width: 657px;
    max-height: 400px;
  }
  .media-fullpagepost {
    width: 400px;
    height: 400px;
  }

  .img-fullpagepost {
    width: 400px;
    height: 400px;
  }

  .desc-likes-comments-container-fullpagepost {
    width: 257px !important;
    max-height: 400px;
    height: 100%;
    min-height: 400px;
    /* display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column; */
  }
  .bloc-content-fullpagepost {
    height: 100%;
    max-width: 240px !important;
    max-height: 370px;
    /* height: 370px; */
    /* background-color: red; */
  }
  /* .bloc-content-fullpagepost { */
  /* max-width: 247px; */
  /* max-height: 580px; */
  /* height: 370px; */
  /* display: flex; */
  /* justify-content: space-around; */
  /* background-color: red; */
  /* flex-direction: column; */
  /* align-items: center; */
  /* } */
  .likes-comments-counter-container-fullpagepost{
    height: 10px !important;
  }
  .comments-container-fullpagepost {
    /* overflow: scroll; */
    min-height: 180px !important;
    max-height: 180px;
    height: 180px;
    /* background-color: red; */
    /* background-color: yellow; */
  }

  .headofpost-container-fullpagepost {
    width: 245px;
    height: 40px;
    align-items: flex-start;
  }

  #dropdown-medium {
    /* display: none;
    width: 320px; */
  }
  #dropdown-small {
    /* display: flex; */
  }
  .headofpost-container-fullpagepost {
    width: 247px;
    height: 40px;
    max-height: 40px;
    /* background-color: blueviolet; */
  }
  .headofpost-container-fullpagepost {
    width: 247px;
    height: 30px;
    max-height: 30px;
    /* background-color: blueviolet; */
  }
  .headofpost-container-fullpagepost .profilepic-athlete-publication {
    width: 28px !important;
    height: 28px !important;
  }
  .headofpost-container-fullpagepost img {
    width: 28px !important;
    height: 28px !important;
  }
  .headofpost-container-fullpagepost
    > div
    > div
    > div:nth-child(2)
    > div:nth-child(1) {
    /* background-color: skyblue; */
    font-size: 14px;
  }
  .headofpost-container-fullpagepost
    > div
    > div
    > div:nth-child(2)
    > div:nth-child(2) {
    /* background-color: palegoldenrod; */
    font-size: 10px;
  }
  /* .separation-line-fullpagepost {
    height: 10px;
  } */
  #responsive-display-toggle-comments-fullpagepost1 {
    display: none;
  }
  #responsive-display-toggle-comments-fullpagepost2 {
    display: none;
  }
  #responsive-display-toggle-comments-fullpagepost3 {
    display: none;
  }
  #responsive-display-toggle-comments-fullpagepost {
    display: none;
  }
  .add-comment-input-container-fullpagepost {
    width: 100%;
    height: 70px;
    /* background-color: aquamarine; */
  }
  .add-comment-input-container-fullpagepost > div > div {
    width: 100%;
    height: 30px;
    padding-left: 10px;
    /* background-color: green; */
  }
  .add-comment-input-container-fullpagepost > div > div {
    min-height: 40px !important;
    height: 40px !important;
    /* background-color: green; */
  }
  .add-comment-input-container-fullpagepost > div > div input {
    font-size: 12px !important;
    /* background-color: green; */
  }
  .add-comment-input-container-fullpagepost > div > div input::placeholder {
    font-size: 12px;
  }
  .add-comment-input-container-fullpagepost > div > div button {
    font-size: 12px !important;
  }
}
