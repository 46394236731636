.profileSubMenu-component {
  display: flex;
  justify-content: flex-start;
  max-width: 1240px;
  width: 100%;
  height: 40px;
  border-bottom: 0.3px solid #3c40455b;
}
.profilesubmenu-wrap {
  padding: 0px 5px 0px 5px;
  margin-right: 15px;
  height: 38px;
}
.profilesubmenu-wrap:hover {
  border-bottom: 4px solid #f8db4a;
}
.profilesubmenu-arrow-wrap {
  display: flex;
}
.profilesubmenu-wrap-clicked {
  border-bottom: 4px solid #f8db4a;
  opacity: 1 !important;
}
.profilesubmenu-arrow-wrap > img {
  width: 12.73px;
  margin-left: 12.64px;
  opacity: 0.5;
}
.profilesubmenu-offres {
  position: relative;
  /* background-color: red; */
  cursor: pointer;
}
.profilesubmenu-offres-dropdown {
  display: none;
}
.profilesubmenu-offres-dropdown-clicked {
  position: absolute;
  width: 150px;
  height: 80px;
  top: 44px;
  left: -5px;
  background-color: white;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  z-index: 999 !important;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-start;
  font-family: "Britanica-Heavy";
  font-size: 18px;
  line-height: 24px;
  padding-left: 10px;
}
.profilesubmenu-offres-dropdown-clicked > span{
    cursor: pointer;
}
.profilesubmenu-offres-dropdown-clicked > span:hover{
    text-decoration: underline #f8db4a 4px;
}
@media (max-width: 1150px) {
  .profilesubmenu-wrap > button:nth-child(n) {
    font-size: 18px !important;
  }
}
