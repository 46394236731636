.mint-pop-up-buy-container {
  width: 100%;
  height: 102%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
}
.mint-pop-up-buy-wrap {
  width: 410px;
  height: 473px;
  display: flex;
  flex-direction: column;
  /* background-color: violet; */
}
.mint-pop-up-buy-wrap-hidden {
  visibility: hidden;
}
.mint-pop-up-buy-title-and-cancel-button {
  height: 30px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* background-color: yellowgreen; */
  font-size: 25px;
  margin-top: 20px;
  font-family: "britanica-heavy";
}
.mint-pop-up-buy-question-and-max-nft {
  width: 100%;
  max-height: 23px;
  display: flex;
  justify-content: space-between;
  margin-top: 31px;
  /* background-color: turquoise; */
}
.mint-pop-up-buy-quantity-selector-container {
  width: 100%;
  height: 70px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #f8f8f8;
  border-radius: 110px;
  margin-top: 7px;
}
.mint-pop-up-buy-quantity-selector-decrease-button {
  background: #ffffff;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.1);
  width: 50px;
  height: 50px;
  border-radius: 50%;
  position: relative;
  font-weight: 900;
  left: 10px;
  font-size: 36px;
  cursor: pointer;
  user-select: none;
}
.mint-pop-up-buy-quantity-selector-decrease-button > span {
  position: absolute;
  top: 5px;
  left: 14.5px;
}

.mint-pop-up-buy-quantity-selector-counter {
  font-size: 40px;
  font-family: "lufga-regular";
}

.mint-pop-up-buy-quantity-selector-increase-button {
  background: #ffffff;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.1);
  width: 50px;
  height: 50px;
  border-radius: 50%;
  position: relative;
  right: 10px;
  font-weight: 900;
  font-size: 36px;
  cursor: pointer;
  user-select: none;
}
.mint-pop-up-buy-quantity-selector-increase-button > span {
  position: absolute;
  top: 5px;
  left: 14.5px;
}
.mint-pop-up-line-separation-first {
  width: 450px !important;
  transform: translateX(-19px);
  margin-top: 25px;
  border: 0.3px solid rgba(0, 0, 0, 0.2);
  opacity: 0.5;
}
.mint-pop-up-price-container {
  height: 42px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 15px;
  /* background-color: tomato; */
}
.mint-pop-up-price-title {
  height: 23px;
  width: auto;
  display: flex;
  align-items: center;
  font-family: "britanica-heavy";
}
.mint-pop-up-price-eth-eur-container {
  display: flex;
  flex-direction: column;
}
.mint-pop-up-price-eth {
  height: 21px;
  font-family: "britanica-heavy";
  font-size: 20px;
}
.mint-pop-up-price-eur {
  height: 21px;
}

.mint-pop-up-line-separation-second {
  width: 450px !important;
  transform: translateX(-19px);
  border: 0.3px solid rgba(0, 0, 0, 0.2);
  margin-top: 6px;
  opacity: 0.5;
}
.mint-pop-up-mint-button {
  outline: none;
  border: 0;
  margin: 0;
  background: none;
  width: 100%;
  height: 56px !important;
  background: #f6d463;
  border-radius: 10px;
  font-size: 20px;
  font-family: "britanica-heavy";
  cursor: pointer;
  margin-top: 20px;
}
.mint-pop-up-mint-button:hover {
  background-color: #f7c520 !important;
  transition-duration: 300ms !important;
}
button.xmint-btn {
  padding: 0;
  width: 100%;
  height: 56px;
  /* background-color: blueviolet; */
  font-family: "britanica-heavy";
  font-size: 20px;
}
