.post-feed-description {
  min-height: 55px;
  width: 100%;
  /* background-color: grey; */
  display: flex;
  /* justify-content: center; */
  align-items: center;
  flex-wrap: wrap;
}
.post-feed-description p {
  display: flex;
  word-break: keep-all;
  flex-wrap: wrap;
  padding-top: 20px;
  padding-bottom: 10px;
}
.post-feed-description-fullpagepost {
  min-height: 55px;
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  /* background-color: green; */
}
.post-feed-description-fullpagepost p {
  display: flex;
  /* word-break: break-all; */
  word-break: keep-all;
  flex-wrap: wrap;
  padding-top: 20px;
  padding-bottom: 10px;
  /* font-size: 14px; */
}
@media (max-width: 1350px) {
  .post-feed-description {
    width: 100%;
  }
  .post-feed-description-resize {
    font-size: 16px;
  }
  .post-feed-description-resize-fullpage {
    /* font-size: ; */
  }
}
@media (max-width: 1150px) {
  .post-feed-description {
    /* height: 90px; */
  }
  .post-feed-description-resize {
    /* height: 85px; */
    font-size: 16px;
  }
}
@media (max-width: 1050px) {
  .post-feed-description {
    /* height: 70px; */
  }
  .post-feed-description-resize {
    /* height: 85px; */
    font-size: 16px;
  }
  .post-feed-description > p {
    font-size: 14px;
  }
  .post-feed-description-resize > p {
    font-size: 16px;
  }
  .post-feed-description-fullpagepost p {
    font-size: 14px;
    padding-top: 10px;
    padding-bottom: 10px;
  }
}
@media (max-width: 900px) {
  .post-feed-description-fullpagepost p {
    /* padding-bottom: 0px; */
    /* padding-top: 0px; */
  }
  .post-feed-description {
    /* height: 70px; */
  }
  .post-feed-description-resize {
    /* height: 85px; */
    font-size: 16px;
  }
  .post-feed-description > p {
    font-size: 12px;
  }
  .post-feed-description-resize > p {
    font-size: 16px;
  }
  .post-feed-description-resize-fullpage > p {
    font-size: 12px !important ;
    /* height: 50px; */
    display: flex;
    align-items: center;
  }
}
