.popupsignin-loading-google-animation {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 500px;
  height: 644px;
  background-color: white;
  border-radius: 20px;
}

.popupsignin-component {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  text-align: center;
  width: 500px;
  height: 644px;
  background-color: white;
  border-radius: 20px;
}
.popupsignin-error-message {
  color: red;
  font-size: 14px;
  font-weight: 900;
  font-style: italic;
  position: absolute;
  top: 150px;
}
.popupsignin-error-message-google-not-register {
  color: red;
  font-size: 14px;
  font-weight: 900;
  font-style: italic;
  position: absolute;
  top: 135px;
}

.popupsignin-component > span:nth-child(1) {
  font-family: "britanica-heavy";
  font-size: 35px;
  line-height: normal;
  margin-top: 30px;
  margin-bottom: 10px;
}
.popupsignin-component > span:nth-child(2) {
  line-height: normal;
  color: #3d4045;
  width: 460px;
  margin-bottom: 40px;
}
.popupsignin-input-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 20px;
  width: 460px;
  height: 71px;
  position: relative;
}
.popupsignin-input-display-button {
  height: 18px;
  width: 18px;
  position: absolute;
  right: 20px;
  bottom: 13px;
  cursor: pointer;
}
.popupsignin-input-mail-error {
  color: red;
  font-size: 12px;
  font-weight: 900;
  margin-top: 5px;
}
.popupsignin-input-container > span {
  font-family: "britanica-heavy";
  font-size: 14px;
  line-height: normal;
  margin-bottom: 5px;
}
.popupsignin-input-container > input {
  border: 1px solid rgba(0, 0, 0, 0.4);
  border-radius: 5px;
  padding: 15px 10px;
  width: 95%;
  height: 19px;
  line-height: normal;
  color: #3c4045;
}
.popupsignin-style-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  opacity: 0.5;
  width: 460px;
  height: 20px;
  margin-bottom: 20px;
}
.popupsignin-style-container > div {
  background-color: rgba(0, 0, 0, 0.5);
  width: 200px;
  height: 1px;
}
.popupsignin-style-container > span {
  font-size: 14px;
  line-height: normal;
}
.popupsignin-socials-container {
  width: 460px;
  height: 56px;
  position: relative;
  margin-bottom: 20px;
}
.popupsignin-socials-container button:hover {
  background: #f6d46391 !important;
  transition-duration: 500ms !important;
}
.popupsignin-socials-container > img {
  width: 25px;
  height: 25px;
  position: absolute;
  top: 16px;
  left: 95px;
}
.popupsignin-signup-container {
  display: flex;
  margin-bottom: 30px;
}
.popupsignin-signup-container > span {
  line-height: normal;
  margin-right: 5px;
}
.popupsignin-forget-password {
  color: #3c4045;
  font-family: "britanica-heavy";
  line-height: normal;
  text-decoration-line: underline;
  outline: none;
  border: transparent;
  background-color: transparent;
  margin-bottom: 40px;
  cursor: pointer;
  user-select: none;
  font-size: 16px;
}
.popupsignin-loading-mail-signin-animation {
  position: absolute;
  top: 390px;
}
.popupsignin-signin-button {
  font-family: "britanica-heavy";
  font-size: 20px;
  line-height: normal;
  outline: none;
  border: transparent;
  background-color: #f6d463;
  margin-bottom: 20px;
  border-radius: 10px;
  width: 460px;
  height: 56px !important;
  cursor: pointer;
  user-select: none;
}
.popupsignin-signin-button:hover {
  background: #f7c520 !important;
  transition-duration: 300ms !important;
}
.popupsignin-signin-button:active {
  transform: scale(0.85);
}
.popupsignin-google-button {
  font-family: "britanica-heavy";
  font-size: 20px;
  line-height: normal;
  background-color: white;
  margin-bottom: 20px;
  border-radius: 10px;
  border: 1px solid rgba(60, 64, 69, 0.4);
  /* width: 425px; */
  width: 100%;
  height: 56px;
  padding-left: 35px;
  cursor: pointer;
  user-select: none;
}
.popupsignin-google-error {
  color: red;
  font-size: 14px;
  font-weight: 900;
  font-style: italic;
  position: absolute;
  top: 560px;
}
.popupsignin-no-user-found-error {
  color: red;
  font-size: 14px;
  font-weight: 900;
  font-style: italic;
  position: absolute;
  top: 560px;
}
.popupsignin-signup-button {
  color: #f6d463;
  font-family: "britanica-heavy";
  line-height: normal;
  font-size: 16px;
  outline: none;
  border: transparent;
  background-color: transparent;
  cursor: pointer;
  font-size: 16px;
}
.popupsignin-signup-container button:hover {
  text-decoration: underline;
  text-decoration-thickness: 2px;
}
