.launchpadallheader-component {
  width: 100%;
  max-height: 500px;
  position: relative;
}
.launchpadallheader-component > img {
  min-width: 1243px !important;
  width: 100%;
  max-width: 1243px;
  height: 100%;
  max-height: 500px;
  border-radius: 20px;
  object-fit: cover !important;
  /* background-color: red; */
}
.launchpadallheader-data-container {
  display: flex;
  align-items: flex-end;
  width: 100%;
  height: 100%;
  position: absolute;
  max-width: 1243px;
  top: 0;
  border-radius: 20px;
  background: linear-gradient(
    84.22deg,
    rgba(0, 0, 0, 0.6) 0%,
    rgba(0, 0, 0, 0.6) 33.41%,
    rgba(0, 0, 0, 0.078) 100.23%
  );
}
.launchpadallheader-data-wrap {
  margin-left: 40px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.launchpadallheader-data-wrap-img-and-name {
  text-decoration: none;
}
.launchpadallheader-data-wrap-img-and-name:hover {
  /* background-color: red; */
  background-color: #ddc3c385;
  transition-duration: 300ms !important;
  border-radius: 10px;
  cursor: pointer;
  padding: 5px;
  text-decoration: underline;
  text-decoration-color: #fffcfc;
  text-underline-offset: 4px;
}
.launchpadallheader-data-wrap-img-and-name:active {
  transform: scale(0.8);
}
.launchpadallheader-data-wrap-img {
  background-color: white;
  border-radius: 5px;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}
.launchpadallheader-data-wrap-img > img {
  width: 46px;
  height: 46px;
  border-radius: 5px;
}
.launchpadallheader-data-wrap-athletename {
  font-family: "Britanica-Regular";
  line-height: 19px;
  color: #ffffff;
}
.launchpadallheader-data-wrap-title {
  font-family: "Britanica-Heavy";
  font-size: 35px;
  line-height: 42px;
  color: #ffffff;
  margin-top: 6px;
}
.launchpadallheader-data-wrap-description {
  font-family: "Britanica-Regular";
  line-height: 19px;
  color: #ffffff;
  max-width: 552px;
  margin-top: 5px;
}
.launchpadallheader-data-wrap-nft {
  font-family: "Britanica-Heavy";
  line-height: 19px;
  color: #ffffff;
  margin-top: 15px;
  margin-bottom: 25px;
}
.launchpadallheader-data-wrap > button {
  margin-bottom: 40px;
  width: 117px;
  height: 44px;
  background-color: #f6d463;
  border-radius: 5px;
  border: transparent;
  font-family: "Britanica-Heavy";
  font-size: 20px;
}
.launchpadallheader-data-wrap > button:active {
  transform: scale(0.8);
  /* transition: 300s; */
  transition-duration: 300ms;
}
@media (max-width: 1300px) {
  .launchpadallheader-component {
    /* max-width: 90% !important; */
    /* width: 100%; */
  }
  .launchpadallheader-component > img {
    /* max-width: 80vw !important; */
    /* width: 100%; */
    /* background-color: blue; */
    min-width: 95vw !important;
    width: 100% !important;
    max-width: 95vw !important;
  }
}
