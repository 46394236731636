.athleteprofilenftcollection-component {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  max-width: 1246px;
}
.athleteprofilenftcollectiontemplate-component:nth-child(2n + 1) {
  margin-right: 30px !important;
  transition: box-shadow 0.25s ease-in-out 0s, transform 0.25s ease 0s;
}
.athleteprofilenftcollectiontemplate-component:hover{
  box-shadow: rgba(0, 0, 0, 0.12) 0px 4px 16px;
  transform: translate(0px, -10px);
  transition-duration: 300ms;
}
.athleteprofilenftcollectiontemplate-component:nth-child(n) {
  margin-top: 26px;
  flex: 48%;
  transition: box-shadow 0.25s ease-in-out 0s, transform 0.25s ease 0s;
}
