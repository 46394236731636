.nft-collection-header-container {
  /* background-color: red; */
  width: 100%;
  max-width: 1243px;
  max-height: 620px;
  display: flex;
}

.nft-collection-header-picture {
  width: 100%;
  height: 100%;
  max-width: 645px;
  position: relative;
}
.nft-collection-header-picture img {
  min-width: 100%;
  min-height: 100%;
  width: 620px;
  height: 620px;
  border-radius: 20px;
  object-fit: cover;
}
.nft-collection-header-div-no-img {
  min-width: 100%;
  min-height: 100%;
  width: 620px;
  height: 620px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  object-fit: cover;
  background-color: #f6d463;
  opacity: 0.5;
  font-style: italic;
  font-size: 22px;
  font-family: "britanica-heavy";
  color: black;
}
.nft-collection-header-data-and-buy-module-container {
  height: 100%;
  width: 100%;
  max-width: 623px;
  max-height: 620px;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* background-color: green; */
}
.nft-collection-header-data-and-buy-module-content {
  /* background-color: yellow; */
  height: 100%;
  max-height: 147px;
  width: 100%;
  max-width: 570px;
  display: flex;
  align-items: flex-end;
  /* padding-top: 79px; */
}
.nft-collection-header-collection-name-and-number {
  width: 100%;
  height: 68px;
  /* background-color: orange; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.nft-collection-header-collection-name-title {
  width: 100%;
  height: 100%;
  max-height: 19px;
  /* max-width: 258px; */
  /* background-color: blue; */
  display: flex;
  justify-content: space-between;
}
.nft-collection-header-collection-name-title p {
  height: 100%;
  width: 100%;
  max-width: 258px;
  /* background-color: brown !important; */
  display: flex;
  align-items: center;
  font-family: "britanica-heavy";
  font-size: 16px;
}
.nft-collection-header-collection-name-title div {
  /* background-color: cyan; */
  max-height: 19px;
  width: 20px;
  z-index: 999 !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.nft-collection-header-collection-name-title span {
  height: 5px;
  width: 5px;
  /* background-color: black; */
  border-radius: 50%;
  display: inline-block;
}
.nft-collection-header-nft-number {
  width: 100%;
  height: 100%;
  max-width: 570px;
  max-height: 42px;
  display: flex;
  align-items: center;
  /* background-color: red; */
  font-family: "Britanica-Heavy";
  font-style: normal;
  font-weight: 400;
  font-size: 35px;
}

.nft-collection-header-owner-creator-container {
  width: 100%;
  height: 69px;
  max-width: 570px;
  display: flex;
  align-items: flex-end;
  /* background-color: darkgray; */
}
.nft-collection-header-owner-creator-wrap {
  width: 100%;
  height: 100%;
  /* max-width: 325px; */
  max-width: 378px;
  max-height: 50px;
  display: flex;
  /* display: flex;
    justify-content: flex-start; */
  /* background-color: darkmagenta; */
}
.nft-collection-header-owner-creator-wrap img {
  border-radius: 10px;
  width: 50px;
  height: 50px;
}
.nft-collection-header-owner-container {
  /* background-color: red; */
  display: flex;
  cursor: pointer;
  margin-right: 10px;
}
.nft-collection-header-owner-container:hover {
  background-color: #00000015;
  transition-duration: 300ms !important;
  border-radius: 10px;
}
.nft-collection-header-owner-creator-title-and-name-creator {
  height: 100%;
  width: 100%;
  max-width: 96px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  /* background-color: darksalmon; */
  padding-left: 17px;
  padding-right: 50px;
}
.nft-collection-header-owner-creator-title-creator {
  font-family: "britanica-regular";
  font-size: 14px;
}
.nft-collection-header-owner-creator-name-creator {
  font-family: "britanica-heavy";
  font-size: 16px;
}
.nft-collection-header-owner-creator-title-and-name-owner {
  height: 100%;
  width: 100%;
  /* max-width: 96px; */
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  /* background-color: darksalmon; */
  padding-left: 16px;
  padding-right: 15px;
}
.nft-collection-header-owner-creator-title-owner {
  font-family: "britanica-regular";
  font-size: 14px;
}
.nft-collection-header-owner-creator-name-owner {
  font-family: "britanica-heavy";
  font-size: 16px;
}
.nft-collection-header-line-separation {
  width: 570px;
  border-bottom: 0.3px solid rgba(0, 0, 0, 0.3);
  padding-top: 35px;
  /* background-color: rgba(139, 0, 0, 0.313); */
}

.nft-collection-header-buy-module-container {
  width: 100%;
  height: 100%;
  max-width: 576px;
  max-height: 316px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.08);
  border-radius: 13.5142px;
}
.nft-collection-header-buy-module-container-owner {
  width: 100%;
  height: 100%;
  max-width: 576px;
  max-height: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.08);
  border-radius: 13.5142px;
}
.nft-collection-header-buy-module-wrap {
  width: 100%;
  height: 100%;
  max-width: 519px;
  max-height: 260px;
  /* background-color: magenta; */
}
.nft-collection-header-price-and-bid-container {
  width: 100%;
  height: 100%;
  max-height: 109px;
  display: flex;
  justify-content: space-between;
  /* background-color: rgba(25, 25, 112, 0.274); */
}
.nft-collection-header-price-and-bid-container-owner {
  width: 100%;
  height: 100%;
  max-height: 109px;
  display: flex;
  justify-content: space-between;
  /* background-color: rgba(25, 25, 112, 0.274); */
  margin-top: 17px;
}
.nft-collection-header-price-container {
  width: 100%;
  height: 100%;
  max-width: 253px;
  max-height: 109px;
  /* display: flex;
  flex-direction: column; */
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.03);
  border-radius: 13.5142px;
}
.nft-collection-header-price-wrap {
  width: 100%;
  height: 100%;
  max-width: 215px;
  max-height: 75px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* background-color: royalblue; */
}
.nft-collection-header-title-price {
  height: 19px;
  display: flex;
  align-items: center;
  /* background-color: tomato; */
}
.nft-collection-header-eth-price {
  height: 30px;
  display: flex;
  align-items: center;
  font-family: "britanica-heavy";
  font-size: 25px;
  /* background-color: rgba(24, 26, 34, 0.243); */
}
.nft-collection-header-eur-price {
  font-family: "britanica-regular";
  font-size: 16px;
  height: 17px;
  /* background-color: lightpink; */
}
/* ===== */
.nft-collection-header-bid-container {
  width: 100%;
  height: 100%;
  max-width: 253px;
  max-height: 109px;
  /* display: flex;
  flex-direction: column; */
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.03);
  border-radius: 13.5142px;
}
.nft-collection-header-bid-wrap {
  width: 100%;
  height: 100%;
  max-width: 215px;
  max-height: 75px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* background-color: royalblue; */
}
.nft-collection-header-title-bid {
  height: 19px;
  display: flex;
  align-items: center;
  /* background-color: tomato; */
}
.nft-collection-header-eth-bid {
  height: 30px;
  display: flex;
  align-items: center;
  font-family: "britanica-heavy";
  font-size: 25px;
  /* background-color: rgba(24, 26, 34, 0.243); */
}
.nft-collection-header-eur-bid {
  font-family: "britanica-regular";
  font-size: 16px;
  height: 17px;
  /* background-color: lightpink; */
}
/* ===== */

.nft-collection-header-buttons-container {
  width: 100%;
  height: 100%;
  max-width: 519px;
  max-height: 150px;
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  /* background-color: rgb(113, 103, 43); */
}
.nft-collection-header-buy-button {
  width: 100%;
  height: 100%;
  max-width: 519px;
  max-height: 65px;
  background: #f6d463;
  font-family: "Britanica-Heavy";
  font-size: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  cursor: pointer;
  user-select: none;
}
.nft-collection-header-buy-button:hover {
  background-color: #f7c520 !important;
  transition-duration: 300ms !important;
}
.nft-collection-header-buy-button:active {
  transform: scale(0.75) !important;
}
.nft-collection-header-buy-button-disabled {
  width: 100%;
  height: 100%;
  max-width: 519px;
  max-height: 65px;
  background: #ededed;
  font-family: "Britanica-Heavy";
  font-size: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  user-select: none;
}
.nft-collection-header-bid-button {
  width: 100%;
  height: 100%;
  max-width: 519px;
  max-height: 65px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Britanica-Heavy";
  font-size: 25px;
  border: 1px solid #000000;
  border-radius: 10px;
  cursor: pointer;
  user-select: none;
  /* background-color: white; */
}
.nft-collection-header-bid-button:hover {
  background-color: #00000015;
  transition-duration: 300ms;
}
.nft-collection-header-bid-button:active {
  transform: scale(0.75) !important;
}
/* ======================================================================== */

/* ======== LaunchpadCollectionLiveHeader ======== */
.launchpad-collection-live-button-container {
  border: none;
  position: absolute;
  bottom: 3.2258%;
  right: 3.2258%;
  background: #ffffff !important;
  z-index: 213;
  width: 173px;
  height: 37px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "britanica-heavy";
  cursor: pointer;
  user-select: none;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
}
.launchpad-collection-live-button-container:active {
  transform: scale(0.65);
}
.launchpad-collection-live-button-container:hover {
  /* background-color: #0000008f; */
  background-color: #e0d4d4e0 !important;
  /* background: #f7c520; */
  /* color: white; */
  transition-duration: 300ms !important;
}

.launchpad-collection-live-header-right-container {
  width: 100%;
  height: 100%;
  max-width: 623px;
  max-height: 620px;
  display: flex;
  flex-direction: column;
  /* background-color: rgb(98, 51, 51); */
}
.launchpad-collection-live-header-right-wrap {
  height: 100%;
  max-height: 515px;
  display: flex;
  flex-direction: column;
  margin-top: 53px;
  margin-left: 50px;
  /* background-color: blueviolet; */
}
.launchpad-collection-live-header-right-creator-pic-and-usernamme-container {
  height: 36px;
  width: 100%;
  display: flex;
  align-items: center;
  font-family: "britanica-heavy";
  /* background-color: aquamarine; */
}
.launchpad-collection-live-header-right-creator-pic-and-usernamme-wrap {
  display: flex;
  align-items: center;
  border-radius: 10px;
  padding: 5px;
  cursor: pointer;
}
.launchpad-collection-live-header-right-creator-pic-and-usernamme-wrap:hover {
  background-color: #00000015;
  transition-duration: 300ms !important;
  text-decoration: underline;
}

.launchpad-collection-live-header-right-container img {
  width: 36px;
  height: 36px;
  border-radius: 5px;
  margin-right: 8px;
}
.launchpad-collection-live-header-right-creator-username-creator-link {
  text-decoration: none;
  color: black;
}
.launchpad-collection-live-header-right-creator-username-creator-link:active {
  transform: scale(0.65);
  transition-duration: 300ms;
}
/* .launchpad-collection-live-header-right-creator-username-creator-link:hover{
  text-decoration: underline;
} */
.launchpad-collection-live-header-right-collection-name {
  width: 100%;
  height: 84px;
  display: flex;
  align-items: center;
  font-family: "britanica-heavy";
  font-size: 35px;
  margin-top: 11px;
  /* background-color: brown; */
}
.launchpad-collection-live-header-right-collection-description {
  width: 100%;
  height: 100%;
  max-height: 88px;
  overflow: scroll;
  margin-top: 15px;
  margin-bottom: 10px;
  /* background-color: yellowgreen; */
}
.launchpad-collection-live-header-right-collection-description::-webkit-scrollbar {
  display: none;
}
.launchpad-collection-live-header-right-line-separation {
  width: 100%;
  height: 0px;
  border: 0.3px solid rgba(0, 0, 0, 0.3);
}
.launchpad-collection-live-header-right-price-container {
  margin-top: 21px;
}
.launchpad-collection-live-header-right-eth-price {
  height: 100%;
  max-height: 42px;
  font-size: 35px;
  font-family: "britanica-heavy";
  /* background-color: whitesmoke; */
}
.launchpad-collection-live-header-right-eur-price {
  height: 100%;
  max-height: 19px;
  /* background-color: wheat; */
  color: #3c4045;
}
.launchpad-collection-live-header-right-mint-module-container {
  height: 181px;
  width: 100%;
  margin-top: 28px;
  /* background-color: violet; */
}
.launchpad-collection-live-header-right-mint-module-timer-and-limit {
  width: 100%;
  height: 19%;
  max-width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* background-color: turquoise; */
}
.launchpad-collection-live-header-right-mint-module-timer {
  font-family: "britanica-heavy";
}

.launchpad-collection-live-header-right-mint-module-mint-button-container {
  width: 100%;
  height: 100%;
  max-height: 72px;
}
.launchpad-collection-live-header-right-mint-module-mint-button {
  background: none;
  margin: 0;
  border: 0;
  width: 100%;
  height: 100%;
  background: #f6d463;
  border-radius: 10px;
  font-size: 30px;
  font-family: "britanica-heavy";
  cursor: pointer;
  margin-top: 21px;
  user-select: none;
}
.launchpad-collection-live-header-right-mint-module-mint-button:hover {
  background-color: #f7c520 !important;
  transition-duration: 300ms !important;
}
.launchpad-collection-live-header-right-mint-module-mint-button:active {
  transform: scale(0.75) !important;
}
/* =====  ===== */
@media (max-width: 1280px) {
  .nft-collection-header-container {
    max-width: 1150px;
  }
  .nft-collection-header-data-and-buy-module-container {
    align-items: flex-start;
    padding-left: 30px;
    max-width: 500px;
  }
  .nft-collection-header-data-and-buy-module-content {
    max-width: 490px;
  }
  .nft-collection-header-owner-creator-container {
    max-width: 490px;
    min-height: 60px;
  }
  .nft-collection-header-line-separation {
    max-width: 490px;
  }
  .nft-collection-header-buy-module-container {
    max-width: 490px;
  }
  .nft-collection-header-buy-module-wrap {
    max-width: 410px;
  }
  .nft-collection-header-price-container,
  .nft-collection-header-bid-container {
    max-width: 200px;
  }
  .nft-collection-header-price-wrap,
  .nft-collection-header-bid-wrap {
    max-width: 170px;
  }
}

@media (max-width: 1180px) {
  .nft-collection-header-container {
    max-width: 1000px;
  }
  /* .nft-collection-header-picture {
  } */
  .nft-collection-header-picture img {
    width: 100%;
    height: 100%;
  }
  .nft-collection-header-data-and-buy-module-container {
    align-items: flex-start;
    padding-left: 30px;
    max-width: 350px;
    /* opacity: 0.5; */
  }
  .nft-collection-header-data-and-buy-module-content {
    max-width: 410px;
    max-height: 100px;
  }
  .nft-collection-header-owner-creator-container {
    max-width: 410px;
  }
  .nft-collection-header-line-separation {
    max-width: 350px;
  }
  .nft-collection-header-buy-module-container {
    max-width: 410px;
    max-height: 280px;
  }
  .nft-collection-header-buy-module-wrap {
    max-width: 350px;
  }
  .nft-collection-header-price-container,
  .nft-collection-header-bid-container {
    max-width: 170px;
  }
  .nft-collection-header-price-wrap,
  .nft-collection-header-bid-wrap {
    max-width: 140px;
  }
  /* =================================================== */
  .launchpad-collection-live-header-right-wrap {
    margin-left: 25px;
    margin-top: 25px;
  }
  .launchpad-collection-live-header-right-collection-name {
    font-size: 25px;
    height: 50px;
  }
  .launchpad-collection-live-header-right-eth-price {
    font-size: 25px;
    max-height: 32px;
  }
  .launchpad-collection-live-header-right-mint-module-mint-button-container {
    max-height: 60px;
  }
  .launchpad-collection-live-header-right-mint-module-mint-button {
    font-size: 25px;
  }
}
@media (max-width: 1080px) {
  .nft-collection-header-container {
    max-width: 940px;
    max-height: 500px;
    min-height: 500px;
    height: 100%;
    /* background-color: red; */
  }
  .nft-collection-header-picture {
    /* max-width: 645px; */
    max-width: 500px;
    /* max-height: 500px; */
    width: 100%;
    height: 100%;
  }
  .nft-collection-header-picture img {
    width: 100%;
    height: 100%;
  }
  .nft-collection-header-div-no-img {
    width: 100%;
    height: 100%;
  }
  .nft-collection-header-data-and-buy-module-container {
    align-items: flex-start;
    padding-left: 20px;
    max-width: 350px;
    /* background-color: red; */
  }
  .nft-collection-header-line-separation {
    padding-top: 20px;
    max-width: 350px;
  }
  .nft-collection-header-div-no-img{
    width: 100%;
    height: 100%;
  }
  .nft-collection-header-buy-module-container {
    margin-top: 20px;
  }
  .nft-collection-header-data-and-buy-module-content {
    max-height: 90px;
  }
  /* =================================================== */
  .launchpad-collection-live-header-right-collection-description {
    font-size: 14px;
    max-height: 68px;
  }
  .launchpad-collection-live-header-right-price-container {
    margin-top: 10px;
  }
}
@media (max-width: 980px) {
  .nft-collection-header-container {
    max-width: 870px;
  }
  .nft-collection-header-data-and-buy-module-container {
    max-width: 350px !important;
  }
  .nft-collection-header-data-and-buy-module-content {
    max-width: 350px;
  }
  .nft-collection-header-owner-creator-container {
    max-width: 350px;
  }
  .nft-collection-header-owner-creator-wrap {
    max-width: 300px;
  }

  .nft-collection-header-owner-creator-title-and-name-owner,
  .nft-collection-header-owner-creator-title-and-name-creator {
    padding-left: 5px;
  }

  .nft-collection-header-line-separation {
    padding-top: 15px;
    max-width: 350px;
  }
  .nft-collection-header-buy-module-container {
    margin-top: 15px;
    max-width: 350px;
  }
  .nft-collection-header-buy-module-wrap {
    max-width: 290px;
  }
  .nft-collection-header-price-container,
  .nft-collection-header-bid-container {
    max-width: 140px;
  }
  .nft-collection-header-price-wrap,
  .nft-collection-header-bid-wrap {
    max-width: 105px;
  }
  .nft-collection-header-title-price,
  .nft-collection-header-eur-price,
  .nft-collection-header-title-bid,
  .nft-collection-header-eur-bid {
    font-size: 14px;
  }
  .nft-collection-header-eth-price,
  .nft-collection-header-eth-bid {
    font-size: 20px;
  }
  .nft-collection-header-buy-button,
  .nft-collection-header-bid-button {
    font-size: 20px;
  }
  /* ======================================================= */
  .launchpad-collection-live-header-right-collection-description {
    margin-top: 8px;
    margin-bottom: 15px;
  }
}
@media (max-width: 880px) {
  .nft-collection-header-container {
    max-width: 750px;
  }
  .nft-collection-header-picture {
    max-width: 645px;
  }
  .nft-collection-header-picture img {
    width: 400px;
    height: 400px;
  }
  .nft-collection-header-data-and-buy-module-container {
    max-width: 350px !important;
  }
  .nft-collection-header-data-and-buy-module-content {
    max-width: 350px;
    max-height: 70px;
  }
  .nft-collection-header-collection-name-and-number {
    height: 50px;
  }
  .nft-collection-header-nft-number {
    font-size: 25px;
    max-height: 25px;
  }
  .nft-collection-header-owner-creator-container {
    max-width: 350px;
  }
  .nft-collection-header-owner-creator-wrap {
    max-width: 300px;
  }

  .nft-collection-header-owner-creator-title-and-name-owner,
  .nft-collection-header-owner-creator-title-and-name-creator {
    padding-left: 5px;
  }

  .nft-collection-header-line-separation {
    padding-top: 15px;
    max-width: 350px;
  }
  .nft-collection-header-buy-module-container {
    margin-top: 15px;
    max-width: 350px;
    max-height: 200px;
  }
  .nft-collection-header-price-and-bid-container {
    max-height: 80px;
  }
  .nft-collection-header-buy-module-wrap {
    max-width: 290px;
    max-height: 180px;
  }
  .nft-collection-header-price-container,
  .nft-collection-header-bid-container {
    max-width: 140px;
    max-height: 80px;
  }
  .nft-collection-header-price-wrap,
  .nft-collection-header-bid-wrap {
    max-width: 105px;
    max-height: 60px;
  }
  .nft-collection-header-title-price,
  .nft-collection-header-eur-price,
  .nft-collection-header-title-bid,
  .nft-collection-header-eur-bid {
    font-size: 14px;
  }
  .nft-collection-header-eth-price,
  .nft-collection-header-eth-bid {
    font-size: 20px;
  }
  .nft-collection-header-buttons-container {
    max-height: 100px;
  }
  .nft-collection-header-buy-button,
  .nft-collection-header-bid-button {
    font-size: 20px;
    max-height: 40px;
  }
  /* ======================================================= */
  .launchpad-collection-live-button-container {
    bottom: 6%;
  }
  .launchpad-collection-live-header-right-creator-pic-and-usernamme {
    font-size: 14px;
  }
  .launchpad-collection-live-header-right-collection-name {
    font-size: 18px;
    max-height: 30px;
  }
  .launchpad-collection-live-header-right-collection-description {
    font-size: 12px;
    max-height: 58px;
    margin-top: 5px;
  }
  .launchpad-collection-live-header-right-eth-price {
    font-size: 20px;
  }
  .launchpad-collection-live-header-right-eur-price {
    font-size: 14px;
  }
  .launchpad-collection-live-header-right-mint-module-container {
    margin-top: 18px;
  }
  .launchpad-collection-live-header-right-mint-module-progress-bar-background {
    margin-top: 15px;
  }
  .launchpad-collection-live-header-right-mint-module-total-minted {
    margin-top: 8px;
  }
  .launchpad-collection-live-header-right-mint-module-mint-button {
    margin-top: 10px;
    font-size: 20px;
    height: 80%;
  }
}
