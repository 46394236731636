.nft-collection-overview-container {
  width: 100%;
  /* height: 100%; */
  max-width: 620px;
  max-height: 1932px;
  display: flex;
  flex-direction: column;
  /* background-color: red; */
}
.nft-collection-overview-utilities-container {
  width: 100%;
  height: 100%;
  max-height: 691px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* background-color: blue; */
}
.nft-collection-overview-utilities-title {
  width: 100%;
  height: 66px;
  max-width: 99px;
  display: flex;
  align-items: center;
  font-size: 30px;
  font-family: "britanica-heavy";
}

/* -----------------------------------------  */
/* -----------------------------------------  */
/* .nft-collection-overview-price-evolution-container {
  width: 100%;
  height: 406px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 44px;
}
.nft-collection-overview-price-evolution-title {
  width: 100%;
  max-width: 194px;
  height: 36px;
  display: flex;
  align-items: center;
  font-family: "britanica-heavy";
  font-size: 30px;
  margin-bottom: 22px;
}
.nft-collection-overview-price-evolution-chart {
  width: 614px;
  height: 348px;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
} */

