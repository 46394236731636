.athlete-profile-feed-container {
  width: 100%;
  height: 100%;
  max-width: 1240px;
  max-height: 1500px;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  /* overflow-y: scroll; */
  margin-top: 18px;
  /* background-color: yellow; */
}

.athlete-profile-feed-container::-webkit-scrollbar {
  display: none;
}
.athlete-profile-feed-free-container {
  width: 100%;
  max-width: 610px;
  flex-direction: column;
  /* background-color: green; */
  /* margin-right: 29px; */
}
.athlete-profile-feed-premium-container {
  /* background-color: red; */
  width: 100%;
  max-width: 610px;
  flex-direction: column;
}
@media (max-width: 1100px) {
    
}

@media (max-width: 1050px) {
    .athlete-profile-feed-container{
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
    }
}