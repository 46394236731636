.banner-and-profile-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-items: center;
  width: calc(100% - 30px);
  height: 100%;
  max-width: 1300px ;
  max-height: 329px !important;
  position: relative;
}
.banner-container {
min-width: 100%;
min-height: 100%;
max-height: 100%;
max-width: 100%;
width: 100%;
height: 100%;
}
.banner-and-profile-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  min-width: 100%;
  min-height: 100%;
  max-width: 100% !important;
  max-height: 100%;
  border-radius: 20px;}
.profile-pic-container {
  width: 170px;
  height: 170px;
  border-radius: 147px;
  border: 6px solid #ffffff;
  box-sizing: border-box;
  position: absolute;
  left: 1.605%;
  top: 53%;
  right: 84.751%;
  cursor: pointer;
  overflow: hidden;
}

@media (max-width: 1300px) {
  .profile-pic-container {
    width: 141px;
    height: 141px;
    left: 30px;
    top: 62%;
  }
}

@media (max-width: 1200px) {
  .profile-pic-container {
    width: 131px;
    height: 131px;
    left: 30px;
    top: 67%;
  }
}
@media (max-width: 1080px) {
  .profile-pic-container {
    width: 121px;
    height: 121px;
    left: 35px;
    top: 70%;
  }
}
@media (max-width: 970px) {
}
@media (max-width: 870px) {
  .profile-pic-container {
    width: 111px;
    height: 111px;
    left: 35px;
    top: 72%;
  }
}
