.notificationpopup-component {
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.1);
  background: #ffffff;
  border-radius: 20px;
  width: 450px;
  min-height: 702px;
  max-height: 702px;
  position: relative;
}
.notificationpopup-component > span {
  font-family: "Britanica-Heavy";
  font-size: 25px;
  margin-top: 19px;
  margin-bottom: 18px;
}
.notificationpopup-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  border-top: 0.3px solid rgba(0, 0, 0, 0.2);
  /* border-bottom: 0.3px solid rgba(0, 0, 0, 0.2); */
}

.notificationpopup-container-submenu-wrap {
  display: flex;
  width: 100%;
}
.notificationpopup-container-submenu-subwrap {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
}
.notificationpopup-container-submenu-subwrap-clicked {
  border-bottom: 3px solid #f6d463;
}
.notificationpopup-container-submenu-subwrap > button {
  width: 100%;
  min-height: 44px !important;
  background-color: transparent;
  border: none;
  font-family: "Britanica-Heavy";
  font-size: 16px;
}
.notificationpopup-container-mapping-wrap {
  overflow: scroll;
  /* max-height: 374px !important; */
  max-height: 523px !important;
  /* background-color: red; */
}
.athlete-following-supporting-popup-container-mapping-wrap{
  max-height: 476px !important;
}
/* .notificationpopup-container-mapping-wrap::-webkit-scrollbar {
  display: none;
} */
.notificationpopup-container-notification-wrap {
  display: flex;
  align-items: center;
  width: 100%;
  border-bottom: 0.3px solid rgba(0, 0, 0, 0.2);
  text-decoration: none;
  color: black;
  padding-top: 10px ;
  padding-bottom: 10px;
  /* background-color: red; */
}
.notificationpopup-container-notification-wrap > img {
  width: 50px;
  height: 50px;
  border-radius: 999px;
  /* margin-top: 21px; */
  margin-left: 21px;
}
.notificationpopup-container-notification-wrap-content-wrap {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 21px;
  margin-left: 16px;
  margin-bottom: 18px;
}
.notificationpopup-container-notification-wrap-content-subwrap {
  max-width: 300px;
}
.notificationpopup-container-notification-wrap-content-subwrap
  > span:nth-child(2n + 1) {
  font-family: "Britanica-Heavy";
  font-size: 14px;
  line-height: 17px;
}
.notificationpopup-container-notification-wrap-content-subwrap span:nth-child(2){
  /* background-color: red ; */
  font-size: 12px;
  padding-left: 10px;

}
.notificationpopup-container-notification-wrap-content-wrap > span {
  font-family: "Britanica-Regular";
  font-size: 12px;
  /* margin-top: 5px; */
}
.notificationpopup-component > button {
  width: 410px;
  min-height: 56px !important;
  background-color: #f6d463;
  border: none;
  border-radius: 10px;
  font-family: "Britanica-Heavy";
  font-size: 20px;
  margin-top: 20px;
  margin-bottom: 29px;
  position: absolute;
  bottom: 0;
}

.template-pop-up-line-separation{
    width: 100%;
    height: 10px;
    /* background-color: red; */
    position: absolute;
    bottom: 105px;
    border-top: 0.3px solid rgba(0, 0, 0, 0.2);
}
@media (max-height: 875px) {
  .notificationpopup-component {
    min-height: 632px !important;
    max-height: 632px;
  }
  .notificationpopup-container-mapping-wrap {
    max-height: 453px !important;
  }
  .athlete-following-supporting-popup-container-mapping-wrap{
    max-height: 408px !important;
  }
}
@media (max-height: 770px) {
  .notificationpopup-component {
    min-height: 632px !important;
    max-height: 552px;
  }
  .notificationpopup-container-mapping-wrap {
    max-height: 373px !important;
    /* background-color: red; */
  }
  .athlete-following-supporting-popup-container-mapping-wrap{
    max-height: 328px !important;
  }
}
