.post-poll-component {
  width: 100%;
  /* height: 369px; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  /* background-color: red; */
}
.post-poll-file-wrap {
  width: 459px;
  height: 459px;
  /* background-color: rebeccapurple; */
  position: relative;
}
.post-poll-file {
  width: 459px;
  height: 459px;
  border-radius: 10px;
}
.post-poll-file-cross {
  position: absolute;
  right: 20px;
  top: 20px;
  cursor: pointer;
}
.post-poll-component > textarea {
  width: 100%;
  /* height: 349px; */
  resize: none;
  border: none;
  font-family: "Britanica-Regular";
  font-style: normal;
  font-weight: 500 !important;
  font-size: 16px;
  line-height: 19px;
  /* background-color: rgba(255, 255, 0, 0.452);  */
}
.post-poll-programmed-post-container {
  width: 100%;
  /* height: 60px; */
  display: flex;
  /* padding-left: 102px; */
  margin-top: 10px;
  margin-bottom: 10px;
  justify-content: space-around;
  align-items: center;
}
.post-poll-programmed-post-wrap {
  display: flex;
  /* background-color: wheat; */
  height: 35px;
  padding: 4px 9px;
  border-radius: 10px;
  cursor: pointer;
  user-select: none;
}
.post-poll-programmed-post-wrap:hover {
  background-color: #00000015;
  transition-duration: 300ms;
}
.post-poll-programmed-post-wrap:active {
  transform: scale(0.85);
}
.post-poll-programmed-post-container img {
  width: 21px;
  height: 21px;
  padding: 5px;
  border-radius: 50%;
  cursor: pointer;
  margin-bottom: 15px;
}
.post-poll-programmed-post-container img:hover {
  background-color: #00000015;
  transition-duration: 300ms;
}
.post-poll-programmed-post-title {
  font-family: "Britanica-Heavy";
  padding-top: 8px;
  padding-left: 8px;
}
.post-poll-programmed-post-date-time-picker-container {
  /* width: 100%; */
  /* height: 50px; */
  display: flex;
  justify-content: center;
  /* background-color: aqua; */
}

.post-poll-programmed-post-date-time-picker-container input{
height: 23px;
}
.post-poll-programmed-post-date-time-picker-modal {
  /* width: 100vw; */
  /* height: 100vh; */
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 654654564654654654654;
  background-color: rgba(0, 0, 0, 0.664);
}
.post-poll-component > textarea:focus {
  border: none;
  outline: none;
}
.post-poll-component > textarea:focus::placeholder {
  color: transparent;
}
.post-poll-component > textarea::placeholder {
  font-family: "Britanica-Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
}
.post-poll-add-container {
  display: flex;
  justify-content: space-between;
  width: 333px;
}
.post-poll-add-picture-wrap {
  width: 126px;
  display: flex;
  justify-content: space-between;
  margin: 0;
  padding: 0;
  background: none;
  border: none;
  cursor: pointer;
}
.post-poll-add-picture-wrap > img {
  width: 20px;
  height: 18.02px;
}
.post-poll-add-picture-wrap > span {
  font-family: "Britanica-Heavy";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
}
.post-poll-add-picture-wrap > input {
  display: none;
}
.post-poll-add-poll-wrap {
  width: 141px;
  display: flex;
  justify-content: space-between;
  margin: 0;
  padding: 0;
  background: none;
  border: none;
  cursor: pointer;
}
.post-poll-add-poll-wrap > img {
  width: 18px;
  /* object-fit: contain !important; */
}
.post-poll-add-poll-wrap > span {
  font-family: "Britanica-Heavy";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
}
