.nftcollectionheader-component {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 1246px;
}
.nftcollectionheader-bannerandprofilepicture-wrap {
  max-height: 300px;
  position: relative;
}
.nftcollectionheader-bannerandprofilepicture-wrap > img {
  width: 100%;
  border-radius: 20px;
  max-height: 300px;
  object-fit: cover;
}
.nftcollectionheader-bannerandprofilepicture-no-img {
  width: 100%;
  height: 100%;
  background-color: #f6d463;
  opacity: 0.3;
}
.nftcollectionheader-profilepicture-wrap {
  width: 170px;
  height: 170px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: -20px;
  left: 20px;
  border-radius: 20px;
  background-color: white;
}
.nftcollectionheader-profilepicture-wrap > img {
  width: 93%;
  height: 93%;
  border-radius: 15px;
}
.nftcollectionheader-profilepicture-no-img {
  width: 93%;
  height: 93%;
  border-radius: 15px;
  background-color: #f6d463;
  opacity: 0.5;
}
.nftcollectionheader-content-container {
  display: flex;
  align-items: flex-start;
  padding-top: 48px;
  /* height: 238px; */
}
.nftcollectionheader-content-wrap {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  /* height: 143px; */
  width: 100%;
  position: relative;
}
.nftcollectionheader-content-wrap-redirection-button {
  width: 150px;
  height: 46px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f6d463;
  border-radius: 10px;
  text-decoration: none;
  color: black;
  font-family: "britanica-heavy";
  position: absolute;
  right: 0;
}
.nftcollectionheader-content-wrap-redirection-button:hover {
  background-color: #f7c520;
  transition-duration: 300ms;
}
.nftcollectionheader-content-wrap-redirection-button:active {
  transform: scale(0.85);
  transition-duration: 300ms;
}
.nftcollectionheader-content-wrap-sport {
  font-family: "Britanica-Black";
  font-size: 20px;
  color: #f6d463;
  margin-bottom: 9px;
  cursor: pointer;
}
.nftcollectionheader-content-wrap-sport:hover {
  text-decoration: underline;
  text-decoration-thickness: 2px;
}
.nftcollectionheader-content-wrap-namestatssocial-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.nftcollectionheader-content-wrap-namestatssocial-wrap-namestats {
  display: flex;
  align-items: center;
}
.nftcollectionheader-content-wrap-namestatssocial-wrap-namestats-title {
  display: flex;
  align-items: center;
  font-family: "Britanica-Heavy";
  font-size: 35px;
}
.nftcollectionheader-content-wrap-namestatssocial-wrap-namestats-title > img {
  width: 20px;
  height: 20px;
  margin-left: 8px;
  margin-top: 7px;
}

.nftcollectionheader-content-wrap-namestatssocial-wrap-social-container {
  display: flex;
  align-items: center;
}
.nftcollectionheader-content-wrap-namestatssocial-wrap-social-container-social {
  display: flex;
  justify-content: space-between;
  width: 105px;
}
.nftcollectionheader-content-wrap-namestatssocial-wrap-social-container-social
  > a
  > img:nth-child(1) {
  width: 25px;
  height: 17.5px;
}
.nftcollectionheader-content-wrap-namestatssocial-wrap-social-container-social
  > a
  > img:nth-child(2) {
  width: 20px;
  height: 16.27px;
}
.nftcollectionheader-content-wrap-namestatssocial-wrap-social-container-social
  > a
  > img:nth-child(3) {
  width: 20px;
  height: 20px;
}
.nftcollectionheader-content-container-descriptionn {
  width: 541px;
  font-size: 14px;
  line-height: 17px;
  padding-top: 12px;
  padding-bottom: 20px;
  max-height: 80px;
  overflow-y: scroll;
  margin-bottom: 20px;
  /* background-color: red; */
}
.nftcollectionheader-content-container-descriptionn::-webkit-scrollbar,
.nftcollectionheader-content-container-descriptionn {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  width: 100.5%;
}
.nftcollectionheader-content-container-descriptionn::-webkit-scrollbar {
  width: 6px; /* Set the width of the scrollbar */
  border-radius: 10px;
}
.nftcollectionheader-content-container-descriptionn::-webkit-scrollbar-track {
  background: transparent; /* Set the track background */
}

.nftcollectionheader-content-container-descriptionn::-webkit-scrollbar-thumb {
  background-color: #888; /* Set the color of the scrollbar */
  border-radius: 10px; /* Set the border-radius */
}

.nftcollectionheader-content-container-descriptionn::-webkit-scrollbar-thumb:hover {
  background-color: #555; /* Set the color when hovered */
}
.nftcollectionheader-content-wrap-stats-container {
  display: flex;
  align-items: center;
}
.nftcollectionheader-content-wrap-stats-wrap {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.nftcollectionheader-content-wrap-stats-wrap-value {
  font-family: "Britanica-Heavy";
  font-size: 20px;
}
.nftcollectionheader-content-wrap-stats-wrap-name {
  font-size: 14px;
  line-height: 17px;
}
.nftcollectionheader-content-wrap-stats-wrap-1 {
  padding-right: 25px;
}
.nftcollectionheader-content-wrap-stats-wrap-2 {
  padding-right: 15px;
}
.nftcollectionheader-content-wrap-stats-wrap-3 {
  padding-right: 15px;
}
.nft-collection-header-mint-button-redirection {
  height: 56px;
  width: 570px;
  margin-top: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 25px;
  color: black;
  font-family: "britanica-heavy";
  text-decoration: none;
  background-color: #f6d463;
  border-radius: 10px;
  font-size: 25px;
  position: relative;
}
.nft-collection-header-mint-button-redirection:hover {
  background-color: #f7c520;
  transition-duration: 300ms;
}
.nft-collection-header-mint-button-redirection:active {
  transform: scale(0.85);
}
.nft-collection-header-mint-button-redirection div {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  position: absolute;
  background: #85d87e;
  top: 15%;
  right: 5%;
}
@media (max-width: 1280px) {
  .nft-collection-header-mint-button-redirection {
    width: 490px;
    margin-left: 0px;
  }
}
@media (max-width: 1200px) {
  .nftcollectionheader-profilepicture-wrap {
    width: 150px;
    height: 150px;
  }
}
@media (max-width: 1180px) {
  .nft-collection-header-mint-button-redirection {
    width: 350px;
  }
}
@media (max-width: 1150px) {
  .nftcollectionheader-profilepicture-wrap {
    width: 130px;
    height: 130px;
  }
  .nftcollectionheader-content-container {
    padding-top: 30px;
    height: 226.1px;
  }
  .nftcollectionheader-content-wrap-sport {
    font-size: 19px;
  }
  .nftcollectionheader-content-wrap-namestatssocial-wrap-namestats-title {
    font-size: 33.25px;
  }
  .nftcollectionheader-content-wrap-namestatssocial-wrap-namestats-title > img {
    width: 19px; /* retour a 20 ?*/
    height: 19px; /* retour a 20 ?*/
    margin-top: 6.65px;
  }

  .nftcollectionheader-content-wrap-namestatssocial-wrap-social-container-social {
    width: 99.75px;
  }
  .nftcollectionheader-content-wrap-namestatssocial-wrap-social-container-social
    > a
    > img:nth-child(1) {
    width: 23.75px;
    height: 16.625px;
  }
  .nftcollectionheader-content-wrap-namestatssocial-wrap-social-container-social
    > a
    > img:nth-child(2) {
    width: 19px;
    height: 15.4565px;
  }
  .nftcollectionheader-content-wrap-namestatssocial-wrap-social-container-social
    > a
    > img:nth-child(3) {
    width: 19px;
    height: 19px;
  }
  .nftcollectionheader-content-wrap-namestatssocial-wrap-social-container
    > button:nth-last-child(1) {
    width: 99.75px !important;
    height: 37.05px !important;
    border-radius: 4.75px !important;
    font-size: 15.2px !important;
  }
  .nftcollectionheader-content-container-description {
    width: 418.95px;
    font-size: 13.3px;
    line-height: 16.15px;
    padding-bottom: 19px;
  }
  .nftcollectionheader-content-wrap-stats-wrap-value {
    font-size: 19px;
  }
  .nftcollectionheader-content-wrap-stats-wrap-name {
    font-size: 13.3px;
    line-height: 16.15px;
  }
  .nftcollectionheader-content-wrap-stats-wrap-1 {
    padding-right: 23.75px;
  }
  .nftcollectionheader-content-wrap-stats-wrap-2 {
    padding-right: 14.25px;
  }
  .nftcollectionheader-content-wrap-stats-wrap-3 {
    padding-right: 14.25px;
  }
}
@media (max-width: 1080px) {
  .nft-collection-header-mint-button-redirection {
    width: 350px;
    font-size: 20px;
  }
}
@media (max-width: 1050px) {
  .nftcollectionheader-content-container {
    padding-top: 40.8px;
    height: 202.3px;
  }
  .nftcollectionheader-content-wrap-sport {
    font-size: 17px;
  }
  .nftcollectionheader-content-wrap-namestatssocial-wrap-namestats-title {
    font-size: 29.75px;
  }
  .nftcollectionheader-content-wrap-namestatssocial-wrap-namestats-title > img {
    width: 17px; /* retour a 20 ?*/
    height: 17px; /* retour a 20 ?*/
    margin-top: 5.95px;
  }

  .nftcollectionheader-content-wrap-namestatssocial-wrap-social-container-social {
    width: 89.25px;
  }
  .nftcollectionheader-content-wrap-namestatssocial-wrap-social-container-social
    > a
    > img:nth-child(1) {
    width: 21.25px;
    height: 14.875px;
  }
  .nftcollectionheader-content-wrap-namestatssocial-wrap-social-container-social
    > a
    > img:nth-child(2) {
    width: 17px;
    height: 13.8295px;
  }
  .nftcollectionheader-content-wrap-namestatssocial-wrap-social-container-social
    > a
    > img:nth-child(3) {
    width: 17px;
    height: 17px;
  }
  .nftcollectionheader-content-wrap-namestatssocial-wrap-social-container
    > button:nth-last-child(1) {
    width: 89.25px !important;
    height: 33.15px !important;
    border-radius: 4.25px !important;
    font-size: 13.6px !important;
  }
  .nftcollectionheader-content-container-description {
    width: 374.85px;
    font-size: 11.9px;
    line-height: 14.45px;
    padding-bottom: 17px;
  }
  .nftcollectionheader-content-wrap-stats-wrap-value {
    font-size: 17px;
  }
  .nftcollectionheader-content-wrap-stats-wrap-name {
    font-size: 11.9px;
    line-height: 14.45px;
  }
  .nftcollectionheader-content-wrap-stats-wrap-1 {
    padding-right: 21.25px;
  }
  .nftcollectionheader-content-wrap-stats-wrap-2 {
    padding-right: 12.75px;
  }
  .nftcollectionheader-content-wrap-stats-wrap-3 {
    padding-right: 12.75px;
  }
}
@media (max-width: 900px) {
  .nftcollectionheader-profilepicture-wrap {
    width: 110px;
    height: 110px;
  }
  .nftcollectionheader-content-container {
    padding-top: 30px;
    height: 178.5px;
  }
  .nftcollectionheader-content-wrap-sport {
    font-size: 15px;
  }
  .nftcollectionheader-content-wrap-namestatssocial-wrap-namestats-title {
    font-size: 26.25px;
  }
  .nftcollectionheader-content-wrap-namestatssocial-wrap-namestats-title > img {
    width: 15px; /* retour a 20 ?*/
    height: 15px; /* retour a 20 ?*/
    margin-top: 5.25px;
  }

  .nftcollectionheader-content-wrap-namestatssocial-wrap-social-container-social {
    width: 78.75px;
  }
  .nftcollectionheader-content-wrap-namestatssocial-wrap-social-container-social
    > a
    > img:nth-child(1) {
    width: 18.75px;
    height: 13.125px;
  }
  .nftcollectionheader-content-wrap-namestatssocial-wrap-social-container-social
    > a
    > img:nth-child(2) {
    width: 15px;
    height: 12.2025px;
  }
  .nftcollectionheader-content-wrap-namestatssocial-wrap-social-container-social
    > a
    > img:nth-child(3) {
    width: 15px;
    height: 15px;
  }
  .nftcollectionheader-content-wrap-namestatssocial-wrap-social-container
    > button:nth-last-child(1) {
    width: 78.75px !important;
    height: 29.25px !important;
    border-radius: 3.75px !important;
    font-size: 12px !important;
  }
  .nftcollectionheader-content-container-description {
    width: 330.75px;
    font-size: 10.5px;
    line-height: 12.75px;
    padding-bottom: 15px;
  }
  .nftcollectionheader-content-wrap-stats-wrap-value {
    font-size: 15px;
  }
  .nftcollectionheader-content-wrap-stats-wrap-name {
    font-size: 10.5px;
    line-height: 12.75px;
  }
  .nftcollectionheader-content-wrap-stats-wrap-1 {
    padding-right: 18.75px;
  }
  .nftcollectionheader-content-wrap-stats-wrap-2 {
    padding-right: 11.25px;
  }
  .nftcollectionheader-content-wrap-stats-wrap-3 {
    padding-right: 11.25px;
  }
}
