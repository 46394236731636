.user-activity-container {
  height: 100%;
  width: 100%;
  max-height: 463px;
  max-width: 1240px;
  position: relative;
}

.nft-list-activity-user-container {
  overflow-y: scroll;
  overflow-x: none !important;
  height: 100%;
  max-height: 440px;
  
}
.nft-list-activity-user-container::-webkit-scrollbar{
  display: none;
}