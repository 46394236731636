.launchpadall-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 95px;
  max-width: 1285px;
}
.launchpadall-wrap {
  width: 100%;
  max-width: 97%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.launchpadall-header-wrap {
  margin-bottom: 50px;
}
.launchpad-livelaunches-wrap {
  /* max-width: 1245px; */
  max-width: 100%;
  margin-bottom: 50px;
}
.launchpad-Upcominglaunches-wrap {
  /* margin-left: 60px; */
  max-width: 100%;

  margin-bottom: 98px;
}
@media (max-width: 1337px) {
  .launchpad-livelaunches-wrap {
    /* margin-left: 90px; */
  }
  .launchpad-Upcominglaunches-wrap {
    /* margin-left: 90px; */
  }
}
@media (max-width: 1300px) {
  .launchpadall-wrap {
    max-width: 95vw;
  }
}
