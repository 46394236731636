.premium-content-locked-container {
  width: 100%;
  height: 100%;
  /* max-width: 1249px !important; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0%;
  left: 0%;
  filter: blur(0px) !important;
  z-index: 959;
  border-radius: 20px;
  border: solid 2px #f6d463 !important;
  /* border-right: solid 2px #f6d463 !important;
  margin-right:0px !important ;
  padding-right:0px !important ; */
}
.premium-content-locked-locker-logo {
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: #f6d463;
}
.premium-content-locked-cta-premium-content {
  width: 100%;
  height: 100%;
  max-width: 330px;
  max-height: 48px;
  font-family: "britanica-heavy";
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  padding-top: 15px;
}
.premium-content-locked-cta-premium-content-display-name{
  color: #f6d463;
  font-style: italic;
  cursor: pointer;
}
.premium-content-locked-cta-premium-content-display-name:hover{
  color: #f7c520;
}
.premium-content-locked-button {
  outline: none;
  margin: 0;
  padding: 0;
  background: none;
  border: none;
  cursor: pointer;
  width: 159px;
  height: 50px;
  background: #f6d463;
  border-radius: 10px;
  font-family: "britanica-black";
  font-size: 20px;
  line-height: 24px;
  margin-top: 47px;
}
.premium-content-locked-button:hover{
  background-color: #f7c520;
  transition: 500ms;
}
.premium-content-locked-button:active{
transform: scale(0.8);
}
