.nft-collection-history-container {
  width: 620px;
  height: 410px ;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 15px;
  overflow-y: scroll;
}
.nft-collection-history-container::-webkit-scrollbar{
  display: none;
}
.nft-collection-history-wrap{
    width: 579px;
    height: 340px;
    display: flex;
    overflow-y: scroll;
    align-items: flex-start;
    flex-direction: column;
}
.nft-collection-history-wrap::-webkit-scrollbar {
  display: none;
}
/* =================================== */
.nft-collection-history-bids {
  width: 579px;
  height: 50px;
  padding-bottom: 20px;
  display: flex;
  align-items: center;
  /* background-color: deeppink; */
}
.nft-collection-history-bids:last-child {
  padding-bottom: 0px;
}
.nft-collection-history-bids-profile-pic {
  width: 50px;
  height: 50px;
}
.nft-collection-history-bids-profile-pic img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  cursor: pointer;
}
.nft-collection-history-bids-username-date-price-container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.nft-collection-history-bids-username-and-date-container {
  max-width: 200px;
  height: 44px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  margin-left: 20px;
  /* background-color: rgb(52, 206, 52); */
}
.nft-collection-history-bids-username {
  font-size: 20px;
  font-family: "britanica-heavy";
  height: 24px;
  cursor: pointer;
}
.nft-collection-history-bids-username:hover{
text-decoration: underline;
}
.nft-collection-history-bids-date {
  font-size: 16px;
  height: 19px;
}
.nft-collection-history-bids-buy{
    color: #F6D463;
    font-family: "britanica-heavy";
    position: relative;
    cursor: pointer;
    left: -15%;
    top: -20%;
}
.nft-collection-history-bids-buy:hover{
  text-decoration: underline;
  text-decoration-thickness: 2.5px;
}
.nft-collection-history-bids-price {
  height: 44px;
  max-width: 300px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  /* background-color: rgb(57, 183, 120); */
}
.nft-collection-history-bids-price-eth {
  height: 24px;
  max-width: 100%;
  font-family: "britanica-heavy";
  font-size: 20px;
  text-align: right;
}
.nft-collection-history-bids-price-eur {
  height: 19px;
  max-width: 100%;
  font-family: "britanica-regular";
  font-size: 16px;
  text-align: right;
}
