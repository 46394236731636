.progressbar-poll-subwrap {
    height: 55px;
    width: 100%;
    /* background-color: red; */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .progressbar-poll-subwrap button {
      outline: none;
      background: none;
      border: none;
      cursor: pointer;
      width: 100%;
  }
  .progressbar-poll {
    box-sizing: border-box;
    width: 464px;
    height: 40px;
    border: 1px solid #f6d463;
    border-radius: 5px;
    position: relative;
  }
  .pollstate-bar {
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(246, 212, 99, 0.5);
    border-radius: 5px 0px 0px 5px;
    transition: 500ms;
    max-width: 464px;
  }
  .survey-percent {
    position: absolute;
    left: 88%;
    font-size: 14px;
  }
  .choice-name {
    position: absolute;
    left: 4%;
    display: flex;
    align-items: center;
    /* left: 490px; */
    font-size: 14px;
    font-family: "britanica-heavy";
  }
  .checkmark-logo{
    width: 15px;
    color: #F6D463;
    fill: #F6D463;
    position: relative;
    left: 20%;
  }
