.nft-collection-overview-more-about-collection-container {
  max-width: 100%;
  height: 185px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  margin-top: 200px;
}
.nft-collection-overview-more-about-collection-title {
  max-width: 100%;
  height: 36px;
  display: flex;
  align-items: center;
  font-family: "britanica-heavy";
  font-size: 30px;
  /* background-color: darkgoldenrod; */
}
.nft-collection-overview-more-about-collection-description {
  width: 100%;
  height: 133px;
  display: flex;
  /* align-items: center; */
  /* background-color: violet; */
  text-align: justify;
  max-height: 133px;
  overflow-y: scroll;
}
.nft-collection-overview-more-about-collection-description::-webkit-scrollbar {
  width: 6px; /* Set the width of the scrollbar */
  border-radius: 10px;
}
.nft-collection-overview-more-about-collection-description::-webkit-track {
  background: transparent; /* Set the track background */
}
.nft-collection-overview-more-about-collection-description::-webkit-scrollbar-thumb {
  background-color: #888; /* Set the color of the scrollbar */
  border-radius: 10px; /* Set the border-radius */
}

.nft-collection-overview-more-about-collection-description::-webkit-scrollbar-thumb:hover {
  background-color: #555; /* Set the color when hovered */
}

/* Conditionnal Rendering */
.nft-collection-overview-more-about-collection-container-nft-single-page-header {
  /* background-color: rgb(31, 139, 64); */
  max-width: 100%;
  max-height: 185px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  margin-top: 25px;
  margin-left: 25px;
  padding: 8px;
}
.nft-collection-overview-more-about-collection-description-nft-single-page-header {
  max-height: 100%;
  overflow-y: scroll;
}
.nft-collection-overview-more-about-collection-description-nft-single-page-header::-webkit-scrollbar {
  width: 6px; /* Set the width of the scrollbar */
  border-radius: 10px;
}
.nft-collection-overview-more-about-collection-description-nft-single-page-header::-webkit-track {
  background: transparent; /* Set the track background */
}
.nft-collection-overview-more-about-collection-description-nft-single-page-header::-webkit-scrollbar-thumb {
  background-color: #888; /* Set the color of the scrollbar */
  border-radius: 10px; /* Set the border-radius */
}

.nft-collection-overview-more-about-collection-description-nft-single-page-header::-webkit-scrollbar-thumb:hover {
  background-color: #555; /* Set the color when hovered */
}
@media (max-width: 1280px) {
  .nft-collection-overview-more-about-collection-container-nft-single-page-header {
    margin-left: 0px;
    margin-top: 30px;
    max-width: 480px;
  }
}
@media (max-width: 1080px) {
  .nft-collection-overview-more-about-collection-container-nft-single-page-header {
    margin-top: 10px;
  }
  .nft-collection-overview-more-about-collection-description-nft-single-page-header {
    height: 80px;
  }
}
