.nft-collection-more-about-athlete-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  max-width: 1380px;
  width: 100%;
}
.nft-collection-more-about-athlete-title {
  width: 100%;
  max-width: 100%;
  height: 36px;
  display: flex;
  font-size: 30px;
  font-family: "britanica-heavy";
  margin-bottom: 20px;
}
.nft-collection-more-about-athlete-wrap {
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 601px;
  display: flex;
}
.nft-collection-more-about-athlete-picture-container {
  max-width: 582px;
  max-height: 601px;

  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}
.nft-collection-more-about-athlete-picture-container img {
  width: 100%;
  height: 100%;
  border-radius: 20px 0px 0px 20px;
}

.nft-collection-more-about-athlete-presentation-container {
  flex: 1;
  max-width: 661px;
  background: #2b2f34;
  border-radius: 0px 20px 20px 0px;
  position: relative;
  color: white;
}
.nft-collection-more-about-athete-sport-title {
  position: absolute;
  bottom: 70.881%;
  left: 6.9591%;
  font-size: 20px;
  height: 24px;
  font-family: "britanica-heavy-expanded";
}
.nft-collection-more-about-athlete-name {
  height: 24px;
  bottom: 65.224%;
  left: 6.9591%;
  position: absolute;
  font-size: 35px;
  font-family: "britanica-heavy";
}
.nft-collection-more-about-athlete-description {
  height: 100%;
  max-height: 88px;
  left: 6.9591%;
  bottom: 46.25623%;
  position: absolute;
  text-align: justify;
  padding-right: 15px;
}
.nft-collection-more-about-athlete-fans-count {
  height: 31px;
  position: absolute;
  left: 6.9591%;
  bottom: 17.1064802%;
  font-size: 19px;
  font-family: "britanica-heavy-expanded";
}
.nft-collection-more-about-athlete-view-profile-button {
  border: none;
  cursor: pointer;
  left: 6.9591%;
  bottom: 10.1231%;
  position: absolute;
  width: 166px;
  height: 44px;
  background: #f6d463;
  border-radius: 5px;
  font-size: 20px;
  font-family: "britanica-heavy";
  text-decoration: none;
  color: black;
  display: flex;
  align-items: center;
  justify-content: center;
}
.nft-collection-more-about-athlete-view-profile-button:active {
  transform: scale(0.8);
  transition-duration: 200ms;
}
.nft-collection-more-about-athlete-view-profile-button:hover {
  background-color: #f7c520 !important;
  transition-duration: 300ms !important;
}

@media (max-width: 1280px) {
  .nft-collection-more-about-athete-sport-title {
    bottom: 77.881%;
  }
  .nft-collection-more-about-athlete-name {
    bottom: 72.224%;
  }
  .nft-collection-more-about-athlete-description {
    bottom: 50.25623%;
    height: 200px !important;
    /* background-color: red; */
    overflow-y: scroll;
  }

/*  */
.nft-collection-more-about-athlete-description::-webkit-scrollbar {
  width: 6px; /* Set the width of the scrollbar */
  border-radius: 10px;
}
.nft-collection-more-about-athlete-description::-webkit-track {
  background: transparent; /* Set the track background */
}
.nft-collection-more-about-athlete-description::-webkit-scrollbar-thumb {
  background-color: #888; /* Set the color of the scrollbar */
  border-radius: 10px; /* Set the border-radius */
}

.nft-collection-more-about-athlete-description::-webkit-scrollbar-thumb:hover {
  background-color: #555; /* Set the color when hovered */
}

/*  */
  .nft-collection-more-about-athlete-fans-count {
    left: 6.9591%;
  }
  .nft-collection-more-about-athlete-view-profile-button {
    bottom: 26.1231%;
  }
}
@media (max-width: 1180px) {
  .nft-collection-more-about-athete-sport-title {
    bottom: 77.881%;
  }
  .nft-collection-more-about-athlete-name {
    bottom: 72.224%;
  }
  .nft-collection-more-about-athlete-description {
    bottom: 46.25623%;
  }
  .nft-collection-more-about-athlete-fans-count {
    bottom: 30%;
  }
  .nft-collection-more-about-athlete-view-profile-button {
    bottom: 20.1231%;
  }
}

@media (max-width: 980px) {
  .nft-collection-more-about-athete-sport-title {
    bottom: 80.881%;
    font-size: 18px;
  }
  .nft-collection-more-about-athlete-name {
    bottom: 74.224%;
    font-size: 30px;
  }
  .nft-collection-more-about-athlete-description {
    bottom: 46.25623%;
    font-size: 14px;
  }
  .nft-collection-more-about-athlete-fans-count {
    bottom: 29%;
    font-size: 18px;
  }
  .nft-collection-more-about-athlete-view-profile-button {
    bottom: 16.1231%;
    font-size: 18px;
  }
}
@media (max-width: 880px) {
  .nft-collection-more-about-athete-sport-title {
    bottom: 80.881%;
    font-size: 18px;
  }
  .nft-collection-more-about-athlete-name {
    bottom: 74.224%;
    font-size: 30px;
  }
  .nft-collection-more-about-athlete-description {
    bottom: 42.25623%;
    font-size: 14px;
  }
  .nft-collection-more-about-athlete-fans-count {
    bottom: 23%;
    font-size: 18px;
  }
  .nft-collection-more-about-athlete-view-profile-button {
    bottom: 10.1231%;
    font-size: 18px;
  }
}
