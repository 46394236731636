.likes-comments-container-publication {
  width: 90%;
  min-height: 70px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.like-button-container {
  width: 100px;
  height: 20px;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-items: center;
}
.like-button-container-pollpost {
  width: 105px;
}

.likes-counter-publication {
  font-family: "britanica-heavy";
  font-size: 14px;
  margin-left: 5px;
}
.likes-counter-publication a {
  text-decoration: none;
  color: black;
}
.likes-counter-publication a:hover {
  text-decoration: underline;
}

.comments-publication {
  width: 120px;
  height: 20px;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-items: center;
}
.comments-publication-fullpagepost-modal-and-page {
  width: 160px;
  height: 20px;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-items: center;
  /* background-color: violet; */
}

.comments-counter-publication {
  font-family: "britanica-heavy";
  font-size: 14px;
  margin-left: 5px;
}
.comments-counter-publication a {
  text-decoration: none;
  color: black;
}
.comments-counter-publication a:hover {
  text-decoration: underline;
}

.likes-comments-container-publication-fullpagepost{
  width: 90%;
  min-height: 70px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  /* background-color: blue; */
}
.like-button-container-fullpagepost {
  width: 100px;
  height: 20px;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-items: center;
}
.logo-comments-publication-fullpagepost-modal-and-page{
  width: 20px !important;
  /* background-color: red; */
}
@media (max-width: 1050px) {
  /* .like-button-container-pollpost{
    width: 125px;
  } */
  .comments-publication {
    width: 120px;
    height: 20px;
  }
  .comments-publication-fullpagepost-modal-and-page {
    width: 120px;
    height: 20px;
  }
  .comments-publication-pollpost {
    width: 140px;
    height: 20px;
  }
  .likes-counter-publication,
  .comments-counter-publication {
    font-size: 12px;
  }
  .likes-counter-publication-pollpost,
  .comments-counter-publication-pollpost {
    font-size: 14px;
  }
  .logo-comments-publication {
    width: 20px !important;
  }
  .logo-comments-publication-fullpagepost-modal-and-page {
    width: 10px !important;
    /* background-color: red; */
  }
  .logo-comments-publication-pollpost svg {
    width: 20px !important;
    /* background-color: red; */
  }
  /* MODIFIER NOM QUAND BACKEND DISPO */
  .logo-comments-publication svg {
    width: 18px !important;
  }
  .logo-comments-publication-fullpagepost-modal-and-page svg{
    width: 15px !important;
  }
  .likes-comments-container-publication-fullpagepost{
    min-height: 40px;
    /* padding-bottom: 10px; */
  }
  .likes-comments-container-publication-fullpagepost > div > div:nth-child(2){
    /* background-color: cornsilk; */
    margin-left: 17px;
  }
}

@media (max-width: 900px) {
  .likes-comments-container-publication {
    width: 200px;
  }
  .likes-comments-container-publication-pollpost {
    width: auto;
  }
  .comments-publication {
    width: 120px;
    height: 20px;
  }
  .comments-publication-fullpagepost-modal-and-page {
    width: 120px;
    height: 20px;
  }
  .comments-publication-pollpost {
    width: 135px;
    height: 20px;
  }
  .likes-counter-publication,
  .comments-counter-publication {
    font-size: 10px;
  }
  .likes-counter-publication-pollpost,
  .comments-counter-publication-pollpost {
    font-size: 14px;
  }
  .logo-comments-publication {
    width: 0px !important;
  }
  .logo-comments-publication svg {
    width: 16px !important;
  }
  .logo-comments-publication-pollpost svg {
    width: 20px !important;
  }
}
