.fourthstep-container {
  height: 80%;
  width: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.title-fourthstep {
  font-family: "britanica-heavy" !important;
  font-size: 30px;
  text-align: center;
}
.fourthstep-disclaimer{
  font-size: 14px;
  font-family: "britanica-regular";
  font-style: italic;
  padding-top: 15px;
}
.fourthstep-form-container {
  height: 70%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.fourthstep-container form {
  height: 100%;
  width: 100%;
  max-width: 1100px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: row;
}

.button-counterpart-fourthstep {
  width: 20%;
  max-width: 341px;
  max-height: 260px;
  height: 40%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
  border: none;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.1);
  background-image: #ffffff;
  opacity: 1 !important;
  background: none;
  border-radius: 10px;
  cursor: pointer;
}
.button-counterpart-fourthstep:active{
  transform: scale(0.75);
  transition-duration: 450ms;
}
.button-counterpart-fourthstep-selected {
  width: 20%;
  max-width: 341px;
  max-height: 260px;
  height: 40%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
  border: none;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.1);
  background-color: #f6d463 !important;
  opacity: 1 !important;
  background: none;
  border-radius: 10px;
  cursor: pointer;
}
.button-counterpart-fourthstep-selected:active{
  transform: scale(0.75);
  transition-duration: 250ms;
}
.fourthstep-container button:hover {
  background-color: #f6d46391 ;
}
.validation-button-container {
  /* height: 200px; */
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.validation-button {
  width: 196px !important;
  height: 67px !important;
  border-radius: 10px;
  border: none;
  background-color: #f6d463 !important;
  cursor: pointer;
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  font-family: "britanica-heavy";
}
.validation-button:hover{
  background-color: #f7c520 !important;
transition-duration:300ms !important;
}

.hide-textbutton-signup-athlete {
  display: none;
}

@media (max-width: 1012px) {
 .fourthstep-container form {
  height: 90%;
 } 
}