.dropdown-menu-container {
  z-index: 998 !important;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  font-size: 0.9rem;
  width: 100px;
  height: auto;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  position: absolute;
  /* top: 7%; */
  /* right: 5%; */
  text-align: center;
  background-color: white;
}
.dropdown-menu-container ul,
.dropdown-menu-container-fullpagepost-page ul {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: column;
}
.dropdown-menu-container li,
.dropdown-menu-container-fullpagepost-page li {
  user-select: none;
  outline: none;
  margin: 0;
  padding: 0;
  text-decoration: none;
  color: black;
  font-family: "britanica-heavy";
  height: 35px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  /* background-color: red; */
}

.dropdown-menu-container li:hover,
.dropdown-menu-container-fullpagepost-page li:hover {
  color: #f6d463;
  transition: 0.3s;
}
/* Conditionnal rendering for FullPagePostPage */
.dropdown-menu-container-fullpagepost-page {
  z-index: 998 !important;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  font-size: 0.9rem;
  width: 100px;
  height: auto;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  position: absolute;
  /* top: 20% !important;
  right: 15% ; */
  text-align: center;
  background-color: white;
}
.dropdown-menu-container-fullpagepost-page a, .dropdown-menu-container a {
  text-decoration: none;
  color: black;
}
.separation-line-dropdown-menu {
  width: 90%;
  border-bottom: 0.3px solid rgba(0, 0, 0, 0.2);
  opacity: 0.5;
}
@media (max-width: 1450px) {
.dropdown-menu-container{
  font-size: 0.7rem;
  width: 80px;
  border-radius: 10px;
}
.dropdown-menu-container li{
  height: 24px;
}
}