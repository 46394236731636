.athleteprofilepage-component{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    /* height: 100% !important; */
    /* background-color: blue; */
    margin-top:95px;
}
.athleteprofilepage-profilesubmenu-wrap{
    display: flex;
    width: 100%;
    max-width: 1240px;
}
.athleteprofilepage-formulatedoffers-wrap{
    max-width: 1246px;
    width: 100%;
}
@media (max-width: 1300px) {
    .athleteprofilepage-component{
        width: 94%;
    }
}