.launchpad-collection-live-page-container {
  /* background-color: yellowgreen; */
  width: 100%;
  max-width: 1243px;
  display: flex;
  padding-bottom: 100px;
  flex-direction: column;
  margin-top: 95px;
}
.launchpad-collection-live-page-left-container {
  width: 100%;
  height: 100%;
  max-width: 618px;
  display: flex;
  flex-direction: column;
  margin-bottom: 50px;
  /* background-color: aquamarine; */
}
.launchpad-collection-live-page-more-about-collection-container {
  width: 100%;
  display: flex;
  justify-content: flex-start;
}
.launchpad-collection-live-mintPopUpStatus-container {
  position: relative !important;
  background-color: white !important;
  width: 450px;
  height: 425px;
  border-radius: 20px;
  box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.1);
}

@media (max-width: 1280px) {
  .launchpad-collection-live-page-container {
    max-width: 96%;
  }
}
@media (max-width: 1180px) {
  .launchpad-collection-live-page-container {
    max-width: 96%;
    align-items: center;
  }
}
@media (max-width: 1080px) {
  .launchpad-collection-live-page-container {
    max-width: 96%;
    align-items: center;
  }
}
@media (max-width: 980px) {
  .launchpad-collection-live-page-container {
    max-width: 96%;
    align-items: center;
  }
}
@media (max-width: 880px) {
  .launchpad-collection-live-page-container {
    max-width: 96%;
    align-items: center;
  }
}
