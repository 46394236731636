.event-content-container {
  width: 100%;
  height: 220px;
  border-radius: 6.22px;
  display: flex;
  align-items: flex-end;
  margin-top: 10px;
  text-decoration: none;
  transition: box-shadow 0.25s ease-in-out 0s, transform 0.25s ease 0s;
}
.event-content-container:hover{
  box-shadow: rgba(0, 0, 0, 0.12) 0px 4px 16px;
  transform: translate(0px, -10px);
  transition-duration: 300ms;
}
.event-content-wrap {
  width: 100%;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.771118) 48.62%,
    #000000 100%
  );
  border-radius: 6.22px;
}
.event-content-subwrap {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  height: 40%;
  width: 100%;
  padding-bottom: 19.72px;
  padding-left: 11.82px;
  color: #ffffff;
  font-family: "Britanica-Regular";
  font-size: 9.952px;
  line-height: 14px;
}
.event-content-title {
  font-family: "Britanica-Heavy";
  font-size: 16px;
  line-height: 19px;
}
.event-content-text {
  display: flex;
  align-items: center;
}
