.useroffersreceived-component {
  display: flex;
  flex-direction: column;
}
.useroffersreceived-table-container {
  width: 100%;
  height: 48px;
  display: grid;
  align-items: center;
  grid-template-columns: 3.8fr 2.4fr 2.3fr 2.5fr 1.5fr 2fr 2.2fr 1.1fr 0.7fr;
  position: relative;
  font-family: "britanica-black";
  font-size: 14px;
  line-height: normal;
}
.useroffersreceived-table-container::before {
  content: "";
  position: absolute;
  top: 0;
  width: 100%;
  height: 1px;
  background-color: rgba(0, 0, 0, 0.5);
}
.useroffersreceived-table-container::after {
  content: "";
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 1px;
  background-color: rgba(0, 0, 0, 0.5);
}
.useroffersreceived-table-container-offer {
  display: flex;
  justify-content: flex-end;
}
.useroffersreceived-table-container-date {
  display: flex;
  justify-content: flex-end;
}
.useroffersreceived-content-container-wrap {
  display: grid;
  align-items: center;
  grid-template-columns: 3.8fr 2.4fr 2.3fr 2.5fr 1.5fr 2fr 2.2fr 1.1fr 0.7fr;
  font-family: "britanica-heavy";
  font-size: 14px;
  line-height: normal;
}
.useroffersreceived-content-container-wrap:nth-child(1n) {
  margin-top: 20px;
}
.useroffersreceived-content-container-nft-wrap {
  display: flex;
  align-items: center;
}
.useroffersreceived-content-container-nft-wrap > img {
  width: 50px;
  height: 50px;
  border-radius: 5px;
}
.useroffersreceived-content-container-nft-wrap-info-wrap {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 9px;
  height: 40px;
}
.useroffersreceived-content-container-offer-wrap {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
  height: 40px;
}
.useroffersreceived-content-container-offer-wrap > span:nth-child(2) {
  font-family: "britanica-regular";
}
.useroffersreceived-content-container-date {
  display: flex;
  justify-content: flex-end;
}
.useroffersreceived-content-container-bid {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.useroffersreceived-content-container-bid > img {
  width: 20px;
  height: 20px;
}
@media (max-width: 1250px) {
    .useroffersreceived-table-container {
        grid-template-columns: 3.8fr 2.4fr 2.3fr 2.5fr 1.5fr 2fr 2.2fr 1.1fr 0.75fr;
      }
      .useroffersreceived-content-container-wrap {
        grid-template-columns: 3.8fr 2.4fr 2.3fr 2.5fr 1.5fr 2fr 2.2fr 1.1fr 0.75fr;
      }
}
@media (max-width: 1150px) {
  .useroffersreceived-table-container {
    font-size: 12.6px; /* 0.9 */
    height: 43.2px; /* 0.9 */
    grid-template-columns: 3.8fr 2.4fr 2.3fr 2.5fr 1.5fr 2fr 2.2fr 1.1fr 0.8fr;
  }
  .useroffersreceived-content-container-wrap {
    font-size: 12.6px; /* 0.9 */
    grid-template-columns: 3.8fr 2.4fr 2.3fr 2.5fr 1.5fr 2fr 2.2fr 1.1fr 0.8fr;
  }
  .useroffersreceived-content-container-wrap:nth-child(1n) {
    margin-top: 18px; /* 0.9 */
  }
  .useroffersreceived-content-container-nft-wrap > img {
    width: 45px; /* 0.9 */
    height: 45px; /* 0.9 */
    border-radius: 4.5px; /* 0.9 */
  }
  .useroffersreceived-content-container-nft-wrap-info-wrap {
    margin-left: 8.1px; /* 0.9 */
    height: 36px; /* 0.9 */
  }
  .useroffersreceived-content-container-offer-wrap {
    height: 36px; /* 0.9 */
  }
  .useroffersreceived-content-container-bid > img {
    width: 18px; /* 0.9 */
    height: 18px; /* 0.9 */
  }
}
@media (max-width: 1080px) {
  .useroffersreceived-table-container {
    height: 40.8px; /* 0.85 */
    grid-template-columns: 3.8fr 2.4fr 2.3fr 2.5fr 1.5fr 2fr 2.2fr 1.1fr 0.83fr;
  }
  .useroffersreceived-content-container-wrap {
    grid-template-columns: 3.8fr 2.4fr 2.3fr 2.5fr 1.5fr 2fr 2.2fr 1.1fr 0.83fr;
  }
  .useroffersreceived-content-container-wrap:nth-child(1n) {
    margin-top: 17px; /* 0.85 */
  }
  .useroffersreceived-content-container-nft-wrap > img {
    width: 42.5px; /* 0.85 */
    height: 42.5px; /* 0.85 */
    border-radius: 4.25px; /* 0.85 */
  }
  .useroffersreceived-content-container-nft-wrap-info-wrap {
    margin-left: 7.65px; /* 0.85 */
    height: 34px; /* 0.85 */
  }
  .useroffersreceived-content-container-offer-wrap {
    height: 34px; /* 0.85 */
  }
  .useroffersreceived-content-container-bid > img {
    width: 17px; /* 0.85 */
    height: 17px; /* 0.85 */
  }
}
@media (max-width: 870px) {
  .useroffersreceived-table-container {
    height: 38.4px; /* 0.8 */
    grid-template-columns: 3.8fr 2.4fr 2.3fr 2.5fr 1.5fr 2fr 2.2fr 1.1fr 0.88fr;
  }
  .useroffersreceived-content-container-wrap {
    grid-template-columns: 3.8fr 2.4fr 2.3fr 2.5fr 1.5fr 2fr 2.2fr 1.1fr 0.88fr;
  }
  .useroffersreceived-content-container-wrap:nth-child(1n) {
    margin-top: 16px; /* 0.8 */
  }
  .useroffersreceived-content-container-nft-wrap > img {
    width: 40px; /* 0.8 */
    height: 40px; /* 0.8 */
    border-radius: 4px; /* 0.8 */
  }
  .useroffersreceived-content-container-nft-wrap-info-wrap {
    margin-left: 7.2px; /* 0.8 */
    height: 32px; /* 0.8 */
  }
  .useroffersreceived-content-container-offer-wrap {
    height: 32px; /* 0.8 */
  }
  .useroffersreceived-content-container-bid > img {
    width: 16px; /* 0.8 */
    height: 16px; /* 0.8 */
  }
}
