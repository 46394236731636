.popupbuynft-component {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 528px;
  height: 373px;
  /* width: 100% !important; */
  background: #ffffff;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.08);
  border-radius: 13.5142px;
}
.popubuynft-title-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 89%;
  padding-top: 25px;
}
.popubuynft-title-container > span {
  font-family: "Britanica-Black";
  font-size: 30px;
  line-height: 36px;
  letter-spacing: -0.015em;
}
.popubuynft-title-container > img {
  width: 25px !important;
  height: 25px !important;
  cursor: pointer;
  border-radius: 10px;
}
.popubuynft-nftinfo-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 89%;
  margin-top: 40px;
  margin-bottom: 16px;
  height: 91px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}
.popubuynft-nftinfo-container-left {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 325px;
}
.popubuynft-nftinfo-container-left-info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 52px;
  padding-left: 12px;
}
.popubuynft-nftinfo-container-left-info > span {
  font-family: "Britanica-Light";
  line-height: 19px;
  letter-spacing: -0.015em;
}
.popubuynft-nftinfo-container-left-info > span:nth-child(2n + 1) {
  font-family: "Britanica-Black";
  font-size: 16px;
  line-height: 19px;
  letter-spacing: -0.015em;
}
.popubuynft-nftinfo-container-right {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  height: 49px;
}
.popubuynft-nftinfo-container-right > span {
  font-family: "Britanica-Light";
  font-size: 17px;
  line-height: 20px;
  letter-spacing: -0.015em;
}
.popubuynft-nftinfo-container-right > span:nth-child(2n + 1) {
  font-family: "Britanica-Black";
  font-size: 19px;
  line-height: 23px;
  letter-spacing: -0.015em;
}
.popupbuynft-fees-container {
  display: flex;
  flex-direction: column;
  width: 89%;
}
.popupbuynft-fees-container-servicefee-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.popupbuynft-fees-container-servicefee-wrap > span {
  font-family: "Britanica-Black";
  font-size: 19px;
  line-height: 23px;
  letter-spacing: -0.015em;
}
.popupbuynft-fees-container-servicefee-wrap > span > div {
  display: flex;
}
.popupbuynft-fees-container-servicefee-wrap > div > span:nth-child(2n + 1) {
  font-family: "Britanica-Light";
  line-height: 19px;
  letter-spacing: -0.015em;
  padding-right: 8px;
}
.popupbuynft-fees-container-servicefee-wrap > div > span:nth-child(n + 2) {
  font-family: "Britanica-Black";
  font-size: 19px;
  line-height: 23px;
  letter-spacing: -0.015em;
}
@font-face {
    font-family: "Lufga";
    src: url("../../Assets/Fonts/Lufga-Black.otf");
}
