.athleteTemplate-container {
  width: 110px;
  height: 150px;
  position: relative;
  display: flex;
  justify-content: center;
  /* background: center linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%) */
  /* ,center url("../fakeData/lebron.svg") */
  /* ; */
  filter: drop-shadow(0px 0px 24px rgba(0, 0, 0, 0.08));
  border-radius: 10px;
}
.athleteTemplate-container-fan {
  border: solid 2px #F6D463;
}
.athleteTemplate-star{
  width: 23px;
  position: absolute;
  left: 2px;
  top: 2px;
}
.athleteTemplate-container > span {
  position: absolute;
  bottom: 15px;
  font-family: "Britanica-Heavy";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: #ffffff;
/* background-size: contain; */
}
