.popuplistnft-component {
  background-color: white;
  width: 528px;
  height: 484px;
  border-radius: 13.514px;
  box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.08);
  display: flex;
  justify-content: center;
  /* align-items: center; */
}
.popuplistnft-container {
  width: 468px;
  height: 431px;
  margin-top: 23px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.popuplistnft-container > span {
  font-family: "britanica-black";
  font-size: 30px;
  line-height: normal;
  letter-spacing: -0.45px;
}
.popuplistnft-container-nft-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 40px;
}
.popuplistnft-container-nft-wrap > img {
  width: 70px;
  height: 70px;
  border-radius: 10px;
  cursor: pointer;
}
.popuplistnft-container-nft-wrap > div {
  display: flex;
  flex-direction: column;
  width: 384px;
}
.popuplistnft-container-nft-wrap-info {
  display: flex;
  justify-content: space-between;
}
.popuplistnft-container-nft-wrap-info > span {
  font-family: "britanica-light";
  font-size: 17px;
  line-height: normal;
  letter-spacing: -0.255px;
}
.popuplistnft-container-nft-wrap-info-bold {
  font-family: "britanica-black" !important;
  font-size: 19px !important;
  letter-spacing: -0.285px !important;
}
.popuplistnft-container-line {
  border-top: solid 1px black;
  width: 100%;
  height: 1px;
  margin-top: 20px;
}
.popuplistnft-container-input-wrap {
  margin-top: 20px;
  display: flex;
  /* background-color: red; */
}
.popuplistnft-container-input-wrap > input {
  width: 348px;
  height: 48px;
  border-radius: 10px 0 0 10px;
  border: solid 1px black;
  padding-left: 16px;
  color: #000;
  font-family: "Montserrat";
  font-size: 16px;
  line-height: normal;
  outline: none;
}
.popuplistnft-container-input-wrap > div {
  width: 103px;
  height: 50px;
  border-radius: 0 10px 10px 0;
  border: solid 1px black;
  border-left: 0px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.popuplistnft-container-input-wrap > div > span:nth-child(1) {
  margin-right: 17.64px;
}
.popuplistnft-container-input-wrap > div > span:nth-child(2) {
  width: 11px;
  height: auto;
}
.popuplistnft-container-fee-wrap {
  width: 100%;
  height: 23px;
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}
.popuplistnft-container-fee-wrap > span {
  font-family: "britanica-black";
  font-size: 19px;
  line-height: normal;
  letter-spacing: -0.285px;
}
.popuplistnft-container-fee-wrap-subwrap > span:nth-child(1) {
  line-height: normal;
  letter-spacing: -0.24px;
  margin-right: 8px;
}
.popuplistnft-container-fee-wrap-subwrap > span:nth-child(2) {
  font-family: "britanica-black";
  font-size: 19px;
  line-height: normal;
  letter-spacing: -0.285px;
}
.popuplistnft-container-receive-wrap {
  width: 100%;
  height: 23px;
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}
.popuplistnft-container-receive-wrap > span {
  font-family: "britanica-black";
  font-size: 19px;
  line-height: normal;
  letter-spacing: -0.285px;
}
.popuplistnft-container-receive-wrap-subwrap > span:nth-child(1) {
  line-height: normal;
  letter-spacing: -0.24px;
  margin-right: 8px;
}
.popuplistnft-container-receive-wrap-subwrap > span:nth-child(2) {
  font-family: "britanica-black";
  font-size: 19px;
  line-height: normal;
  letter-spacing: -0.285px;
}
.popuplistnft-mintpopupprocessing-wrap {
  background-color: white;
  position: relative;
  width: 450px;
  height: 425px;
  border-radius: 20px;
  box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.1);
}
