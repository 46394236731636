#sortbyselector-component {
  width: 128px;
  height: 40px;
  border: 0.3px solid #9b9b9b;
  border-radius: 10px 10px 0px 0px;
  position: relative;
  margin-top: 23px;
}
#sortbyselector-component:hover {
  cursor: pointer;
}

#sortbyselector-component > span {
  position: absolute;
  top: 12px;
  left: 10px;
  font-family: "Britanica-Regular";
  z-index: -1;
}
#sortbyselector-component:hover span{
  font-family: "Britanica-Heavy";
}
#sortbyselector-component > img {
  position: absolute;
  top: 17px;
  right: 15.64px;
  width: 12.73px;
  z-index: -1;
}
.sortbyselector-menu {
  position: absolute;
  bottom: -82px;
  right: -1px;
  border: 0.3px solid #9b9b9b;
  border-radius: 0px 0px 10px 10px;
  width: 128px;
  height: 80px;
  background-color: white;
}
.sortbyselector-menu > ul {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: 100%;
}
.sortbyselector-menu > ul > li {
  padding-left: 10px;
  font-family: "Britanica-Regular" !important;
}
.sortbyselector-menu > ul > li:hover {
  font-family: "Britanica-Heavy" !important;
  cursor: pointer;
}
