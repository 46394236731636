/* CGU Page Styles */
.cgu-page-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  /* justify-content: flex-start; */
  margin-top: 95px;
  width: 90vw;
}
.cgu-page-container iframe {
  width: 1200px;
  height: 90vh;
  display: flex;
  justify-content: center !important;
  /* background-color: red; */
  max-width: 100vw !important;
  border: none !important;
}

.cgu-title {
  font-size: 36px;
  font-weight: bold;
  margin-bottom: 30px;
  width: 100%;
  text-align: center;
}

.cgu-section-title {
  font-size: 24px;
  font-weight: bold;
  margin-top: 30px;
  margin-bottom: 20px;
}

.cgu-subsection-title {
  font-size: 20px;
  font-weight: bold;
  margin-top: 20px;
  margin-bottom: 10px;
}

.cgu-paragraph {
  text-align: justify;
  line-height: 1.6;
  margin-bottom: 20px;
}

.cgu-section {
  margin-bottom: 30px;
}
