.App {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.mail-contact-sofan{
  color: black;
  text-decoration:none ;
}
.mail-contact-sofan:visited{
  color: black;
}
.app-sofan-block-access-container {
  /* background-color: red; */
  background-color: rgba(141, 107, 42, 0.363);
  z-index: 455555554654654654654;
  width: 100vw;
  height: 100vh;
  position: absolute;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
}
.app-sofan-block-access-wrap {
  width: 100%;
  height: 100%;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  /* border-radius: 10px; */
}
.app-sofan-block-access-subwrap {
  width: 95%;
  height: 95%;
  /* background-color: yellow; */
}
.app-sofan-block-access-sofan-logo-container {
  width: 100%;
  display: flex;
  justify-content: center;
  height: 30px;
  margin-bottom: 20px;
  user-select: none;
}
.app-sofan-block-access-sofan-logo-container a {
  display: flex;
  justify-content: center;
  cursor: pointer;
}
.app-sofan-block-access-title {
  font-family: "britanica-heavy";
  font-size: 24px;
  /* background-color: red; */
  text-align: center;
  padding: 10px;
}
.app-sofan-block-access-img-and-button-container {
  width: 100%;
  height: 85%;
  display: flex;
  justify-content: center;
  /* background-color: aquamarine; */
}
.app-sofan-block-access-img-container {
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* background-color: khaki; */
}
.app-sofan-block-acces-animation-container {
  /* background-color: blue; */
  width: 100%;
}
.transitionsItem {
  overflow: hidden;
  width: 100%;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 4em;
  font-weight: 800;
  text-transform: uppercase;
  will-change: transform, opacity, height;
  white-space: nowrap;
  cursor: pointer;
  line-height: 80px;
  user-select: none;
}
/* .app-sofan-block-access-img-and-button-container > img {
  width: 60%;
  height: 60%;
} */
.app-sofan-block-access-login-signup-container {
  display: flex;
  align-items: center;
  justify-content: center;
  /* background-color: cadetblue; */
  width: 50%;
  height: 100%;
}
.app-sofan-block-access-login-container {
  position: relative;
  box-shadow: 0px 0px 20.3424px rgba(0, 0, 0, 0.08);
  border-radius: 10px;
}
.app-sofan-block-access-mentions-legales a{
text-decoration: none;
color: black;
}
.app-sofan-block-access-mentions-legales a:hover{
color: #f6d463;
transition-duration: 200ms;
}
/* button:active {
  transform: scale(0.45) !important;
} */
.error-mobile-waiting-page {
  display: none;
}
.waiting-page-container {
  height: 100%;
  width: 100%;
  /* background-color: red; */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.logo-sofan-waiting-page-container {
  height: 20%;
  width: 100%;
  /* background-color: green; */
  display: flex;
  justify-content: center;
  align-items: center;
}
.explore-picture-and-text-waiting-page {
  height: 60%;
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
  /* background-color: blue; */
  font-family: "britanica-heavy";
  font-size: 30px;
}
.explore-picture-waiting-page {
  height: 250px;
  width: 500px;
}

.socials-network-waiting-page {
  height: 10%;
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
  /* background-color: red; */
  position: absolute;
  bottom: 0%;
}
.logo-socials-waiting-page {
  height: 20%;
  width: 20%;
  /* background-color: red; */
  display: flex;
  justify-content: space-evenly;
}
.svg-socials-waiting-page {
  height: 30px;
  width: 30px;
  fill: #f6d463;
  cursor: pointer;
}
.svg-socials-waiting-page:hover {
  fill: #f7c520;
}
@media (max-height: 980px) {
  .app-sofan-block-access-sofan-logo-container {
    width: 70%;
  }
  .app-sofan-block-access-title {
    width: 70%;
  }
  .socials-network-waiting-page {
    width: 70%;
    left: 0%;
    bottom: 2%;
  }
}
@media (max-height: 1000px) {
  .app-sofan-block-access-img-container {
    width: 70%;
  }
  .app-sofan-block-access-login-signup-container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    /* background-color: cadetblue; */
    width: 30%;
    height: 100%;
  }
}

@media (max-width: 1050px) {
  .app-sofan-block-access-login-container div {
    max-width: 380px;
  }
  .app-sofan-block-access-login-container form {
    max-width: 350px;
  }
}
@media (max-width: 800px) {
  .app-sofan-block-access-container {
    display: none;
  }
  .error-mobile-waiting-page {
    height: 100vh !important;
    width: 100vw !important;
    display: flex !important;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
    text-align: center;
    line-height: 40px !important;
    font-family: "britanica-heavy";
  }
  .waiting-page-container {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .logo-sofan-waiting-page-container {
    height: 20%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .explore-picture-and-text-waiting-page {
    height: 60%;
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
    font-family: "britanica-heavy";
    font-size: 30px;
  }
  .explore-picture-waiting-page {
    height: 250px;
    width: 500px;
  }

  .socials-network-waiting-page {
    height: 15%;
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
  }
  .logo-socials-waiting-page {
    height: 20%;
    width: 60vw !important;
    display: flex;
    justify-content: space-evenly;
  }
  .svg-socials-waiting-page {
    height: 30px;
    width: 30px;
    fill: #f6d463;
    cursor: pointer;
  }
  .svg-socials-waiting-page:hover {
    fill: #f7c520;
  }

  .App {
    display: none !important;
  }
  .App {
    display: none !important;
  }
}
@media (max-width: 670px) {
  .logo-socials-waiting-page {
    width: 60vw !important;
  }
}
@media (max-height: 700px) {
  .app-sofan-block-access-container {
    display: none;
  }
  .error-mobile-waiting-page {
    height: 100vh !important;
    width: 100vw !important;
    display: flex !important;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
    text-align: center;
    line-height: 40px !important;
    font-family: "britanica-heavy";
  }
  .waiting-page-container {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .logo-sofan-waiting-page-container {
    height: 20%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .explore-picture-and-text-waiting-page {
    height: 60%;
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
    font-family: "britanica-heavy";
    font-size: 30px;
  }
  .explore-picture-waiting-page {
    height: 250px;
    width: 500px;
  }

  .socials-network-waiting-page {
    height: 15%;
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
  }
  .logo-socials-waiting-page {
    height: 20%;
    width: 60vw !important;
    display: flex;
    justify-content: space-evenly;
  }
  .svg-socials-waiting-page {
    height: 30px;
    width: 30px;
    fill: #f6d463;
    cursor: pointer;
  }
  .svg-socials-waiting-page:hover {
    fill: #f7c520;
  }

  .App {
    display: none !important;
  }
}
@media ((max-width: 1150px) and (max-height:850px)) {
  .socials-network-waiting-page {
    width: 50%;
  }
  .app-sofan-block-access-img-container{
    width: 75%;
  }
  .logo-socials-waiting-page{
    width: 50%;
  }
}
@media ((max-width: 1150px) and (max-height:700px)) {
  .socials-network-waiting-page {
    width: 100%;
  }
  .app-sofan-block-access-img-container{
    width: 75%;
  }
  .logo-socials-waiting-page{
    width: 20%;
  }
}
@media ((max-width: 800px) and (max-height:850px)) {
  .socials-network-waiting-page {
    width: 100%;
  }
  .app-sofan-block-access-img-container{
    width: 75%;
  }
  .logo-socials-waiting-page{
    width: 20%;
  }
}
