.button-like-toggle-color {
  outline: none;
  background: none;
  border: none;
  cursor: pointer;
}
.logo-likes {
  width: 30px;
}
.logo-likes-fullpagepost-modal-and-page {
  width: 30px;
}

@media (max-width: 1050px) {
  .logo-likes svg {
    /* background-color: red; */
    width: 18px !important;
  }
  .logo-likes-no-media-queries svg {
    /* background-color: red; */
    width: 22px;
  }
  .logo-likes-fullpagepost-modal-and-page svg {
    width: 15px;
  }
  .logo-likes-pollpost svg {
    width: 22px !important;
  }
  .logo-likes {
    width: 35px;
  }
  .logo-likes-fullpagepost-modal-and-page {
    width: 10px;
  }
}
