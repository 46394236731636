.creation-post-component {
  background-color: rgba(155, 155, 155, 0.479);
  /* background-color: rgba(155, 155, 155, 0.1); */
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.creation-post-container {
  width: 500px;
  /* height: 566px; */
  background-color: white;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.creation-post-wrap {
  width: 461px;
  /* height: 524px; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* background-color: blue; */
}
.creation-post-wrap > button {
  height: 56px;
  background: #f6d463;
  border-radius: 10px;
  border: none;
  color: #000000;
  font-family: "Britanica-Heavy";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  cursor: pointer;
}
.creation-post-wrap > button:active {
  transform: scale(0.8);
}
.creation-post-wrap > button:hover {
  background-color: #f7c520;
  transition: 0.3s;
}
.creation-post-wrap > button:active {
  background-color: #fff2c7;
}
.creation-text-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.creation-text-wrap > span {
  font-family: "Britanica-Heavy";
  font-style: normal;
  font-weight: 400;
  font-size: 25px;
  line-height: 30px;
  margin-left: 60px;
}
.creation-text-wrap > img {
  width: 25px;
  height: 25px;
}
.creation-text-wrap-previous-arrow-container {
  position: absolute;
  top: 20px;
  cursor: pointer;
  padding: 8px;
  border-radius: 50%;
}
.creation-text-wrap-previous-arrow-container:hover {
  background-color: #00000015;
}
.creation-text-wrap-previous-arrow-container img {
  border-radius: 50%;
}
.creation-text-wrap-previous-arrow-container img:active {
  transform: scale(0.85);
  transition-duration: 300ms;
}
.cancel-button-creation-post {
  border: none;
  background: none;
  margin: 0;
  padding: 0;
  cursor: pointer;
}
.creation-visibility-choice-container {
  width: 460px;
  height: 203px;
  display: flex;
  justify-content: space-between;
}

.creation-visibility-choice-fans-only {
  border: 0.3px solid #000000;
  width: 225px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Britanica-Heavy";
  font-size: 20px;
  border-radius: 10px;
  cursor: pointer;
}
.creation-visibility-choice-everyone {
  border: 0.3px solid #000000;
  width: 225px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Britanica-Heavy";
  font-size: 20px;
  border-radius: 10px;
  cursor: pointer;
}
.creation-visibility-choice-fans-only:hover,
.creation-visibility-choice-everyone:hover {
  background-color: #00000015;
  transition-duration: 300ms !important;
}
.creation-visibility-choice-fans-only:active,
.creation-visibility-choice-everyone:active {
  transform: scale(0.8);
}
.creation-visibility-choice-container > div:hover {
  background-color: #f6d463;
}
.visibility-on {
  background-color: #f6d463 !important;
}
.visibility-off {
  background-color: white !important;
}
.creation-post-validation-message {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 22px;
  font-family: "britanica-heavy";
  /* background-color: red; */
}
.creation-post-validation-message img {
  width: 40px !important;
  height: 40px !important;
  margin-bottom: 20px;
}
