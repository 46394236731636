.input-comment-container-publication-no-media-queries {
  min-height: 80px;
  /* background-color: red; */
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  /* background-color: bisque; */
}
.input-comment-elements-no-media-queries {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  /* flex-direction: column; */
  background: rgba(60, 64, 69, 0.04);
  border-radius: 10px;
  min-height: 50px;
  /* max-height: 50px; */
  width: 100%;
  height: auto;
  /* background-color: aqua; */
}

.input-comment-publication-no-media-queries {
  width: 70%;
  /* max-height: 70%; */
  /* height: auto !important; */
  /* max-height: 150px !important; */
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  background: none;
  height: 100%;
  font-family: "britanica-regular";
  font-size: 16px;
  resize: none;
  padding-top: 20px ;
  /* background-color: rgba(255, 0, 0, 0.223); */
}

.input-comment-publication-no-media-queries:focus {
  border: none;
  outline: none;
}
.input-comment-publication-no-media-queries::placeholder {
  font-family: "britanica-regular";
  color: #9b9b9b;
  font-size: 16px;
}

.publish-comments-button-container-publication-no-media-queries button {
  border: none;
  background: none;
  font-family: "britanica-black";
  cursor: pointer;
  font-size: 16px;
}
