.launchpad-collection-live-utilities-container{
    width: 100%;
    max-height: 691px;
    max-width: 612px;
    margin-top: 50px;
    /* background-color: red; */
}
.launchpad-collection-live-utilities-title{
    width: 100%;
    height: 36px;
    font-size: 30px;
    font-family: "britanica-heavy";
    margin-bottom: 20px;
}