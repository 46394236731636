 body .signup-athlete-page {
    height: 100% !important;
    width: 100vw !important;
    /* background-color: aqua;  */
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-family: 'britanica-regular' !important;
    flex-direction: column;
    margin-top: 95px;
  }
.signup-athlete-elements
{
  height: 88%;
  width: 100%;
  /* background-color: green; */
  display: flex !important;
  justify-content: flex-start !important;
  align-items: center !important;
  flex-direction: column !important;
}

.steps-signup{
  height: 60px;
  font-family: "britanica-heavy";
  width: 100%;
  text-align: center;
  opacity: 0.5;
  position: absolute;
  top: 15%;
}
.button-and-progressbar-container{
  height: 190px;
  width: 100%;
  position: absolute;
  /* background-color: red; */
}

.button-container {
  height: 130px;
  width: 100%;
  /* background-color: red; */
}

.progress-bar-container{
  width: 100%;
  height: 60px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* padding: 40px; */
  /* background-color: red;  */
}
.progress-bar-total{
  width: 1095px;
  height: 25px;
  background-color: #fff2C7 ;
  border-radius: 10px ;
}
.progress-bar-current{
  height: 25px;
  /* width: 50%; */
  /* width: 10%; */
  display: flex;
  justify-content: flex-start;
  background-color: #f6d463;
  border-radius:10px ;
  /* transition: 500ms; */
}

.progress-bar {
  background-color: #FFF2C7;
  height: 25px;
  /* z-index: 1; */
}
.signup-athlete-button {
  width: 196px;
  height: 67px;
  border-radius: 10px;
  border: none;
  background-color: #f6d463;
  cursor: pointer;
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  font-family: "britanica-heavy";
}

.signup-athlete-button:hover {
  background-color: #f7c520;
  transition: 500ms;
}
.signup-athlete-button:active{
  transform: scale(0.85);
  transition-duration: 200ms;
}


/* .bg-progress-bar:hover {
  background-color: red;
  cursor: pointer;
} */
@media (max-width:1131px) {
  .progress-bar-total{
    width: 800px;
  }
}
@media (max-width:850px) {
  .progress-bar-total{
    width: 600px;
    /* background-color: red; */
  }
}
@media (min-width:1900px) {
  .steps-signup{
    font-size: 1.6rem !important;
  }
  .signup-athlete-button{
    font-size: 1.45rem;
  }
}