.launchpadallupcominglaunches-component {
  display: flex;
  flex-direction: column;
  max-width: 100%;
}
.launchpadallupcominglaunches-top-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* width: 1246px; */
}
.launchpadallupcominglaunches-top-wrap > span {
  font-family: "Britanica-Heavy";
  font-size: 35px;
  line-height: 42px;
  text-align: center;
  height: 42px;
}
.launchpadallupcominglaunches-top-wrap-dropdown {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 207px;
  height: 37px;
  border: 0.3px solid #000000;
  border-radius: 10px;
  cursor: pointer;
  position: relative;
  user-select: none;
}
.launchpadallupcominglaunches-top-wrap-dropdown-clicked {
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}
.launchpadallupcominglaunches-top-wrap-dropdown > span {
  padding-left: 10px;
  font-family: "Britanica-Regular";
  line-height: 19px;
}
.launchpadallupcominglaunches-top-wrap-dropdown > img {
  padding-right: 10px;
}
.launchpadallupcominglaunches-top-wrap-dropdown-choice-wrap {
  position: absolute;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  background-color: white;
  border: 0.3px solid #000000;
  width: 197px;
  height: 90px;
  z-index: 999;
  top: 37px;
  left: -1px;
  padding-left: 10px;
  font-family: "Britanica-Regular";
  line-height: 19px;
}
.launchpadallupcominglaunches-top-wrap-dropdown-choice-wrap
  > ul
  > li:nth-child(1) {
  padding-top: 5px;
}
.launchpadallupcominglaunches-bottom-wrap {
  display: flex;
  width: 100%;
  max-width: 1245px;
  overflow-x: scroll;
  overflow-y: hidden;
  height: 100%;
  max-height: 550px;
  padding-top: 20px;
  /* margin-top: 20px; */
}
.launchpadallupcominglaunches-bottom-wrap::-webkit-scrollbar {
  display: none;
}
.launchpadallupcominglaunches-bottom-wrap > div {
  display: flex;
  scroll-behavior: smooth !important;
}
.launchpadallupcominglaunches-bottom-wrap > div > div, .launchpadallupcominglaunches-bottom-wrap > div > div img {
width: calc(520px - (1337px - var(--dim, 0px)) * 0.38);
  max-width: 500px;
  max-height: calc(550px * (1 - (1337px - var(--dim, 0px)) / 1300));
}