.nft-collection-properties-container {
  width: 100%;
  min-height: 375px;
  height: 375px;
  max-width: 620px;
  /* max-height: 380px; */
  /* background-color: red; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  /* overflow-y: scroll; */
  /* display: none; */
}
.nft-collection-properties-card-container {
  width: 300px;
  height: 100px;
  margin-top: 20px;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.08);
  border-radius: 13.5142px;
  /* background-color: aqua; */
}
.nft-collection-properties-card-type-property {
  width: 300px;
  height: 17px;
  padding-top: 17px;
  text-align: center;
  font-size: 14px;
  font-family: "britanica-heavy";
  color: #f6d463;
  /* background-color: yellow; */
}
.nft-collection-properties-card-property {
  width: 300px;
  height: 24px;
  padding-top: 10px;
  font-size: 20px;
  text-align: center;
  font-family: "britanica-heavy";
  /* background-color: blue; */
}
.nft-collection-properties-card-rarity {
  width: 300px;
  height: 17px;
  padding: 1px;
  font-size: 14px;
  text-align: center;
  opacity: 0.5;
  /* background-color: rgb(100, 227, 54); */
}
