.forgot-password-popup-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 500px;
  height: 404px;
  background-color: white;
  border-radius: 20px;
  position: relative;
}
.forgot-password-previous-step-button {
  position: absolute;
  left: 15px;
  top: 15px;
  cursor: pointer;
  user-select: none;
  padding: 5px;
}
.forgot-password-previous-step-button:hover {
  background-color: #00000015;
  transition-duration: 300ms !important;
  border-radius: 30px;
  padding: 5px;
}
.forgot-password-previous-step-button:active {
  transform: scale(0.65);
  transition: 300ms;
}
.forgot-password-popup-wrap {
  width: 460px;
  height: 604px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.forgot-password-popup-title {
  width: 100%;
  display: flex;
  justify-content: center;
  font-size: 30px;
  font-family: "britanica-heavy";
  margin-top: 50px;
  /* background-color: darkblue; */
  animation: slide-in 0.5s cubic-bezier(0.175, 0.885, 0.320, 1.275);
}

.forgot-password-popup-description-and-input-container {
  height: 60%;
  /* background-color: aqua; */
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
}
.forgot-password-popup-description {
  width: 80%;
  font-size: 17px;
  font-weight: 600;
  font-style: italic;
  margin-top: 40px;
  margin-bottom: 40px;
  animation: slide-in 0.5s cubic-bezier(0.175, 0.885, 0.320, 1.275);
  
  /* background-color: violet; */
}
.forgot-passord-popup-mail-input {
  width: 100%;
  height: 35px;
  width: 97.5%;
  margin: 0;
  padding: 0;
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.281);
  /* opacity: 0.5; */
  caret-color: #f6d463;
  margin-top: 10px;
  padding-left: 10px;
  animation: slide-in 0.5s cubic-bezier(0.175, 0.885, 0.320, 1.275);
}
.forgot-password-popup-validation-button {
  outline: none;
  background: none;
  margin: none;
  border: none;
  width: 100%;
  height: 56px;
  font-family: "britanica-heavy";
  font-size: 20px;
  background: var(--yellow, #f6d463);
  border-radius: 10px;
  cursor: pointer;
  animation: slide-in 0.5s cubic-bezier(0.175, 0.885, 0.320, 1.275);
}
.forgot-password-popup-validation-button:disabled:hover {
  cursor: default !important;
  background-color: #f6d463 !important;
}
.forgot-password-popup-validation-button:disabled:active {
  transform: none !important;
}
.forgot-password-popup-validation-button:hover {
  background-color: #f7c520 !important;
  transition-duration: 300ms !important;
}
.forgot-password-popup-validation-button:active {
  transform: scale(0.85);
  transition-duration: 300ms;
}
/* ===== Validation Message when mail sent ===== */
.forgot-password-popup-validation-message-container {
  width: 100%;
  height: 300px;
  /* background-color: skyblue; */
  margin-top: 23px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.forgot-password-popup-validation-message-title {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 20px;
  font-family: "britanica-black";
  /* margin-top: 20px; */
  /* background-color: bisque; */
}
.forgot-password-popup-validation-message-title p {
  margin-top: 20px;
}
.forgot-password-popup-validation-message-email-address{
  font-style: italic;
  font-size: 18px;
  color: #f6d463;
}
.forgot-password-popup-validation-message-title img {
  width: 50px;
  height: 50px;
}
.forgot-password-popup-validation-message-title span {
  text-decoration: underline;
  cursor: pointer;
  color: #f6d463;
}
.forgot-password-popup-validation-message-title span:hover {
  text-decoration: underline;
  cursor: pointer;
  color: #f7c520 !important;
}
.forgot-password-popup-validation-message-resend {
  font-size: 16px;
  margin-top: 40px;
  font-style: italic;
}
.forgot-password-popup-validation-message-resend-confirmation {
  font-style: italic;
  color: #85d87e;
  font-size: 16px;
  position: absolute;
  bottom: 0px;
}
/* ANIMATIONS */
.forgot-password-popup-validation-message-loading {
  position: absolute;
  top: 25%;
}
.lds-ripple {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ripple div {
  position: absolute;
  border: 4px solid #f6d463;
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}
@keyframes lds-ripple {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 0;
  }
  4.9% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 0;
  }
  5% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
}
.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ellipsis div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #f6d463;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}

@keyframes slide-in {
  0% {  
    transform: translateX(-50px);
  }
  100% { 
    transform: translateX(0);
  }
}