.dropdown-menu-button-container {
  outline: none;
  background: none;
  border: none;
  margin: 0;
  /* padding-right: 4px; */
  /* padding-left: 4px; */
}
.dropdown-menu-button-container:hover {
  background-color: #00000015;
  transition-duration: 300ms !important;
  border-radius: 50%;
}
.dropdown-button-publication {
  height: 40px;
  width: 35px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  cursor: pointer;
}

.dropdown-button-point {
  border-radius: 20px;
  background-color: black !important;
}
.dropdown-button-point-size-S {
  width: 3px;
  height: 3px;
}
.dropdown-button-point-size-M {
  width: 5px;
  height: 5px;
}
.dropdown-button-point-size-XL {
  width: 10px;
  height: 10px;
}
.dropdown-menu-button-container-fullpagepost {
  outline: none;
  background: none;
  border: none;
  margin: 0;
}
.dropdown-menu-button-container-fullpagepost > div {
  height: 40px;
  width: 35px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  cursor: pointer;
}
.dropdown-menu-button-container-fullpagepost:hover {
  background-color: #00000015;
  transition-duration: 300ms !important;
  border-radius: 50%;
}
@media (max-width: 1450px) {
  .dropdown-menu-button-container-fullpagepost > div {
    width: 30px;
    height: 35px;
    /* background-color: red; */
  }
  .dropdown-menu-button-container-fullpagepost > div > div {
    width: 5px;
    height: 5px;
  }
}
@media (max-width: 1050px) {
  .dropdown-button-publication {
    height: 40px;
    width: 20px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    cursor: pointer;
  }
}
@media (max-width: 900px) {
    .dropdown-menu-button-container-fullpagepost > div {
        width: 20px;
        height: 25px;
        /* background-color: red; */
      }
    .dropdown-menu-button-container-fullpagepost > div > div {
        width: 2.5px;
        height: 2.5px;
      }
}
