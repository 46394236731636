.favAthlete-container {
  width: 288px;
  height: 239px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  opacity: 0;
  animation-delay: 0.3s !important;
  animation: fadeInUp 700ms ease-in-out forwards;
  user-select: none;
}
.favAthlete-horizontal {
  border: 0.3px solid rgba(0, 0, 0, 0.1);
}
.favAthlete-wrap {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: 95%;
}
.favAthlete-subwrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.favAthlete-subwrap {
  font-family: "Britanica-Heavy";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
}
.favAthlete-subwrap > a {
  font-family: "Britanica-Regular";
  font-size: 13px;
  line-height: 16px;
  text-decoration: none;
  color: #000000;
}

.favAthlete-subwrap > a:hover {
  text-decoration: underline;
  color: black;
}
.favAthlete-responsive-container {
  display: none;
}
@keyframes fadeInUp {
  from {
      opacity: 0;
      transform: translateY(-200px);
  }
  to {
      opacity: 1;
  }
}
@media (max-width: 950px) {
  .favAthlete-container {
    width: 250px;
    height: 330px;
  }
  .favAthlete-responsive-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 80%;
    overflow: hidden;
  }
  /* .favAthlete-responsive-container > a  {
    background-color: #f6d46352;
    border-radius: 99px;
  } */
}
@media (max-width: 950px) {
  .favAthlete-container {
display: none;
  }
}
@media (max-width: 900px) {
  .favAthlete-container {
    width: 220px;
  }
  .favAthlete-subwrap > a {
    display: none;
  }
}
@media (max-width: 860px){
  .favAthlete-container {
    width: 200px;
  }
}
@media (max-width: 840px){
  .favAthlete-container {
    width: 183px;
  }
}