.event-component {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 249px;
  min-height: 228px;
  opacity: 0;
  animation: fadeInUp 500ms ease-in-out forwards;
}
.event-header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.event-header-title {
  font-family: "Britanica-Heavy";
  font-size: 20px;
  line-height: 24px;
  color: #000000;
}
.event-header-button {
  font-family: "Britanica-Regular";
  line-height: 16px;
  color: #000000;
  text-decoration: none;
}
.event-header-button:hover {
  text-decoration: underline;
}

@keyframes fadeInUp {
  from {
      opacity: 0;
      transform: translateY(-200px);
  }
  to {
      opacity: 1;
  }
}