.nftcardtemplate-component {
  max-width: 299px;
  max-height: 380px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.25s ease-in-out 0s, transform 0.25s ease 0s;
  border-radius: 10px;
}
.nftcardtemplate-component:hover {
  box-shadow: rgba(0, 0, 0, 0.12) 0px 4px 16px;
  transform: translate(0px, -10px);
  transition-duration: 300ms;
}
.nftcardtemplate-image-wrap {
  max-width: 299px;
  max-height: 300px;
}
.nftcardtemplate-image-wrap > img {
  width: 100%;
  height: 100%;
  border-radius: 10px;
}
.nftcardtemplate-when-no-image{
  background-color: #f6d4638a;
  min-height: 299px;
  min-width: 300px;
  color: rgba(0, 0, 0, 0.63);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  font-family: "britanica-heavy";
  border-radius: 10px;
}
.nftcardtemplate-container-content {
  max-width: 400px;
  width: calc(100% - 20px);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 17.63px;
}
.nftcardtemplate-container-content-athletename {
  font-family: "Britanica-Heavy";
  font-size: 14px;
  color: #f6d463;
  padding-bottom: 2px;
}
.nftcardtemplate-container-content-title {
  font-family: "Britanica-Heavy";
  font-size: 16px;
  line-height: 19px;
  color: #000000;
  width: 100%;
  padding-bottom: 10.85px;
}
.nftcardtemplate-container-content-title-props p{
font-family: "britanica-regular";
}
.nftcardtemplate-container-content-price-wrap {
  display: flex;
  width: 100%;
  border-radius: 10px;
  background-color: rgba(155, 155, 155, 0.1);
  padding-top: 14.44px;
  padding-bottom: 14.44px;
  margin-bottom: 19.76px;
}
.nftcardtemplate-container-content-price-subwrap {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 50%;
}
.nftcardtemplate-container-content-price-subwrap-text {
  font-family: "Britanica-Light";
  font-size: 12px;
  color: #000000;
  padding-left: 10px;
  padding-bottom: 3px;
}
.nftcardtemplate-container-content-price-subwrap-price {
  font-family: "Britanica-Heavy";
  font-size: 16px;
  color: #000000;
  padding-left: 10px;
}
@media (max-width: 1150px) {
  .nftcardtemplate-component {
    border-radius: 9.5px;
  }
  .nftcardtemplate-image-wrap > img {
    border-radius: 9.5px;
  }
  .nftcardtemplate-container-content {
    margin-top: 16.7485px;
  }
  .nftcardtemplate-container-content-athletename {
    font-size: 13.3px;
    padding-bottom: 1.9px;
  }
  .nftcardtemplate-container-content-title {
    font-size: 15.2px;
    line-height: 18.05px;
    padding-bottom: 10.3075px;
  }
  .nftcardtemplate-container-content-price-wrap {
    border-radius: 9.5px;
    padding-top: 13.718px;
    padding-bottom: 13.718px;
    margin-bottom: 18.772px;
  }
  .nftcardtemplate-container-content-price-subwrap-text {
    font-size: 11.4px;
    padding-left: 9.5px;
    padding-bottom: 2.85px;
  }
  .nftcardtemplate-container-content-price-subwrap-price {
    font-size: 15.2px;
    padding-left: 9.5px;
  }
}
@media (max-width: 1050px) {
  .nftcardtemplate-component {
    border-radius: 8.5px;
  }
  .nftcardtemplate-image-wrap > img {
    border-radius: 8.5px;
  }
  .nftcardtemplate-container-content {
    margin-top: 14.9855px;
  }
  .nftcardtemplate-container-content-athletename {
    font-size: 11.9px;
    padding-bottom: 1.7px;
  }
  .nftcardtemplate-container-content-title {
    font-size: 13.6px;
    line-height: 16.15px;
    padding-bottom: 9.2225px;
  }
  .nftcardtemplate-container-content-price-wrap {
    border-radius: 8.5px;
    padding-top: 12.274px;
    padding-bottom: 12.274px;
    margin-bottom: 16.796px;
  }
  .nftcardtemplate-container-content-price-subwrap-text {
    font-size: 10.2px;
    padding-left: 8.5px;
    padding-bottom: 2.55px;
  }
  .nftcardtemplate-container-content-price-subwrap-price {
    font-size: 13.6px;
    padding-left: 8.5px;
  }
}
@media (max-width: 960px){
  .nftcardtemplate-component {
    border-radius: 7.5px;
  }
  .nftcardtemplate-image-wrap > img {
    border-radius: 7.5px;
  }
  .nftcardtemplate-container-content {
    margin-top: 13.2225px;
  }
  .nftcardtemplate-container-content-athletename {
    font-size: 10.5px;
    padding-bottom: 1.5px;
  }
  .nftcardtemplate-container-content-title {
    font-size: 12px;
    line-height: 14.25px;
    padding-bottom: 8.1375px;
  }
  .nftcardtemplate-container-content-price-wrap {
    border-radius: 7.5px;
    padding-top: 10.83px;
    padding-bottom: 10.83px;
    margin-bottom: 14.82px;
  }
  .nftcardtemplate-container-content-price-subwrap-text {
    font-size: 9px;
    padding-left: 7.5px;
    padding-bottom: 2.25px;
  }
  .nftcardtemplate-container-content-price-subwrap-price {
    font-size: 12px;
    padding-left: 7.5px;
  }
}
@media (max-width: 850px){
  
  .nftcardtemplate-container-content-price-subwrap-price {
    font-size: 11px;
    padding-left: 7.5px;
  }
}