.pollpost-container {
  width: 100%;
  max-width: 518px;
  height: auto;
  background: #ffffff;
  /* box-shadow: 0px 3.39796px 20.3878px rgba(0, 0, 0, 0.1); */
  border-radius: 9.96517px;
  display: flex;
  /* justify-content: space-around; */
  align-items: center;
  flex-direction: column;
  position: relative;
}

.pollpost-wrap {
  width: 90%;
}

.headofpost-pollpost-container {
  width: 90%;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.description-pollpost {
  width: 90%;
  height: auto;
  min-height: 45px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.pollpost-wrap {
  /* background-color: yellow; */
  height: 100%;
}



.ageofpost-poll-container {
  width: 100%;
  /* background-color: green; */
  display: flex;
  justify-content: center;
}
.ageofpost-poll-wrap {
  width: 100%;
  display: flex;
  /* background-color: red; */
}

.ageofpost-and-timeleft-poll {
  /* width: 100%; */
  height: 30px;
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  color: #000000;
  opacity: 0.5;
  display: flex;
  align-items: center;
  /* background-color: red; */
}
.ageofpost-and-timeleft-poll div {
  padding: 3px;
}

.likes-comments-counter-pollpost-container {
  width: 65%;
}
.add-comment-pollpost-container {
  width: 100%;
  display: flex;
  justify-content: center;
}
.show-comments-button-publication-pollpost-container {
  /* background-color: red; */
  width: 100%;
}
.show-comments-button-publication-pollpost {
  font-size: 17px;
  font-family: "britanica-black";
  line-height: 20px;
  height: 35px;
  /* background-color: forestgreen; */
  width: 38%;
  display: flex;
  justify-content: center;
}
.show-comments-button-publication-pollpost a {
  text-decoration: none;
  color: black;
}
.show-comments-button-publication-pollpost a:hover {
  text-decoration: underline;
}
.likes-comments-container-pollpost {
  width: 90%;
}

/* Faire passer en props */