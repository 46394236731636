.publication-head-container-fullpagepost {
  width: 100%;
  min-height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* background-color: chocolate; */
}
.publication-head-left-container {
  max-height: 40px;
  height: 40px;
  padding-right: 15px;
  flex-wrap: wrap;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.publication-head-left-container:hover {
  background-color: #00000015;
  transition-duration: 300ms !important;
  border-radius: 10px;
  cursor: pointer;
}
.publication-head-left-container:active {
  transform: scale(0.8);
}

.profile-side-info-container {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
}
.athlete-name-publication {
  font-size: 16px;
  font-family: "britanica-heavy";
}

.profilepic-athlete-publication {
  height: 40px;
  width: 40px;
  background-color: rgb(187, 204, 204);
  /* color: white; */
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  overflow: hidden;
}
.profilepic-athlete-publication img {
  width: 100%;
  height: 100%;
}

.age-publication {
  font-size: 14px;
  /* font-family: 'Montserrat'; */
  line-height: 17px;
  opacity: 0.5;
}

.publication-head-right-container {
  height: 25px;
  width: 100%;
  /* background-color: blue; */
  max-width: 150px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.publication-type {
  border-radius: 10px;
  background-color: #f6d463;
  width: 84px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "britanica-regular";
  font-size: 14px;
  cursor: pointer;
}
.publication-type:hover {
  background-color: #f7c520;
  transition: 500ms;
}
@media (max-height: 900px) {
  .publication-head-right-container{
    padding-right: 25px;
  }
}
@media (max-width: 1450px) {
  .publication-head-left-container {
    width: 45%;
  }
  .publication-head-left-container-size-pollpost {
    width: 45%;
  }
  .publication-type {
    width: 69px;
    height: 22px;
  }
  .publication-type-pollpost {
    width: 84px;
    height: 25px;
  }
  .publication-head-container-fullpagepost {
    width: 370px;
    /* padding-left: 15px; */
    /* background-color: red; */
  }
}
@media (max-width: 1050px) {
  .publication-head-container {
    /* background-color: aquamarine; */
    /* max-width: 300px; */
  }
  .publication-head-left-container {
    width: 55%;
  }
  .publication-head-left-container-size-pollpost {
    width: 40%;
  }
  .age-publication {
    font-size: 12px;
  }
  .age-publication-pollpost {
    font-size: 14px;
  }
  .athlete-name-publication {
    font-size: 14px;
  }
  .athlete-name-publication-pollpost {
    font-size: 16px;
  }
  .publication-type {
    font-size: 12px;
    width: 60px;
    height: 18px;
  }
  .publication-type-pollpost {
    width: 84px;
    height: 25px;
  }
  .publication-head-right-container {
    max-width: 100px;
  }
  .publication-head-right-container-pollpost {
    max-width: 150px;
  }
  .publication-head-container-fullpagepost {
    /* width: 370px; */
    padding-left: 0px;
    /* background-color: red; */
  }
}
@media (max-width: 900px) {
  .publication-head-container-fullpagepost > div:nth-child(2) {
    /* margin-right:6px !important ; */
    width: 40px;
    padding-right: 10px;
    /* background-color: red; */
  }
  .publication-head-left-container {
    width: 55%;
  }
  .publication-head-left-container-size-pollpost {
    width: 40%;
  }
  .profilepic-athlete-publication {
    width: 35px !important;
    height: 35px !important;
  }
  .profilepic-athlete-publication img {
    width: 35px !important;
    height: 35px !important;
  }
  .athlete-name-publication {
    font-size: 12px;
  }
  .athlete-name-publication-pollpost {
    font-size: 16px;
  }
  .age-publication {
    font-size: 10px;
  }
  .age-publication-pollpost {
    font-size: 14px;
  }
  .publication-type {
    font-size: 10px;
    width: 50px;
    height: 18px;
  }
  .publication-type-pollpost {
    width: 84px;
    height: 25px;
    font-size: 14px;
  }
  .publication-head-right-container {
    max-width: 100px;
  }
  .publication-head-right-container-pollpost {
    max-width: 150px;
  }
}
