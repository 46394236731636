.likes-comments-container-publication-no-media-queries {
  width: 90%;
  min-height: 70px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.like-button-container-no-media-queries {
  width: 100px;
  height: 20px;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-items: center;
}
.like-button-container-pollpost-no-media-queries {
  width: 105px;
  /* background-color: red; */
}

.likes-counter-publication-no-media-queries {
  font-family: "britanica-heavy";
  font-size: 14px;
  /* margin-left: 25px; */
}
.likes-counter-publication-no-media-queries a {
  text-decoration: none;
  color: black;
}
.likes-counter-publication-no-media-queries a:hover {
  text-decoration: underline;
}

.comments-publication-no-media-queries {
  width: 120px;
  height: 20px;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-items: center;
}

.comments-counter-publication-no-media-queries {
  font-family: "britanica-heavy";
  font-size: 14px;
  margin-left: 5px;
}
.comments-counter-publication-no-media-queries a {
  text-decoration: none;
  color: black;
}
.comments-counter-publication-no-media-queries a:hover {
  text-decoration: underline;
}

.likes-comments-container-publication-fullpagepost-no-media-queries {
  width: 90%;
  min-height: 70px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  /* background-color: blue; */
}
.like-button-container-fullpagepost-no-media-queries {
  width: 100px;
  height: 20px;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-items: center;
}
@media (max-width: 1050px) {
    .likes-counter-publication-no-media-queries{
        /* margin-left: 25px; */
    }
}