.popupvalidate-component {
  background-color: white;
  width: 450px;
  height: 415px;
  border-radius: 20px;
  box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
}
.popupvalidate-component > span {
  margin-top: 24px;
  font-family: "britanica-heavy";
  font-size: 25px;
  line-height: normal;
  text-align: center;
}
