.publication-container {
  /* max-height: 863px; */
  max-width: 550px;
  width: 100% !important;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  /* margin-bottom: 60px; */
  position: relative;
  /* background-color: blue; */
}
.publication-content {
  /* max-height: 803px; */
  max-width: 517px;
  width: 90%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 0px 20.3424px rgba(0, 0, 0, 0.08);
  border-radius: 9.94299px;
  /* position: relative; */
  overflow-y: visible;
  /* background-color: yellow; */
}
.publication-content::-webkit-scrollbar {
  display: none;
}
/* check HeadOfPost.css for the fullpagepost version */
.publication-head-container {
  width: 100%;
  min-height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.publication-media {
  max-height: 517px !important;
  max-width: 517px !important;
  width: 100%;
  height: 60%;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.publication-media img {
  max-width: 100%;
  max-height: 100%;
  max-height: 517px;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  object-fit: cover;
}

.show-comments-button-publication {
  font-size: 17px;
  font-family: "britanica-black";
  line-height: 20px;
  min-height: 35px;
  width: 96%;
  display: flex;
  position: relative;
  left: 1%;
}
.show-comments-button-publication a {
  color: black;
  text-decoration: none;
}

.show-comments-button-publication:active {
  color: white;
}
.show-comments-button-publication:hover {
  text-decoration: underline;
  cursor: pointer;
}
.publish-comments-button-container-publication button {
  border: none;
  background: none;
  padding: 0;
  font-family: "britanica-black";
  font-size: 16px;
  cursor: pointer;
  padding-top: 6px;
  padding-bottom: 6px;
  padding-left: 10px;
  padding-right: 10px;
}
.publish-comments-button-container-publication button:hover {
  background-color: #00000015;
  /* background-color: #f7c520 !important; */
  transition-duration: 300ms !important;
  border-radius: 20px;
}
.line-separation-comments-publication {
  border: 0.29829px solid rgba(0, 0, 0, 0.2);
}

@media (max-width: 1350px) {
  .line-separation-comments-publication-container {
    height: 25px;
  }
}
