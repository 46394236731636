/* 1470x920 */
.fullpagepost-container-no-media-queries {
  position: relative !important;
  height: 100% !important;
  width: 100% !important;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100 !important;
  top: 0 !important;
  bottom: 0 !important;
  left: 0 !important;
  right: 0 !important;
}
.overlay-postfeed-no-media-queries {
  top: 0;
  bottom: 0;
  left: 0;
  /* right: 0; */
  position: relative !important;
  width: 100% !important ;
  height: 100% !important;
  background-color: black;
  opacity: 0.7;
  z-index: 10 !important;
}

.post-container-fullpagepost-no-media-queries {
  height: 100%;
  width: 100%;
  max-width: 1347px;
  max-height: 795px;
  display: flex;
  justify-content: center;
  align-items: center;
}
/* Responsive */
.media-fullpagepost-no-media-queries {
  width: 795px;
  height: 795px;
  max-width: 795px;
  max-height: 795px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-color: antiquewhite; */
}
/* Responsive */
.img-fullpagepost-no-media-queries {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  max-height: 795px;
  max-width: 795px;
  width: 795px;
  height: 795px;
  object-fit: contain !important;
  background-color: rgba(0, 0, 0, 0.706) !important;
  background-color: black !important;
  /* background-color: blur(15px) rgba(0, 0, 0, 0.706); */
}

.desc-likes-comments-container-fullpagepost-no-media-queries {
  width: 100%;
  height: 100%;
  min-height: 795px;
  min-width: 552px;
  max-width: 552px;
  max-height: 795px !important;
  background-color: white;
  /* border-radius: 10px; */
  display: flex;
  align-items: center;
  /* justify-content: center; */
  flex-direction: column;
  border-radius: 10px;
}

.bloc-content-fullpagepost-no-media-queries {
  width: 100%;
  height: 100%;
  max-width: 496px;
  max-height: 795px !important;
  /* display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; */
  margin-top: 15px;
  /* background-color: red; */
  overflow-y: scroll;
  overflow-x: hidden;
  /* padding-right: 20px !important; */
}
/* Hide scrollbar for IE, Edge, and Firefox */
.bloc-content-fullpagepost-no-media-queries::-webkit-scrollbar,
.bloc-content-fullpagepost-no-media-queries {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  width: 100.5%;
}

/* Customize the scrollbar for WebKit-based browsers (e.g., Chrome, Safari) */
.bloc-content-fullpagepost-no-media-queries::-webkit-scrollbar {
  width: 6px; /* Set the width of the scrollbar */
  border-radius: 10px;
}

.bloc-content-fullpagepost-no-media-queries::-webkit-scrollbar-track {
  background: transparent; /* Set the track background */
  display: none;
}

.bloc-content-fullpagepost-no-media-queries::-webkit-scrollbar-thumb {
  background-color: #888; /* Set the color of the scrollbar */
  border-radius: 10px; /* Set the border-radius */
  visibility: hidden;
}

.bloc-content-fullpagepost-no-media-queries::-webkit-scrollbar-thumb:hover {
  background-color: #555; /* Set the color when hovered */
}
.cancel-fullpagepost-no-media-queries {
  position: sticky;
  bottom: 95%;
  left: 93%;
}

.headofpost-container-fullpagepost-no-media-queries {
  width: 500px;
  height: 60px;
  display: flex;
  justify-content: space-between;
  /* background-color: tomato; */
  /* justify-content: center;
    align-items: center; */
}

.separation-line-fullpagepost-no-media-queries {
  width: 100%;
  border-top: 0.3px solid rgba(0, 0, 0, 0.2);
}
.comments-container-fullpagepost-no-media-queries {
  min-height: 354px !important;
  height: 100%;
  width: 100%;
  margin-top: 15px;
  /* background-color: aqua; */
  max-height: 474px !important;
  overflow-y: scroll;
}
/* Hide scrollbar for IE, Edge, and Firefox */
.comments-container-fullpagepost-no-media-queries::-webkit-scrollbar,
.comments-container-fullpagepost-no-media-queries {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  width: 100.5%;
}

/* Customize the scrollbar for WebKit-based browsers (e.g., Chrome, Safari) */
.comments-container-fullpagepost-no-media-queries::-webkit-scrollbar {
  width: 6px; /* Set the width of the scrollbar */
  border-radius: 10px;
}

.comments-container-fullpagepost-no-media-queries::-webkit-scrollbar-track {
  background: transparent; /* Set the track background */
}

.comments-container-fullpagepost-no-media-queries::-webkit-scrollbar-thumb {
  background-color: #888; /* Set the color of the scrollbar */
  border-radius: 10px; /* Set the border-radius */
}

.comments-container-fullpagepost-no-media-queries::-webkit-scrollbar-thumb:hover {
  background-color: #555; /* Set the color when hovered */
}
#dropdown-small-no-media-queries {
  display: none;
}

.likes-comments-counter-container-fullpagepost-no-media-queries {
  height: 6% !important;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.add-comment-input-container-fullpagepost-no-media-queries {
  width: 100%;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-height: 900px) {
  .desc-likes-comments-container-fullpagepost-no-media-queries {
    min-height: 675px;
    height: 675px;
  }
  .bloc-content-fullpagepost-no-media-queries {
    min-height: 645px;
    max-height: 645px;
    height: 645px;
  }
  .comments-container-fullpagepost-no-media-queries {
    min-height: 374px !important;
    height: 374px;
    max-height: 374px;
  }
}
