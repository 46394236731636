.popup-edit-profile-wrap {
  height: 525px;
  width: 500px;
  background-color: white;
  border-radius: 20px;
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.popup-edit-profile-animation-wrap {
  height: 525px;
  width: 500px;
  background-color: white;
  border-radius: 20px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.popup-edit-profile-validation-container,
.popup-edit-profile-error-container {
  height: 525px;
  width: 500px;
  background-color: white;
  border-radius: 20px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.popup-edit-profile-validation-container img,
.popup-edit-profile-error-container img {
  width: 40px !important;
  height: 40px !important;
}
.popup-edit-profile-validation-message,
.popup-edit-profile-error-message {
  font-size: 30px;
  /* font-weight: 900; */
  margin-top: 40px;
  font-family: "britanica-heavy";
  text-align: center;
  padding: 10px;
}
.popup-edit-profile-previous-step {
  position: absolute;
  top: 20px;
  left: 20px;
  cursor: pointer;
}
.popup-edit-profile-title {
  height: 30px;
  width: 100%;
  font-size: 25px;
  font-family: "britanica-heavy";
  text-align: center;
  margin-top: 20px;
}
.popup-edit-profile-banner-and-profile-pic {
  height: 181px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;
}
.popup-edit-profile-banner-container {
  width: 460px;
  min-height: 110.75px;
  /* max-width: 100%;
  max-height: 110.75px; */
  border-radius: 20px;
  background: #d9d9d9;
  position: relative;
}
.popup-edit-profile-banner-container > img {
  width: 100%;
  height: 100%;
  border-radius: 20px;
}

.popup-edit-profile-banner-add-button {
  position: absolute;
  width: 36px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 6px;
  right: 9px;
  border-radius: 50%;
  background: #f6d463;
  box-shadow: 0px 0px 11px rgba(0, 0, 0, 0.25);
  cursor: pointer;
  border: none;
}
.popup-edit-profile-banner-add-button:hover {
  background-color: #f7c520 !important;
  transition-duration: 300ms;
}
.popup-edit-profile-banner-add-button:active {
  transform: scale(0.85);
}

.popup-edit-profile-profile-pic-container {
  min-width: 100px;
  min-height: 100px;
  width: 100px;
  height: 100px;
  max-width: 100px;
  max-height: 100px;
  border-radius: 50%;
  background: #d9d9d9;
  transform: translateY(-40px);
  border: 6px solid #ffffff;
  cursor: pointer;
  background-repeat: no-repeat;
}

.popup-edit-profile-profile-pic {
  object-fit: cover;
  width: 100%;
  height: 100%;
  border-radius: 50%;
}
.popup-edit-profile-profile-pic-add-button {
  position: absolute;
  width: 36px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 67.25px;
  left: 70px;
  border-radius: 50%;
  background: #f6d463;
  box-shadow: 0px 0px 11px rgba(0, 0, 0, 0.25);
  cursor: pointer;
  border: none;
  user-select: none;
}
.popup-edit-profile-profile-pic-add-button:hover {
  background-color: #f7c520 !important;
  transition-duration: 300ms;
}
.popup-edit-profile-profile-pic-add-button:active {
  transform: scale(0.75);
}
.popup-edit-profile-bio-title-and-length-limit {
  width: 460px;
  height: 17px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}
.popup-edit-profile-bio-title {
  font-size: 14px;
  font-family: "britanica-heavy";
  line-height: normal;
}
.popup-edit-profile-length-limit {
  font-size: 14px;
  line-height: normal;
}
.popup-edit-profile-bio-container {
  position: relative;
  width: 460px;
  margin-top: 5px;
}
.popup-edit-profile-bio {
  width: calc(100% - 10px);
  height: 94px;
  margin: 0;
  padding: 0;
  padding-left: 10px;
  padding-top: 10px;
  border-radius: 5px;
  resize: none;
  font-family: "britanica-regular";
  /* overflow-y: scroll; */
}
.popup-edit-profile-wrap textarea {
  font-family: "britanica-regular" !important;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  /* border-color: red; */
}

.popup-edit-profile-bio-error {
  position: absolute;
  color: red;
  font-size: 12px;
  font-weight: 900;
}
.popup-edit-profile-next-button {
  background: none;
  margin: 0;
  padding: 0;
  border: none;
  height: 56px;
  width: 460px;
  border-radius: 10px;
  background: #f6d463;
  font-size: 20px;
  font-family: "britanica-heavy";
  margin-top: 40px;
  cursor: pointer;
}

.popup-edit-profile-next-button:hover {
  background-color: #f7c520 !important;
  transition-duration: 300ms;
}
.popup-edit-profile-next-button:active {
  transform: scale(0.85);
}
.popup-edit-profile-next-button:disabled {
  pointer-events: none;
  opacity: 0.5;
}

.popup-edit-profile-cropeasy-container {
  width: 460px;
  height: 610px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  background-color: white;
}
.popup-edit-profile-cropeasy-cursor-container {
  width: 90%;
  display: flex;
  justify-content: center;
}

.popup-edit-profile-cropeasy-previous-step {
  position: absolute;
  top: 25px;
  left: 7px;
  padding: 5px;
  cursor: pointer;
}
.popup-edit-profile-cropeasy-previous-step:hover {
  background-color: #00000015;
  transition-duration: 300ms !important;
  border-radius: 30px;
}
.popup-edit-profile-cropeasy-previous-step:active {
  transform: scale(0.85);
}
.popup-edit-profile-cropeasy-title {
  font-size: 30px;
  font-family: "britanica-black";
  height: 70px;
  display: flex;
  align-items: center;
}
.popup-edit-profile-cropeasy-container-wrap {
  position: relative;
  width: 380px;
  border-radius: 20px;
  /* 0.9406953 */
  height: 403.95652px;
}
.controls {
  width: 50%;
  /* background-color: red; */
}
.popup-edit-profile-cropeasy-input-and-button-container {
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  margin-top: 20px;
}
.popup-edit-profile-cropeasy-container input[type="range"] {
  accent-color: #f7c520;
  cursor: ew-resize;
  width: 50%;
  margin-bottom: 15px;
}
.popup-edit-profile-cropeasy-input-and-button-container button {
  background-color: #f6d463;
  outline: none;
  border: none;
  border-radius: 10px;
  width: 90%;
  height: 46px;
  font-size: 20px;
  font-family: "britanica-heavy";
  cursor: pointer;
}
.popup-edit-profile-cropeasy-input-and-button-container button:hover {
  background-color: #f7c520;
  transition-duration: 300ms;
}
.popup-edit-profile-cropeasy-input-and-button-container button:active {
  transition-duration: 300ms;
  transform: scale(0.85);
}

.result {
  position: absolute;
  /* background-color: dimgray; */
  top: 95px;
  width: 400px;
  height: 300px;
}
