.feedsuggestions-component {
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  width: 286px;
  min-height: 280px;
  overflow: hidden;
  /* Suggestion: mettre 3 petits points pour les noms à rallonge */
  opacity: 0;
  animation-delay: 0.5s !important;
  animation: fadeInUp 700ms ease-in-out forwards;
  padding-top: 16px;
}
.feedlaunchpad-header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 10px;
}
.feedlaunchpad-header-container > span {
  font-family: "Britanica-Heavy";
  font-size: 20px;
}
.feedlaunchpad-header-container > a {
  font-family: "Britanica-Regular";
  text-decoration: none;
  color: #000000;
}
.feedlaunchpad-header-button-suggestion {
  font-size: 13px;
}
@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(-200px);
  }
  to {
    opacity: 1;
  }
}
@media (max-width: 950px) {
  .feedsuggestions-component {
    width: 250px;
    /* display: none; */
  }
}
@media (max-width: 900px) {
  .feedsuggestions-component {
    width: 220px;
  }
  .feedlaunchpad-header-container > a {
    display: none;
  }
}
@media (max-width: 860px) {
  .feedsuggestions-component {
    width: 200px;
  }
}
@media (max-width: 840px) {
  .feedsuggestions-component {
    width: 183px;
  }
}
