.password-reset-container * {
  box-sizing: border-box; /* Add this line */
}

.password-reset-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  font-family: "Arial", sans-serif;
}
.password-reset-loading-container {
  background: #fff;
  padding: 40px 60px;
  padding-bottom: 50px;
  border-radius: 20px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  width: 400px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.password-reset-validation-message,
.password-reset-title {
  margin-bottom: 25px;
  font-size: 24px;
  /* color: #333; */
  font-family: "britanica-heavy";
}

.password-reset-container form {
  background: #fff;
  padding: 40px 60px;
  padding-bottom: 50px;
  border-radius: 20px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  width: 400px;
  position: relative;
}
.password-reset-container > form svg {
  position: absolute;
  width: 18px;
  height: 18px;
  right: 75px;
  bottom: 248px;
  cursor: pointer;
  user-select: none;
}
.password-reset-container > form svg:nth-of-type(2) {
  position: absolute;
  width: 18px;
  height: 18px;
  right: 75px;
  bottom: 145px;
  cursor: pointer;
  user-select: none;
}

.password-reset-container label {
  font-size: 16px;
  /* color: #555; */
  font-family: "britanica-heavy";
  margin-bottom: 10px;
}

.password-reset-container input {
  width: 100%;
  padding: 12px;
  margin-top: 10px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.password-reset-container .change-password-button {
  font-family: "Britanica-Heavy";
  font-size: 16px;
  width: 100%;
  padding: 14px;
  background-color: #f6d463;
  color: black;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  user-select: none;
}

.password-reset-container .change-password-button:hover {
  background-color: #f7c520;
}
.password-reset-validation-message {
  /* background: #a01515; */
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 40px 60px;
  border-radius: 20px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  width: 400px;
  height: 250px;
}
.password-reset-validation-message img {
  width: 30px;
  height: 30px;
  margin-bottom: 15px;
}
.reset-password-message-validation {
  color: #85d87e;
  font-style: italic;
  font-family: "britanica-heavy";
  font-size: 14px;
  position: absolute;
  bottom: 140px;
}
.reset-password-message-error {
  color: red;
  font-style: italic;
  font-family: "britanica-heavy";
  position: absolute;
  bottom: 14px;
  font-size: 14px;
}
