/* NEW CSS */
.posts-comments-component-container {
  width: 100%;
  max-width: 496px;
  /* min-height: 81px; */
  margin-bottom: 10px;
  display: flex;
  /* background-color: red; */
}
.posts-comments-component-profile-pic-container {
  /* height: 81px; */
  margin-right: 10px;
  /* background-color: aquamarine; */
}
.posts-comments-component-profile-pic-container img {
  width: 30px;
  height: 30px;
  max-width: 30px;
  max-height: 30px;
  /* border-radius: 30px; */
  border: solid 2px white;
}
.posts-comments-component-profile-pic-container img:hover {
  border: solid 2px rgba(0, 0, 0, 0.466);
  cursor: pointer;
  transition-duration: 500ms;
}
.posts-comments-component-right-container {
  width: 100%;
  height: 100%;
  /* background-color: blueviolet; */
}
.posts-comments-component-username {
  font-size: 16px;
  font-family: "britanica-heavy";
}
.post-comments-component-username-for-hover:hover{
  text-decoration: underline;
  cursor: pointer;
}
.posts-comments-component-creator-badge {
  background-color: #f6d463;
  color: white;
  padding: 1px 3px;
  text-align: center;
  border-radius: 5px;
  font-size: 12px;
  margin-left: 5px;
  cursor: pointer;
  user-select: none;
}
.posts-comments-component-creator-badge:hover {
  background-color: #f7c520;
  transition-duration: 300ms;
}
/* .posts-comments-component-username:hover {
  text-decoration: underline;
  cursor: pointer;
} */
.posts-comments-component-comments {
  font-size: 14px;
  margin-top: 5px;
  font-family: "britanica-regular";
}
.posts-comments-component-comments-like-and-timestamp {
  padding-top: 10px;
  display: flex;
  width: 100%;
  height: 13px;
  font-size: 12px;
  /* background-color: bisque; */
}
.posts-comments-component-comments-like-counter {
  font-family: "britanica-black";
}
.posts-comments-component-comments-timestamp {
  font-family: "britanica-regular";
  padding-left: 15px;
  color: #555555;
}
.posts-comments-component-comments-like-button-and-dropdown {
  width: 40px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-size: 12px;
  /* background-color: yellow; */
}
.posts-comments-component-comments-dropdown {
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  /* background-color: brown; */
  position: relative;
}
.posts-comments-component-comments-like-button-and-dropdown img {
  cursor: pointer;
  padding: 5px;
  width: 10px;
}
.posts-comments-component-comments-like-button-and-dropdown img:hover {
  background-color: #00000015;
  transition-duration: 300ms !important;
  border-radius: 50% !important;
}
.posts-comments-component-comments-like-button {
  width: 30px;
  height: 20px;
  padding-left: 3px;
  /* background-color: burlywood; */
}
@media (max-height: 900px) {
  .posts-comments-component-comments-like-and-timestamp {
    padding-top: 5px;
  }
}
@media (max-width: 1450px) {
  .posts-comments-component-comments {
    font-size: 12px;
    margin-top: 2px;
  }
  .posts-comments-component-username {
    font-size: 14px;
  }
  .posts-comments-component-comments-like-and-timestamp {
    padding-top: 5px;
    font-size: 11px;
    height: 12px;
  }
}
@media (max-width: 900px) {
  .posts-comments-component-container {
    /* background-color: RED; */
    /* min-height: 68px; */
    /* height: 68px !important; */
    /* max-height: 68px !important; */
    /* background-color: blue; */
    margin-bottom: 2px;
  }

  .posts-comments-component-profile-pic-container img {
    width: 25px;
    height: 25px;
    max-width: 25px;
    max-height: 25px;
  }

  .posts-comments-component-comments {
    font-size: 10px;
    margin-top: 2px;
  }
  .posts-comments-component-username {
    font-size: 12px;
  }
  .posts-comments-component-comments-like-and-timestamp {
    padding-top: 3px;
    font-size: 9px;
    height: 12px;
  }
}
