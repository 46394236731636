.mint-pop-up-container {
  width: 450px;
  height: 466px;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
}
