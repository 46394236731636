.utility-pop-up-container {
  width: 400px;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  position: relative;
  background-color: white;
  border-radius: 20px;
  padding: 20px;
}

.utility-pop-up-title {
  font-family: "Britanica-Heavy";
  font-style: normal;
  font-weight: 400;
  font-size: 25px;
  line-height: 30px;
}

.utility-info-data {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.utility-info-header {
    margin: 10px;
}

.utility-info-header img {
    width: 20px;
    margin-right: 10px;
}

.utility-info-title {
    font-family: "Britanica-Heavy";
  font-style: normal;
  font-weight: 400;
  font-size: 19px;
  line-height: 30px;
}
