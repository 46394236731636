.feedlaunchpad-content-container {
  width: 100%;
  height: 308px;
  border-radius: 10px;
  display: flex;
  align-items: flex-end;
  margin-top: 10px;
  text-decoration: none;
  transition: box-shadow 0.25s ease-in-out 0s, transform 0.25s ease 0s;
}
.feedlaunchpad-content-container img{
  object-fit: contain !important;
}
.feedlaunchpad-content-container:hover{
  box-shadow: rgba(0, 0, 0, 0.12) 0px 4px 16px;
  transform: translate(0px, -10px);
  transition-duration: 300ms;
}
.feedlaunchpad-content-wrap {
  width: 100%;
  height: 203px;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
  border-radius: 0px 0px 10px 10px;
  display: flex;
  align-items: flex-end;
}
.feedlaunchpad-content-subwrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 40%;
  width: 100%;
  padding-bottom: 21px;
  padding-left: 15px;
  color: #ffffff;
  font-family: 'Britanica-Regular';
  font-size: 14px;
}
.feedlaunchpad-content-subwrap > div {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 39px;
    padding-bottom: 5px;
}
.feedlaunchpad-content-subwrap > div > img {
  width: 22px;
  height: 22px;
  border-radius: 2px;
}
.feedlaunchpad-content-title {
    font-family: "Britanica-Heavy";
    font-size: 16px;
    line-height: 19px;
  }