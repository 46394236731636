.nft-collection-overview-utilities-one-container {
  width: 100%;
  height: 185px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  /* background-color: brown; */
  margin-bottom: 20px;
  transition: 0.5s;
}

.nft-collection-overview-utilities-one-container:hover {
  cursor: pointer;
  box-shadow: 0px 0px 12px #f6d463;
}

.nft-collection-overview-utilities-one-container:first-child {
  margin-top: 20px !important;
}
.nft-collection-overview-utilities-one-container:last-child {
  margin-bottom: 0;
}
.nft-collection-overview-utilities-one-wrap {
  width: 574.64px;
  height: 125px;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  /* background-color: darkolivegreen; */
}
.nft-collection-overview-utilities-one-header {
  height: 24px;
  width: 100%;
  max-width: 527.64px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* background-color: darkorange; */
  position: relative;
}
.nft-collection-overview-one-header-logo {
  width: 23.28px;
  height: 19.11px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-color: springgreen; */
}
.nft-collection-overview-one-header-logo img {
  width: 23.28px;
  height: 19.11px;
}
.nft-collection-overview-one-header-title {
  width: 495px;
  height: 100%;
  /* background-color: orchid; */
  display: flex;
  align-items: center;
  font-family: "britanica-heavy";
  font-size: 20px;
}
.nft-collection-overview-one-header-status {
  width: auto;
  max-width: 170px !important;
  height: 17px;
  display: flex;
  align-items: center;
  position: absolute;
  bottom: 10px;
  left: 490.64px;
  border: 0.3px solid #00000070;
  border-radius: 30px;
  padding: 1px;
}
.nft-collectin-overview-one-header-status-color-available {
  width: 5px;
  height: 5px;
  border-radius: 50%;
  margin: 3px;
  margin-right: 4px;
  background: #85d87e;
}
.nft-collectin-overview-one-header-status-color-unavailable {
  width: 5px;
  height: 5px;
  border-radius: 50%;
  margin: 3px;
  margin-right: 4px;
  background: #ee4d4d;
}
.nft-collection-overview-one-header-status-text {
  max-width: 109px;
  height: 14px;
  padding-right: 5px;
  font-size: 12px;
  font-family: "britanica-heavy";
}
.nft-collection-overview-utilities-one-description {
  max-width: 542px;
  height: 57px;
  max-height: 57px;
  margin-top: 10px;
  margin-left: 32.64px;
  /* background-color: mediumvioletred; */
  text-align: justify;
}
.nft-collection-overview-utilities-one-date {
  max-width: 495px;
  max-height: 14px;
  margin-top: 20px;
  margin-left: 32.64px;
  font-family: "britanica-black";
  /* background-color: lime; */
}
