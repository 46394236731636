.useractivitytab-component {
  display: flex;
  flex-direction: column;
  max-width: 1240px;
  width: 100%;
}
.useractivitytab-table-container {
  width: 100%;
  height: 48px;
  display: grid;
  align-items: center;
  grid-template-columns: 1.2fr 2.4fr 1.3fr 1.2fr 0.95fr 2fr 2.66fr 1fr;
  position: relative;
  font-family: "britanica-black";
  font-size: 14px;
  line-height: normal;
}
.useractivitytab-table-container::before {
  content: "";
  position: absolute;
  top: 0;
  width: 100%;
  height: 1px;
  background-color: black;
}
.useractivitytab-table-container::after {
  content: "";
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 1px;
  background-color: black;
}
.useractivitytab-table-container-price,
.useractivitytab-table-container-qty {
  display: flex;
  justify-content: flex-end;
}
.useractivitytab-content-container-wrap {
  display: grid;
  align-items: center;
  grid-template-columns: 1.2fr 2.4fr 1.3fr 1.2fr 0.95fr 2fr 2.66fr 1fr;
  font-family: "britanica-heavy";
  font-size: 14px;
  line-height: normal;
}
.useractivitytab-content-container-wrap div:nth-child(6) a:hover {
  color: #f7c520 !important;
  transition-duration: 300ms !important;
}
.useractivitytab-content-container-wrap div:nth-child(7) span:hover {
  color: #f7c520 !important;
  transition-duration: 300ms !important;
cursor: pointer;
}
.useractivitytab-content-container-wrap:nth-child(1n) {
  margin-top: 20px;
}
.useractivitytab-content-container-methods-wrap {
  font-family: "britanica-black";
}
.useractivitytab-content-container-nft-wrap {
  display: flex;
  align-items: center;
}
.useractivitytab-content-container-nft-wrap > img {
  width: 50px;
  height: 50px;
  border-radius: 5px;
}
.useractivitytab-content-no-img-div {
  width: 50px;
  height: 50px;
  font-size: 8px;
  display: flex;
  font-style: italic;
  align-items: center;
  justify-content: center;
  background-color: #f6d463;
  opacity: 0.5;
  border-radius: 10px;
  cursor: pointer;
}
.useractivitytab-content-container-nft-wrap-info-wrap {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 9px;
  height: 40px;
}
.useractivitytab-content-container-price-container {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}
.useractivitytab-content-container-price-wrap {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
  height: 40px;
}
.useractivitytab-content-container-price-wrap > span:nth-child(2) {
  font-family: "britanica-regular";
}
.useractivitytab-content-container-qty-wrap {
  display: flex;
  justify-content: flex-end;
}
.useractivitytab-content-container-clipboard {
  font-size: 14px;
  position: absolute;
  left: 110px;
  top: -2px;
  font-family: "britanica-black";
  color: #f6d463;
  /* background-color: red; */
  animation: slide-bottom-up 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
.useractivitytab-content-container-clipboardlogo {
  width: 14px;
  height: 14px;
  margin-left: 4px;
}
.useractivitytab-content-container-clipboardlogo:active {
  transform: scale(0.85);
}
.useractivitytab-content-container-address-wrap {
  position: relative;
}
@keyframes slide-bottom-up {
  0% {
    transform: translateY(50px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
.useractivitytab-address-copied {
  width: 200px;
  height: 30px;
  font-size: 14px;
  font-family: "britanica-black";
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  left: 5vh;
  top: 90vh;
  border: 2px solid #f6d463;
  padding: 5px;
  border-radius: 10px;
  animation: slide-in 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.1);
  background-color: white;
}
.home-post-link-copied-hide {
  width: 200px;
  height: 30px;
  font-size: 14px;
  font-family: "britanica-black";
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  left: 5vh;
  top: 90vh;
  border: 2px solid #f6d463;
  padding: 5px;
  border-radius: 10px;
  animation: hide-smooth 300ms;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.1);
  background-color: white;
}
@keyframes slide-in {
  0% {
    transform: translateX(-150px);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes hide-smooth {
  0% {
    opacity: 100%;
  }
  100% {
    opacity: 0%;
  }
}
@media (max-width: 1150px) {
  .useractivitytab-table-container {
    font-size: 12.6px; /* 0.9 */
    height: 43.2px; /* 0.9 */
  }
  .useractivitytab-content-container-wrap {
    font-size: 12.6px; /* 0.9 */
  }
  .useractivitytab-content-container-wrap:nth-child(1n) {
    margin-top: 18px; /* 0.9 */
  }
  .useractivitytab-content-container-nft-wrap > img {
    width: 45px; /* 0.9 */
    height: 45px; /* 0.9 */
    border-radius: 4.5px; /* 0.9 */
  }
  .useractivitytab-content-container-nft-wrap-info-wrap {
    margin-left: 8.1px; /* 0.9 */
    height: 36px; /* 0.9 */
  }
  .useractivitytab-content-container-price-wrap {
    height: 36px; /* 0.9 */
  }
}
@media (max-width: 1080px) {
  .useractivitytab-table-container {
    font-size: 11.9px; /* 0.85 */
    height: 40.8px; /* 0.85 */
  }
  .useractivitytab-content-container-wrap {
    font-size: 11.9px; /* 0.85 */
  }
  .useractivitytab-content-container-wrap:nth-child(1n) {
    margin-top: 17px; /* 0.85 */
  }
  .useractivitytab-content-container-nft-wrap > img {
    width: 42.5px; /* 0.85 */
    height: 42.5px; /* 0.85 */
    border-radius: 4.25px; /* 0.85 */
  }
  .useractivitytab-content-container-nft-wrap-info-wrap {
    margin-left: 7.65px; /* 0.85 */
    height: 34px; /* 0.85 */
  }
  .useractivitytab-content-container-price-wrap {
    height: 34px; /* 0.85 */
  }
}
@media (max-width: 870px) {
  .useractivitytab-table-container {
    height: 38.4px; /* 0.8 */
    font-size: 11.2px; /* 0.85 */
  }
  .useractivitytab-content-container-wrap {
    font-size: 11.2px; /* 0.85 */
  }
  .useractivitytab-content-container-wrap:nth-child(1n) {
    margin-top: 16px; /* 0.8 */
  }
  .useractivitytab-content-container-nft-wrap > img {
    width: 40px; /* 0.8 */
    height: 40px; /* 0.8 */
    border-radius: 4px; /* 0.8 */
  }
  .useractivitytab-content-container-nft-wrap-info-wrap {
    margin-left: 7.2px; /* 0.8 */
    height: 32px; /* 0.8 */
  }
  .useractivitytab-content-container-price-wrap {
    height: 32px; /* 0.8 */
  }
}
