.mint-pop-up-status-container {
  width: 100%;
  height: 100%;
}
.mint-pop-up-status-image {
  width: 60px;
  height: 60px;
  position: absolute;
  top: 110px;
  right: 195px;
  user-select: none;
}
.mint-pop-up-status-text {
  width: 279px;
  height: 60px;
  font-size: 21px;
  text-align: center;
  font-family: "britanica-heavy";
  position: absolute;
  top: 194px;
  right: 85px;
}
.mint-pop-up-status-text span {
  color: #f6d463;
}
.mint-pop-up-status-button {
  border: none;
  outline: none;
  width: 410px;
  height: 56px;
  background: #f6d463;
  border-radius: 10px;
  font-family: "britanica-heavy";
  font-size: 20px;
  position: absolute;
  top: 339px;
  right: 20px;
  cursor: pointer;
  user-select: none;
}
.mint-pop-up-status-button-link {
  border: none;
  outline: none;
  width: 410px;
  height: 56px;
  background: #f6d463;
  border-radius: 10px;
  font-family: "britanica-heavy";
  font-size: 20px;
  position: absolute;
  top: 339px;
  right: 20px;
  cursor: pointer;
  user-select: none;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;
}
.mint-pop-up-status-button-link:hover {
  background-color: #f7c520;
  transition-duration: 300ms;
}
.mint-pop-up-status-button:hover {
  background-color: #f7c520;
  transition-duration: 300ms;
}
